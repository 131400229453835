import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAddtlComments, setChipsSelected } from '../../../actions'
import { RootState } from '../../../reducers'


type LiveChatRatingProps = {
    liveChatRating: string,
    liveChatAgent: string,
    setRatingValue: (value: number) => void,
    closeLiveChatRating: (event: any) => void,
    setLiveChatComment: React.Dispatch<React.SetStateAction<string>>
    isHostDevice: boolean
}

const LiveChatRating = ({liveChatRating, liveChatAgent, setRatingValue, closeLiveChatRating, setLiveChatComment, isHostDevice}: LiveChatRatingProps) => {
    const dispatch = useDispatch();
    const chipSelection = useSelector((state: RootState) => { return state.botData.chipSelection });
    const additionalComments = useSelector((state: RootState) => { return state.botData.additionalComments });

    const additionalCommentsRevertValue: any = useRef('');
    const goodChipLabels = ['Quick to resolve', 'Knowledgeable', 'Professional', 'Friendly']; 
    const badChipLabels = ['Took too long', 'Issue not resolved', 'Unfriendly', 'Unprofessional']; 

    const goodChips: any = useRef([]);
    const badChips: any = useRef([]);

    const [showAdditionalComments, setShowAdditionalComments] = useState<boolean>(false);

    const setChipsSelection = (label: string) => {
        if (chipSelection.indexOf(label) === -1) {
            setChipsSelected(chipSelection.push(label));
        } else {
            let index = chipSelection.indexOf(label);
            setChipsSelected(chipSelection.splice(index, 1));
        }

        setCompleteLiveChatCommentsForZendesk();
        renderChips();
    }

    const updateRating = (value:any) => {
        setRatingValue(value);

        if ((value === 1 && liveChatRating !== 'good') || (value === -1 && liveChatRating !== 'bad')) {
            // clear previously selected chips
            dispatch(setChipsSelected([]));
        }

        renderChips();
    }

    const isChipSelected = (label: string) => {
        return chipSelection.indexOf(label) !== -1;
    }

    const renderChips = () => {
        // clear the chips element
        goodChips.current = [];
        badChips.current = [];
        
        goodChipLabels.forEach((label, index) => {
            goodChips.current.push(<button key={"chip" + index} onClick={() => setChipsSelection(label)} className={"widget__overlay--votesection-chip" + (isChipSelected(label) ? " selected" : "" )}>{label}</button>);
        });
    
        badChipLabels.forEach((label, index) => {
            badChips.current.push(<button key={"chip" + index} onClick={() => setChipsSelection(label)} className={"widget__overlay--votesection-chip" + (isChipSelected(label) ? " selected" : "" )}>{label}</button>);
        });
    }

    const updateAdditionalCommentsValue = (e: any) => {
        dispatch(setAddtlComments(e.target.value));
        setCompleteLiveChatCommentsForZendesk();
    }

    const setCompleteLiveChatCommentsForZendesk = () => {
        let chipString = (chipSelection.length > 0) ? `[${ chipSelection.join(", ") }] -- ` : "";
        setLiveChatComment(chipString + additionalComments.trim());
    }

    const closeAdditionalComments = (reverChanges: boolean = false) => {
        if (reverChanges === true) {
            dispatch(setAddtlComments(additionalCommentsRevertValue.current));
        } 

        setCompleteLiveChatCommentsForZendesk();
        setShowAdditionalComments(false);
    }

    const openAdditionalComments = () => {
        additionalCommentsRevertValue.current = additionalComments;
        setShowAdditionalComments(true);
    }

    renderChips();

    return (
        <div className={"widget__overlay--livechat" + (isHostDevice ? " device" : "")}>
            {
                !showAdditionalComments &&
                <div id="divFirstScreen">
                    <p className="widget__overlay--text">How was your live chat experience with { liveChatAgent }?</p>
                    <div className="widget__overlay--votesection">
                        <div className="widget__overlay--votesection-thumbs">
                            <div className="widget__overlay--votesection-thumbsitem" >
                                <button tabIndex={1} onClick={() => updateRating(1)} className={(liveChatRating === 'good' ? "good-selected" : "good-normal") + (isHostDevice ? " hostdevice" : "")}>
                                </button>
                                <span>
                                    It was good
                                </span>
                            </div>
                            <div className="widget__overlay--votesection-thumbsitem">
                                <button  tabIndex={2} onClick={() => updateRating(-1)} className={(liveChatRating === 'bad' ? "bad-selected" : "bad-normal") + (isHostDevice ? " hostdevice" : "") } >
                                </button>
                                <span>
                                    Not great
                                </span>
                            </div>
                        </div>
                        <div className="widget__overlay--votesection-sections widget__overlay--votesection-votemessage">
                            {(liveChatRating === 'good') && <span>Glad to hear, What went well?</span> }
                            {(liveChatRating === 'bad') && <span>Sorry to hear that. What went wrong?</span> }
                        </div>
                        {
                            liveChatRating === 'good' &&
                            <div className="widget__overlay--votesection-sections widget__overlay--votesection-goodchips center">
                                { goodChips.current }
                            </div>
                        }

                        {
                            liveChatRating === 'bad' &&
                            <div className="widget__overlay--votesection-sections widget__overlay--votesection-badchips center">
                                { badChips.current }
                            </div>
                        }

                        {
                            liveChatRating !== '' && additionalComments.length > 0 &&
                            <div className="widget__overlay--votesection-sections widget__overlay--votesection-commentspreview">
                                <span>{ additionalComments }</span>
                            </div>
                        }

                        {
                            liveChatRating !== '' &&
                            <div className="widget__overlay--votesection-sections center">
                                <button className='widget__editbutton' onClick={ () => openAdditionalComments() } >
                                    <img src="/images/edit-icon.svg" alt="edit_icon" />
                                    <span>{ additionalComments.length > 0 ? "Edit " : "Add more comments" } </span>
                                </button>
                            </div>
                        }
                        
                        {
                            liveChatRating !== '' &&
                            <div className="widget__overlay--votesection-actionbutton">
                                <input className={(liveChatRating === '' ? 'widget__overlay--votesection-submitdisabled' : 'widget__overlay--votesection-submit') + (isHostDevice ? " hostdevice" : "")}  type="button" onClick={closeLiveChatRating} value="Send Feedback" />
                            </div>
                        }
                        
                    </div>
                </div>
            }
            {
                showAdditionalComments &&
                <div className="widget__overlay--additionalcomments">
                    <div className='widget__overlay--additionalcomments-header'>
                        <div className='widget-chevron-left'>
                            <button onClick={() => closeAdditionalComments(true) } ><img alt='cheveron-left' src="/images/chevron-left.svg"></img></button>
                        </div>
                        <div className='widget__overlay--additionalcomments-header-text'>Any more feedback to add?</div>
                        <div className='widget-chevron-right'></div>
                    </div>
                    
                    <textarea placeholder="Tell us more" className="widget__overlay--votesection-comment" value={ additionalComments } onChange={updateAdditionalCommentsValue}></textarea>
                    
                    <div className="widget__overlay--votesection-actionbutton additionalComments">
                        <input className={"widget__overlay--votesection-submit" + (isHostDevice ? " hostdevice" : "")} type="button" onClick={() => closeAdditionalComments(false) } value="Done" />
                    </div>
                </div>

                
            }
        </div>
    )
}

export default LiveChatRating