import React, { useEffect, useState } from 'react'
import './Banner.scss';
import { Button, makeStyles } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import { config } from '../../config/config';
import { handleAnalyticsBot } from '../../widget/util/commonUtil'

type BannerProps = {
    setShowverigyAge: any,
    endScreenMountingStyle: any,
    showverigyAge: any,
    setisOverlayScreenMounted: any
};

const theme = createTheme({
    typography: {
        "fontFamily": `"din-2014","Segoe UI",'Helvetica','Helvetica','sans-serif','Arial'`,
        "fontSize": 14
    }
});

const useStyles = makeStyles({

    buttonCancel: {
        backgroundColor: '#FFFFFF',
        color: '#305233',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        padding: '8px 24px 8px 24px',
        borderRadius: '2px',
        width: '93px',
        height: '36px',
        marginTop: '8px',
        transition: 'all .2s ease',
        '&:hover': {
            backgroundColor: '#F8F8F8',
            color: '#305233',
            border: 'none'
        },
        '&:focus': {
            backgroundColor: '#F8F8F8',
            color: '#305233',
            border: '2px solid #5795DB'
        },
        '&:active': {
            backgroundColor: '#F3F3F3',
            color: '#474947',
            border: 'none'
        },
    },

    buttonVerifyAge: {
        backgroundColor: '#4C8251',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        padding: '8px 24px 8px 24px',
        border: '1px solid #4C8251',
        borderRadius: '2px',
        width: '210px',
        height: '36px',
        marginTop: '8px',
        transition: 'all .2s ease',
        '&:hover': {
            backgroundColor: '#305233',
            color: '#FFFFFF',
            border: 'none'
        },
        '&:focus': {
            backgroundColor: '#305233',
            color: '#FFFFFF',
            border: '2px solid #5795DB'
        },
        '&:active': {
            backgroundColor: '#143017',
            color: '#FFFFFF',
            border: 'none'
        },
    }
})

const Banner = ({ setShowverigyAge, endScreenMountingStyle, showverigyAge, setisOverlayScreenMounted }: BannerProps) => {
    const classes = useStyles();
    const verifyAgeButtonClick = () => {
        window.open(config.DAN_MY_ACCOUNT_URL + "?i_cid=cb-nsw-id-verification", '_blank');
        handleAnalyticsBot('web:chatbot:nsw id verification:verify my age', 'chatbot nsw id verification', 'chatbotTriggerAdobeAnalytics');
    };

    const cancel = () => {
        setTimeout(() => {
            setShowverigyAge(false)
        }, 400);
        setisOverlayScreenMounted(false)
    }

    return (
        <ThemeProvider theme={theme}>
            <div className={"message "}>
                <div className={"slide-up" + endScreenMountingStyle} id="slide-up">
                    <div className="slideup-header-text">
                        <span>Online age check required for same-day deliveries in NSW from June 1</span>
                    </div>
                    <div className='text-align-container'>
                        <div className='img-align'>
                            <img src="/images/dot.svg" />
                        </div>
                        <div className="slideup-text">
                            <span>This is only mandatory for same-day deliveries in NSW after June 1.</span>
                        </div>
                    </div>
                    <div className='text-align-container'>
                        <div className='img-align'>
                            <img src="/images/dot.svg" />
                        </div>
                        <div className="slideup-text">
                            <span>Verify your ID now will save you time at checkout in the future. If you're a My Dan's member you'll only have to do this once.</span>
                        </div>
                    </div>
                    <div className='text-align-container'>
                        <div className='img-align'>
                            <img src="/images/dot.svg" />
                        </div>
                        <div className="slideup-text">
                            <span>Simply go to your account & follow the prompt in 'Personal Details' section.</span>
                        </div>
                    </div>

                    <div className="action-buttons">
                        <Button id="Cancel" disableRipple={true} onClick={cancel} style={{ fontWeight: 600, fontSize: 14 }} className={classes.buttonCancel}>Cancel</Button>
                        <Button className={classes.buttonVerifyAge} size='small' onClick={verifyAgeButtonClick} disableRipple={true} style={{ fontWeight: 600, fontSize: 14}} endIcon={<LaunchIcon fontSize="inherit" />}>Verify my age</Button>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )

}

export default Banner