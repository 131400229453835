import { postMessageToClient } from '../chatservice/communicationService';

const toTitleCase = (str: string) => {
    return str.replace( /\w\S*/g, function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

const pickRandomString = (arr : string[]) => {
    var index = Math.floor(Math.random() * arr.length); 
    return arr[index];
}

const getOrdinalSuffix = (n: number) => {
    return["st","nd","rd"][((n+90)%100-10)%10-1]||"th"
}


const isMobileOS = () => {
    return (/Android|aarch64|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))
}

const getUniqueId = (prefix:string, text: string) => {
    const textId = text?.split(' ')?.join('-')?.toLowerCase();
    return `${prefix?.toLowerCase()}-${textId}`;
}

const handleAnalyticsBot = (linkName: string, linkType: string, methodHandler:string ) => {
    const objCall = {
        data : {
            linkName : linkName,
            linkType: linkType,
        },
        methodHandler : methodHandler
    };
    postMessageToClient(objCall);
}

const removeIntents = ( msg:any, index:number) => {
    // Reference of message array 
    let updatedMessages = msg;
    //Removed the intent data from message
    updatedMessages[index].data = [];
}

export { 
    toTitleCase,
    pickRandomString,
    getOrdinalSuffix,
    isMobileOS,
    getUniqueId,
    handleAnalyticsBot,
    removeIntents
}