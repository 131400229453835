import React from 'react'
import './loader.scss';

const Loader = () => {
    return (
        <div className="loader">
            <div className="loader__one"></div>
            <div className="loader__two"></div>
            <div className="loader__three"></div>
            <div className="loader__four"></div>
        </div>
    )
}

export default Loader
