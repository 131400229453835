import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../reducers/index';
import { updateBannerInfo } from '../../actions/index';
import './NotificationBanner.scss';
import { BannerInfoDataModel } from '../../widget/models/NotificationBannerInfoModel';

const NotificationBanner = () => {
    const [htmlContent, setHtmlContent] = useState("");
    const [bannerAdditionalInfo, setBannerAdditionalInfo] = useState({ className: '', imgAltTxt: ''});
    const dispatch = useDispatch();
    const notificationBannerInfo: BannerInfoDataModel = useSelector((state: RootState) => { return state.botData.notificationBannerInfo });
    
    useEffect(() => {
        setHtmlContent(notificationBannerInfo.bannerTemplate || "");
        if(!bannerAdditionalInfo.className && notificationBannerInfo.bannerType) {
            setBannerAdditionalInfo(getBannerAdditionalInfo(notificationBannerInfo.bannerType?.toLowerCase()));
        }
    }, []);

    // Function to close the banner and set a timestamp in local storage
    const closeBanner = () => {
        const now = new Date().getTime();
        localStorage.setItem('bannerClosedTimestamp', now.toString());
        dispatch(updateBannerInfo({
            ...notificationBannerInfo,
            isBannerActive: false
        }))
    };

    const shouldDisplayBanner = () => {
        const closedTimestamp = localStorage.getItem('bannerClosedTimestamp');
        if (closedTimestamp) {
            const now = new Date().getTime();
            const EXPIRY_IN_MS =  (notificationBannerInfo.expiry || 0) * 60000;
            return now - parseInt(closedTimestamp, 10) >= EXPIRY_IN_MS;
        }
        return true;
    };

    const getBannerAdditionalInfo = (bannerType: string) => {
        switch (bannerType) {
            case 'notification':
               return {
                className: 'info',
                imgAltTxt: 'Notification Icon',
               }
            case 'warning':
                return {
                    className: 'warning',
                    imgAltTxt: 'Warning Icon',
                }
            case 'error':
                return {
                    className: 'error',
                    imgAltTxt: 'Error Icon'
                }
            default:
                return {
                    className: '',
                    imgAltTxt: ''
                }
        }  
    }

    return shouldDisplayBanner() ? (
        <div className={`notification-banner notification-banner--${bannerAdditionalInfo.className}`}>
            <div className="notification-banner__icon">
                <img alt={bannerAdditionalInfo.imgAltTxt} />
            </div>
            <div className="notification-banner__info">
                {htmlContent && (
                    <div className="notification-banner__info-text" dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    )}
                <button className="notification-banner__info-close" onClick={closeBanner}>Close</button>
            </div>
        </div>
    ): null;
};

export default NotificationBanner;

