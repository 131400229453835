import React from 'react'
import './brand.scss'
import { cdnImgUrl } from '../../config/config';

type size = {
    isLiveChat?: boolean,
    size?: string
}

const Brand = ({isLiveChat, size} : size) => {
    return (
        <div className={
            'brand ' + 
            (size ? 'brand--small' : '')
        }>
            { !isLiveChat && 
                <img src={`${cdnImgUrl}/chatbot-murphy-no-border-icon?&fmt=png-alpha`}
                alt="Murphy"
                className="brand__logo" />
            }

            { isLiveChat &&
                <img src="/images/icon-livechat-avatar.svg" 
                alt="Murphy"
                className="brand__logo" />
            }
        </div>
    )
}

export default Brand
