import React, { useRef, useState } from 'react';
import './product-nearby-store-list-detail.scss';
import '../message/message.scss';
import { postMessageToClient } from '../chatservice/communicationService';

import { Button, makeStyles } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

type ProductNearbyStoreListDetailProps = {
    nearbyStore: any,
    isHostDevice: any,
    toggleWidget: any
}

const theme = createTheme({
    typography: {
     "fontFamily": `"DIN 2014","Segoe UI",'Helvetica','Helvetica','sans-serif','Arial'`,
     "fontSize": 14
    }});

const useStyles = makeStyles({
    button: {
        backgroundColor: '#4C8251',
        color: '#fff',
        textTransform: 'none',
        fontStyle: 'normal',
        padding: '10px 24px',
        borderLeft:'1px solid #eee',
        borderRight:'1px solid #eee',
        borderBottom:'1px solid #eee',
        borderRadius:'0px',
        fontSize:'small',
        transition: 'all .2s ease',
        width:'100%',
        '&:hover': {
            backgroundColor: '#305233',
        },
        '&:focus-visible': {
            backgroundColor: '#305233',
            boxShadow: '0 0 0 3px #FFFFFF, 0 0 0 5px #5795DB',
            borderRadius: '2px',
        },
        '&:active': {
            backgroundColor: '#143017',
            border:'none'
        }
    }
    })    

const formatStoreName = (storeName: any) => {
    const store = storeName.replaceAll("Dan Murphy's ", "");
    return store.charAt(0).toUpperCase() + store.slice(1).toLowerCase();
}

const getPackType = (product:any) =>{
    return product.availablePackTypes.sort((a: any, b: any) => a.UnitQty > b.UnitQty ? 1 : -1)[0];
}


const getInventoryStatus = (product: any, nearbyStore:any) => {
    const packTypeToShow = getPackType(product);
    if (packTypeToShow.Key === 'Bottle') {
        return `${nearbyStore?.storeAvailableQuantity} ${packTypeToShow.DisplayName} at ${formatStoreName(nearbyStore?.storeName)}`;
    }
    return `${Math.ceil(nearbyStore?.storeAvailableQuantity / packTypeToShow.UnitQty)} ${packTypeToShow.DisplayName} of ${packTypeToShow.UnitQty} at ${formatStoreName(nearbyStore?.storeName)}`;
}

const ProductNearbyStoreListDetail = ({ nearbyStore, toggleWidget, isHostDevice }: ProductNearbyStoreListDetailProps) => {

   let product = nearbyStore.product;
   let stockText = getInventoryStatus(product,nearbyStore);
   let [timeStr1,timeStr2] = nearbyStore.storeOpeningHours || ['',''];


    const onClickHandler = (e: any) => {


        const analyticsCall = {
            data: {
                linkName: `web:chatbot:product availability:find nearby:purchase from store:${nearbyStore.storeName}`,
                linkType: 'chatbot product availability',
            },
            methodHandler: "chatbotTriggerAdobeAnalytics"
        };
        postMessageToClient(analyticsCall);

        // init change url
        const objCall = {
            data: {
                prodDetailsUrl: product.productDetailsUrl,
                storeId: nearbyStore.storeNo,
                storeName: formatStoreName(nearbyStore.storeName),
                queryString: { i_cid: 'cbprodavail-findnearby' }
            },
            methodHandler: "chatbotInitChangeUrl"
        };

    //minimize widget
    toggleWidget(false);
    postMessageToClient(objCall);
}

const classes = useStyles();

return (
    <div className='product'>
        <div className='product__location'>
            <div className='product__title'>
                <span className="product__suburb">{nearbyStore.suburb.toLowerCase()}</span>
                <span className='product__distance'>{nearbyStore.distance} km</span>
            </div>
        </div>
            <div className='product__address'>{nearbyStore.addressLine1} <span className='store__suburb'>{nearbyStore.suburb.toLowerCase()}</span> {nearbyStore.postcode}</div>
            <div className='product__stock'>
                <span className='product__stock--quantity'><b>{stockText?.split('each')[0]} each </b></span>
                 <span> {stockText?.split('each')[1]} </span>
            </div>

        <div className={'product__hours ' + (isHostDevice && 'product__hours--device')} >
            {<div className='product__date'>
                <span><b>{timeStr1} {timeStr2 && '-'} </b> <span className="product__date--opening-hour"> {timeStr2}</span></span>{console.log("time str ", timeStr1 , timeStr2)}
            </div> }
        </div>

        <div className='product__button'>
            <Button className={classes.button} id="purchase-from-link" onClick={onClickHandler} disableRipple={true}>Buy from {nearbyStore.storeName}</Button>
        </div>
    </div>
);
}

export {
    ProductNearbyStoreListDetail
}