import React from 'react';
import '../../message.scss';
import '../messageOrderTracking.scss';
import MessageUnit from '../MessageUnit';
import MessageLink from '../../MessageLink';
import { config, orderTrackingStatusConfig, orderTrackingConfig } from '../../../../config/config';
import { displayCourierNameText } from '../orderTrackingService';

type messageConsignmentProps = {
    consignment: any,
    totalNumberOf: number,
    index: number,
    mapStatus: any,
    toDate: any,
    orderTrackingUrl: string,
    isAllDelayed: boolean,
    indexParent: any,
    msgs: any
};

const MessageConsignment = ({consignment, totalNumberOf, index, mapStatus, toDate, orderTrackingUrl, isAllDelayed, indexParent, msgs}: messageConsignmentProps) => {    
    const status = mapStatus(consignment.status, consignment.expectedDeliveryDate);    
    const isDeliveryDelayed = status === orderTrackingStatusConfig.DELAYED;
    const isDeliveryFailed = status === orderTrackingStatusConfig.DELIVERY_FAILED;    
    const isDelivered = status === orderTrackingStatusConfig.DELIVERED;
    const dateDisplayText = toDate(isDelivered || isDeliveryFailed ? consignment.statusDate : consignment.expectedDeliveryDate);
    const isAusPost = consignment.courierName === 'AusPost';
    const courierName = displayCourierNameText(consignment);
    return (         
        <div>                   
            <div key={index} className="message__text orderTrackingMessage__delivery orderTrackingMessage__delivery--multiple">
                <div>Delivery <MessageUnit info={index + 1}/> of <MessageUnit info={totalNumberOf}/></div>
                <div>Delivery Number: <MessageUnit info={consignment.consignmentNumber}/></div>
                { 
                    isAusPost ? 
                        dateDisplayText === 'N/A' ? <div>Delivered by: <MessageUnit info={"Australia Post"}/> </div> : <div>Delivered by: <MessageUnit info={dateDisplayText}/> by <MessageUnit info={"Australia Post"}/>.</div>
                        :                
                        isDeliveryFailed ?
                            <div>
                                <div>Status: <MessageUnit info="Unsuccessful"/></div>
                                <div>Attempted delivery date: <MessageUnit info={consignment.statusDate}/> </div>
                                <div>This delivery is rebooked to arrive in 2 business days. If that doesn’t suit you, rebook your delivery below</div>
                            </div>
                            :
                            isDeliveryDelayed ? 
                                isAllDelayed ?
                                <div className="orderTrackingMessage__delivery">
                                    <div>Unfortunately, your delivery <MessageUnit info={consignment.consignmentNumber}/> is <b>delayed</b>. </div>
                                    <br />
                                    <div>Please check your tracking below. If its only been a day or so, bear with us. Longer than a week? Please get in touch.</div>
                                </div>     
                                :
                                <div>
                                    <div>Status: <MessageUnit info={mapStatus(consignment.status, consignment.expectedDeliveryDate)}/></div>
                                    <div>Delivered by:<MessageUnit info="TBA"/></div>                     
                                    <div>Please check your tracking below. If its only been a day or so, bear with us. Longer than a week? Please get in touch.</div>
                                </div>
                                :
                                <div>
                                    <div>Status: <MessageUnit info={mapStatus(consignment.status, consignment.expectedDeliveryDate)}/></div>
                                    <div>Delivered by: <MessageUnit info={dateDisplayText}/> by <MessageUnit info={courierName} /></div>
                                </div>
                }
                {!isAusPost && status === 'N/A' && <div>{config.TRACK_ORDER_EMPTY_STATUS}</div> }
                { courierName.toLowerCase() === 'xl express'
                    &&
                    <span><br />
                    To track your delivery, view detailed tracking below and enter the following number {consignment.barCodeNumber}..
                    </span>
                }

                
                
            </div>
            <MessageLink text={orderTrackingConfig.QUICK_REPLY_TEXT} link={orderTrackingUrl}indexParent={indexParent}msgs={msgs}/>
        </div>
    );   
};

export default MessageConsignment;