import moment from 'moment';
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import './OrderExpandedTile.scss';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Stepper, Step, StepLabel, Button, Chip, withStyles, StepConnector, styled, StepIconProps } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LaunchIcon from '@material-ui/icons/Launch';
import InfoIcon from '@material-ui/icons/Info';

import { getOrderDetailByShopperId } from '../../chatservice/chatserverApis';
import { getShippingType } from './VerifiedOrderUtils';
import { DialogflowQueryType, MessageTypes, Speaker } from '../../models/enum';
import { dialogflowEvent, orderTrackingStatusConfig, orderTrackingStatusMapping, vote } from '../../../config/config';
import { updateAnalyticsStatus } from '../../chatservice/dialogflowQuery';
import { setOrderForTile, setShowOrderTrackingDetail } from '../../../actions';
import { Check } from '@material-ui/icons';
import SkeletonLoaderOrderTracking from '../../skeleton-loader/SkeletonLoaderOrderTracking';

type OrderExpandedTileProps = {
    order: any,
    quickReplyHandler: any,
    backToWidget: any,
    sessionId: any,
    userId: any,
    setMessages: any,
    msgs: any
}

const OrderExpandedTile = ({order, quickReplyHandler, backToWidget, sessionId, userId, setMessages, msgs }: OrderExpandedTileProps) => {
    const shopperId = useSelector((state: RootState) => { return state.botData.shopperId }); 
    const widgetSessionId = useSelector((state: RootState) => { return state.botData.widgetSessionId }); 

    const tileStartRef: any = useRef(null);
    const [isDataLoaded, setIsDataLoaded] = React.useState(false);
    const [lastDeliveryDate, setLastDeliveryDate] = React.useState<Date>(new Date());
    const [deliveryType, setDeliveryType] = React.useState<string>('');
    const [orderData, setOrderData] = React.useState<any>(null);
    const dispatch = useDispatch();
    const steps = ['Processed', 'In depot', 'On its way', 'Delivered'];

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const processAcceptance = (e: any, isSuccessful: boolean) => {
        updateAnalyticsStatus(sessionId, userId, order.intentGroupId, isSuccessful);
        
        const intentGroupInfo = {
            intentGroupId: order.intentGroupId,
            intentGroupName: order.intentGroupName
        }

        let eventName = (isSuccessful) ? dialogflowEvent.Authenticated_Chatbot_Additional_Questions : dialogflowEvent.Authenticated_Chatbot_Contact_Options;
        let disPlayClickAsText = true;
        let renewIntentGroup = false;

        quickReplyHandler(e, DialogflowQueryType.Event, eventName, disPlayClickAsText, null, renewIntentGroup, intentGroupInfo);

        backToWidget(true);
    }

    const scrollToStart = () => {
        tileStartRef?.current?.scrollIntoView({ behavior: "smooth" });
    }; 

    useEffect(() => {
        scrollToStart();
        // fetch article data
        if (isDataLoaded === false) {
            getOrderDetailByShopperId(shopperId, widgetSessionId, order.orderId, sessionId, userId)
                .then((result:any) => {
                    if (result.statusCode && result.statusCode !== 200) {
                        dispatch(setShowOrderTrackingDetail(false)); 
                        dispatch(setOrderForTile(null));

                        setMessages((messages: any) => [...messages, ...[{ text: `We can no longer show you these delivery details.`, from: Speaker.Murphy, type: MessageTypes.Message }]]);
                        setMessages((messages: any) => [...messages, ...[{ text: `To do this, you need to log in to the account associated with the orders.`, from: Speaker.Murphy, type: MessageTypes.Message }]]);
                        
                        if (shopperId === 0) {
                            setMessages((messages: any) => [...messages, ...[{ text: null, from: Speaker.Murphy, type: MessageTypes.QuickReplyPayload, data: [{
                                intentGroupId : order.intentGroupId,
                                intentGroupName : order.intentGroupName,
                                target : "route",
                                text : "Login",
                                type : "url",
                                uri : "https://www.danmurphys.com.au/dm/mydans/login"
                            }] }]]);
                        }
                    } else {
                        setIsDataLoaded(true);
    
                        setOrderData(result.data);
    
                        let deliveryDatesAllConsignments = result.data.recipients[0].productSegments.map((a:any) => {
                            let orderDate = moment(result.data.date);
                            let deliveryDate = (result.data.recipients[0].deliveryDate) ? moment(result.data.recipients[0].deliveryDate) : orderDate;
    
                            let { deliveryType, calculatedDeliveryDate } = getShippingType(a.shippingType, orderDate, deliveryDate)
    
                            // if the delivery type is Supplier, show a standard delivery
                            setDeliveryType((deliveryType === 'Supplier')? 'Standard' : deliveryType);
    
                            // create a new object inside result data
                            a['calculatedDeliveryDate'] = calculatedDeliveryDate;
                            return calculatedDeliveryDate;
                        });
    
                        let sortedDates = deliveryDatesAllConsignments.sort(
                            (a:Date, b:Date) => new Date(b).getTime() - new Date(a).getTime()
                        );
                        
                        // get the latest date
                        setLastDeliveryDate(sortedDates[0]);
                    }
                });
        }
    }, []);

    const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
        () => ({
          display: "flex",
          height: 22,
          alignItems: "center",
          "& .QontoStepIcon-completedIcon": {
          },
          "& .QontoStepIcon-circle": {
          },
          "& .QontoStepIcon": {
          },
          "& .QontoStepIcon-activeIcon":{
          }
        })
      );

    function QontoStepIcon(props: StepIconProps) {
        const { active, completed, className } = props;
        return (
            <QontoStepIconRoot className={className}>
                {!active ? (
                    completed ? (
                        <div className="QontoStepIcon-circle">
                            <Check className="QontoStepIcon-completedIcon"/>
                        </div>
                    ) :
                    ( <div className="QontoStepIcon"></div> )
                ) : 
                (
                    <div className='QontoStepIcon-circle active'>
                        <div className="QontoStepIcon-activeIcon"></div>
                    </div>
                )}
            </QontoStepIconRoot>
        );
    }

    const Connector = withStyles({
        alternativeLabel: { top: 9 },
        active:{'& $line': { borderColor: '#55925B' } },
        completed: { '& $line': { borderColor: '#55925B' } },          
        line: {
          borderTopWidth: 2,
        },
        vertical: {
          marginLeft: 9,
        },        
      })(StepConnector); 
    
    let consignments = orderData?.recipients[0].productSegments;

    return (
        <div className='scroller'>
            {
                isDataLoaded === true && 
                <div className='expandedTiles' ref={ tileStartRef } >
                    <div className='expandedTiles-summary'>
                        <span className='expandedTiles-deliveryType'>{ deliveryType } Delivery</span>
                        {
                            deliveryType !== orderTrackingStatusConfig.DELIVERY_CANCELLED && 
                            <span className='expandedTiles-deliveryDate'>Your order should be complete by { moment(lastDeliveryDate).format('DD MMM') } </span>
                        }
                        
                        <span className='expandedTiles-orderNumber'>Confirmed { moment(orderData?.date).format('DD MMM YYYY') } • Order #{ orderData?.orderId }</span>
                        { consignments?.length > 1 && <div className='expandedTiles-multiConsignment'><InfoIcon className='expandedTiles-multiConsignment--infoIcon'>InfoIcon</InfoIcon><span>Your order comes in { consignments?.length } deliveries</span></div> }
                        <span className='expandedTiles-timelineTitle'>Delivery timeline</span>
                    </div>

                    {
                        consignments?.map((data: any, index: number) => {
                            let mappedStatus = orderTrackingStatusMapping[data.statusDescription] || data.statusDescription;
                            let activeStep = steps.indexOf(mappedStatus);

                            // if the active step is 3, increase it by one to complete it
                            if (activeStep === 3) activeStep = 4;
                            return (
                            <Accordion className='accordionCustomStyle' expanded={ (consignments.length === 1) || expanded === `panel_${ index}` } onChange={handleChange(`panel_${ index }`)}>
                                <AccordionSummary
                                    expandIcon={ (consignments.length > 1) ? <ExpandMoreIcon /> : null }
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'
                                    className='accordionCustomStyle-header'
                                >
                                    <Typography className='accordionCustomStyle-header'>
                                        <div className='accordionCustomStyle-header--line1'>{ consignments?.length > 1 && <span className='dark'>Delivery { index + 1} of { consignments?.length }: </span> }<Chip className='accordionCustomStyle-chip' label={ data.statusDescription } /></div>

                                        {
                                            deliveryType !== orderTrackingStatusConfig.DELIVERY_CANCELLED && 
                                            <div className='accordionCustomStyle-header--line2'>Delivered by <span className='medBold'>{ data.calculatedDeliveryDate?.format('DD MMM') }</span> via <span className='bold'>{ data.carrierName ?? data.displayTitle ?? "[Courier not set]" }</span></div>
                                        }
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails className='accordionCustomStyle-details'>
                                    <Typography>
                                        <div className='accordionCustomStyle-consignmentNo'>Consignment No: { data.consignmentNo }</div>
                                        <div className='accordionCustomStyle-stepper'>
                                            <Stepper activeStep={ activeStep } alternativeLabel connector={<Connector/>}>
                                                {steps.map((label) => (
                                                    <Step key={label}>
                                                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                                    </Step>
                                                ))}
                                            </Stepper>
                                        </div>

                                        {
                                            data.products?.map((product: any, product_index: number) => {
                                                return (
                                                    <div className='accordionCustomStyle-products'>
                                                        <div className='accordionCustomStyle-products--image'>
                                                            { 
                                                                product.productImage !== null &&
                                                                <img src={ product.productImage } width='40px' height='40px' alt='productImage' />
                                                            }
                                                            { 
                                                                product.productImage === null &&
                                                                <img src="/images/no-image.png" width='40px' height='40px' alt='productImage' />
                                                            }
                                                        </div>
                                                        <div className='accordionCustomStyle-products--detail'>
                                                            <div className='accordionCustomStyle-products--name'>{ product.name }</div>
                                                            <div className='accordionCustomStyle-products--count'>{ product.quantity } { product.packType }</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        <div className='accordionCustomStyle-trackingBtn'>
                                            {
                                                data.trackUrl &&
                                                <Button
                                                    variant='outlined'
                                                    color='primary'
                                                    endIcon={<LaunchIcon>LaunchIcon</LaunchIcon>}
                                                    href={ data.trackUrl }
                                                    target='_blank'
                                                >
                                                    View detailed tracking
                                                </Button>
                                            }
                                            
                                            {
                                                !data.trackUrl &&
                                                <div className='accordionCustomStyle-info' >
                                                    <InfoIcon className='accordionCustomStyle-info--icon' >InfoIcon</InfoIcon>
                                                    Detailed tracking is unavailable
                                                </div>
                                            }
                                            
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            )
                        })
                        
                    }
                    
                    <div className="vote">
                        <div className="vote__question">{vote.QUESTION_TEXT_ORDERTRACKING}</div>
                        <div className="vote__button-container">
                            <div className="vote__button" id="yes-vote-button" aria-label='smile' onClick={(event) => processAcceptance(event, true)}>{vote.YES_TEXT}</div>
                            <div className="vote__button" id="no-vote-button" aria-label='frown' onClick={(event) => processAcceptance(event, false)}>{vote.NO_TEXT}</div>
                        </div>
                    </div>
                </div>
            }

            { isDataLoaded === false && <SkeletonLoaderOrderTracking/>}
        </div>
    )
}

export default OrderExpandedTile