import React from 'react';
import '../messageOrderTracking.scss';
import MessageUnit from '../MessageUnit';

type messageFailedProps = {
    orderNumber: string,
    dateDisplayText: string
};

const MessageFailed = ({orderNumber, dateDisplayText}: messageFailedProps) => {
    return (
        <div className="orderTrackingMessage__delivery">
            <div>Unfortunately, we attempted your delivery <MessageUnit info={orderNumber}/> at <MessageUnit info={dateDisplayText} /> but were unsuccessful. </div>
            <div>Your delivery is rebooked to arrive in 2 business days.</div>
            <div>If the time doesn’t suit you, rebook your delivery below</div>
        </div>            
    );
};

export default MessageFailed;
