import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ResultCatergoryProps } from '../models/fawTypes';
import './resultCategory.scss';
import { updateSelectedType, updateCurrentView, openPdpDansPage } from '../../actions/index';
import { RootState } from '../../reducers/index';
import { getUniqueId } from '../../widget/util/commonUtil';

const ResultCatergory = ({ fawRecommendation, categoryType, intentData }: ResultCatergoryProps) => {

  const [orientation, setOrientation] = useState('');
  const [isMoving, setIsMoving] = useState(false);

  const dispatch = useDispatch();

  const storeName = useSelector((state: RootState) => { return state.fawData.selectedStoreName });
  const storeID = useSelector((state: RootState) => { return state.fawData.selectedStoreId });

  const fawRecommendations = [...fawRecommendation];
  const recommendationResults = fawRecommendations.length > 5 ? [...fawRecommendations.splice(0, 5)] : fawRecommendations ;

  const setScreenOrientation = () => {

    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrientation('portrait');
    }
   
    if (window.matchMedia("(orientation: landscape)").matches) {
        setOrientation('landscape');
    }
  }

  useEffect(()=> {
    setScreenOrientation();
    return () => { setOrientation('') }
  }, [])

  window.addEventListener("resize", setScreenOrientation);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      partialVisibilityGutter: 105
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 105
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 105
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 125
    },
    smallMobile: {
      breakpoint: { max: 320, min: 0 },
      items: 1,
      partialVisibilityGutter: 90
    }
  };
  
  const showRecommendationList = () => {
    dispatch(updateSelectedType(categoryType));
    dispatch(updateCurrentView('recommendationList'));
  }

  const openPDPpage = (event:any, wineLink: string, wineName:string) => {
    if(isMoving) event.preventDefault();;
    
    if (event.currentTarget.parentNode.classList.contains('react-multi-carousel-item--active')) {
      dispatch(openPdpDansPage(wineLink, storeName, storeID, wineName, categoryType, intentData));
    }
  }

  const isCenterModeOn = () => {
    //Center mode on with landscape and Ipad portrait mode
    return (orientation === 'landscape' && recommendationResults.length > 2); 
  }

  const isPartialModeOn = () => {
    //Partial mode on with portrait and non Ipad devices
    return (orientation === 'portrait') && (recommendationResults.length > 1);
  }
  
  return (
    <div className="result-category" id={getUniqueId('result-category', categoryType)}>
      {/* top section */}
      <section className="heading">
        <span><b>{categoryType}</b></span>
        { recommendationResults.length ? <span className="see-more-link" id="see-more-link" onClick={ () => showRecommendationList() } >See more</span> : null}
      </section>
      {/* Recommendations */}
      {recommendationResults.length? 
        <Carousel
          swipeable={true}
          draggable={false}
          responsive={responsive}
          ssr={false}
          infinite={true}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={600}
          partialVisible={ isPartialModeOn() }
          showDots={ (recommendationResults.length === 1) ? false: true }
          arrows={ recommendationResults.length === 1 ? false: true }
          containerClass= { recommendationResults.length === 1 ? "carousel-container-single": "carousel-container-multi" }
          dotListClass="custom-dot-list-style"
          centerMode={isCenterModeOn ()}
          beforeChange={() => setIsMoving(true)}
          afterChange={() => setIsMoving(false)}
          focusOnSelect={true}
        >
          {recommendationResults.map((item, index) => 
          <div key={index} id={getUniqueId('wine-result', (index+1)?.toString())}  className="wine-container" onClick={(event) => openPDPpage(event, item.wineLink, item.wineName)}>
            <div className="wine-info">
              <div className="brandName">{item.brandName}</div>
              <div className="wineName">{item.wineName}</div>
              <div className="price-section">
                <span className="price">${item.price}</span> 
                <span className="per-bottle">per bottle</span>
              </div>
              <div className="rating-review">
                <div>
                  <img className="star" src="/images/star.png"/>
                  <span className="rating">{(Math.round(item.rating * 10) / 10).toFixed(1)}</span>
                </div>
                <span className="review">({item.review} reviews)</span>
              </div>
            </div>
            <div className="wine-image">
              <img className="image" 
                            src={item.imageURL} 
                            alt={item.wineName}/>
            </div>
          </div>)
          }
        </Carousel>: 
        <div className="no-result-found">No results found. Consider widening your filters.</div> 
      }
    </div>
  )
}
  
export default ResultCatergory