import React from 'react';
import '../messageOrderTracking.scss';
import MessageUnit from '../MessageUnit';

type messageInProgressProps = {
    courierName: string,
    label: string,
    status: string,
    orderNumber: string,
    dateDisplayText: string,
    barCodeNumber: string
};

const MessageInProgress = ({courierName, label, status, orderNumber, dateDisplayText, barCodeNumber}: messageInProgressProps) => {
    return (
        <div className="orderTrackingMessage__delivery">
            <div>Your order <MessageUnit info={orderNumber}/> is <MessageUnit info={status}/>. It’s {label} on <MessageUnit info={dateDisplayText}/> by <MessageUnit info={courierName}/> .

            { courierName.toLowerCase() === 'xl express'
                    &&
                    <span><br /><br />
                    To track your delivery, view detailed tracking below and enter the following number { barCodeNumber }.
                    </span>
                }
                
            
            </div>
        </div>            
    );
};

export default MessageInProgress;
