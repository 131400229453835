import React, { useEffect } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

type AutoEndSessionProps = {
    openWelcomeMessage: () => void,
    setShowWelcomeMessage: (type: boolean) => void,
    showWelcomeMessage: boolean,
    setQRPayloadLoaded: (type: boolean) => void,
    qRPayloadLoaded: boolean
}

const theme = createTheme({
    typography: {
     "fontFamily": `"DIN-2014"`,
     "fontSize": 14
    }});

const useStyles = makeStyles({
    button: {
        backgroundColor: '#4C8251',
        color: '#fff',
        textTransform: 'none',
        fontStyle: 'normal',
        fontSize: '16px',
        padding: '12px 24px',
        transition: 'all .2s ease',
        width: '100%',
        height: '42px',
        fontWeight: 600,
        
        '&:hover': {
            backgroundColor: '#305233',
            color: '#fff',
        },
        '&:focus': {
            backgroundColor: '#305233',
            color: '#fff',
            border:'2px solid #5795DB'
        },
        '&:active': {
            backgroundColor: '#143017',
            color: '#fff',
        },
        '&:disabled': {
          backgroundColor: '#D1D2D1',
          color: '#fff',
      },
    }
  })

const AutoEndSession = ({ openWelcomeMessage,setShowWelcomeMessage,showWelcomeMessage,setQRPayloadLoaded, qRPayloadLoaded }: AutoEndSessionProps) => {
    const classes = useStyles();
    
    const restartChat = () => {
        setShowWelcomeMessage(false);
        setQRPayloadLoaded(false);
    }

    useEffect(() => {
        if(!showWelcomeMessage){
            openWelcomeMessage();
        }
    }, [showWelcomeMessage, qRPayloadLoaded])
    
    return (
        <ThemeProvider theme={theme}>
        <div className="widget__overlay--endsession">
            <div className="warning-text">
                <div>Your chat has ended.</div>
            </div>
            <div className="action-buttons" style={{ justifyContent: 'center' }}>
                <Button id="start-again-button" disableRipple={true} className={classes.button} onClick={() => restartChat()}>Start again</Button>
            </div>
        </div>
        </ThemeProvider>
    )
}

export default AutoEndSession