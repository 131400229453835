/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { postMessageToClient } from '../chatservice/communicationService';
import { updateAnalyticsStatus } from '../chatservice/dialogflowQuery';
import { MessageTypes } from '../models/enum';
import './message.scss';
import { getUniqueId, removeIntents } from '../../widget/util/commonUtil';

type messageLinkProps = {
    text: string,
    link: string,
    toggleWidget?: any,
    target?: string,
    sessionId?: any,
    userId?: any,
    intentGroupId?: any,
    type?: any,
    indexParent: number,
    msgs: any
}

const MessageLink = ({text, link, toggleWidget, target, sessionId, userId, intentGroupId, type, indexParent, msgs }: messageLinkProps) => {

    const onClickHandler = (link:string, event: any) => {
        removeIntents(msgs, indexParent)
        event.preventDefault();
        event.stopPropagation();
        
        postMessageToClient({name: "chatbotQRClicked", text: text});
        // if message type is DQR post a success to analytics when clicked.
        if (type === MessageTypes.DynamicQr) {
            updateAnalyticsStatus(sessionId, userId, intentGroupId, true);
        }

        if (target === 'route') {
            // init change url
            const objCall = {
                data: {
                    prodDetailsUrl: new URL(link).pathname,
                    storeId: '',
                    storeName: '',
                    queryString: { i_cid: 'cbprodtracking-loaddashboard' }
                },
                methodHandler: "chatbotInitChangeUrl"
            };

            //minimize widget
            if (toggleWidget)
                toggleWidget(false);
            postMessageToClient(objCall);
        } else if (target === 'self') {
            window.parent.location.href = link;
        } else {
            window.open(link, "_blank");
        }
    }
    
    const externalLink = (type && (type === MessageTypes.Form || type === MessageTypes.DynamicQr)  && sessionId && userId && intentGroupId) ? `${link}&msid=${sessionId}&muid=${userId}&migid=${intentGroupId}` : link;
    
    return (
        <div className='message__text--quick-reply'>           
            <a href={ externalLink } onClick={(event) => onClickHandler(externalLink, event)} className="message__link" id={getUniqueId('message-link', text)} target="_blank" rel="noreferrer">
                <span>{text}</span>
                <img src="/images/external-link-chip.svg" alt="" /> 
            </a>           
        </div>
    )
}

export default MessageLink  