import React from 'react'
import './SkeletonLoaderNomineeChange.scss'
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonLoaderNomineeChange = () => {
    return (
        <div className="updateNomineeDiv">
            <div className="updateNomineeDiv__header">
                <Skeleton variant='rect' animation="wave" width={318} height={68} />
            </div>
            <div className="updateNomineeDiv__orderDetails">
                <Skeleton variant='rect' animation="wave" width={318} height={155} />
            </div>
            <div className="updateNomineeDiv__nomineeDetails">
                <div className="updateNomineeDiv__nomineeDetails__header">
                    <Skeleton variant='rect' animation="wave" width={318} height={28} />
                </div>
                <div className="updateNomineeDiv__nomineeDetails__inputDiv">
                    <Skeleton variant='rect' animation="wave" width={318} height={28} />
                    <Skeleton variant='rect' animation="wave" width={318} height={28} />
                    <Skeleton variant='rect' animation="wave" width={318} height={48} />
                </div>
                <div className="updateNomineeDiv__nomineeDetails__inputDiv">
                    <Skeleton variant='rect' animation="wave" width={318} height={28} />
                    <Skeleton variant='rect' animation="wave" width={318} height={28} />
                    <Skeleton variant='rect' animation="wave" width={318} height={48} />
                </div>
            </div>
            <div className="updateNomineeDiv__nomineeDetails__inputDiv">
                <Skeleton variant='rect' animation="wave" width={318} height={58} />
            </div>
            <div className="updateNomineeDiv__nomineeDetails__inputDiv">
                <Skeleton variant='rect' animation="wave" width={318} height={63} />
            </div>
            <div className="updateNomineeDiv__nomineeDetails__inputDiv">
                <Skeleton variant='rect' animation="wave" width={318} height={42} />
                <Skeleton variant='rect' animation="wave" width={318} height={42} />
            </div>
        </div>
    )
}

export default SkeletonLoaderNomineeChange