import React from 'react';
import './checkbox.scss';

type checkboxProps = {
    label: string,
    setCheckboxValue: React.Dispatch<React.SetStateAction<boolean>>;
}

const Checkbox = ({label, setCheckboxValue}: checkboxProps) => {
    return(
      <div>
        <label className="checkbox-container">
          <input type="checkbox" name="checkbox" onChange={(e => setCheckboxValue(e.target.checked))} tabIndex={-1} />
          <span className="checkmark"  tabIndex={0}></span>
          <div className="checkbox-container__label" dangerouslySetInnerHTML={{__html: label}}></div>
        </label>
      </div>
    );
}

export default Checkbox;

