
import { orderTrackingStatusMapping, orderTrackingStatusConfig } from '../../../config/config';
import { isPast } from '../../util/dateTimeHelper';

const mapStatus = (status: string, dueDateString: string) : string => {
    let mappedStatus = orderTrackingStatusMapping[status] || status;

    if (isPast(dueDateString) && 
        mappedStatus !== orderTrackingStatusConfig.DELIVERED && 
        mappedStatus !== orderTrackingStatusConfig.DELIVERY_CANCELLED &&
        mappedStatus !== status) {
        mappedStatus = orderTrackingStatusConfig.DELAYED;
    }

    return mappedStatus || "N/A";
}

const filterByNonePickUp = (consignmentNotes: any) => {
    // 'Pickup' means courier picking up from Endeavour
    return consignmentNotes.filter((item: any) => item.deliveryType !== 'Pickup');  
}

const getSummaryOpenerText = (message: any) => {
    // todo: return different text based on status
    return message.text;
}

const displayCourierNameText = (consignment: any) => {
    const courierName =  consignment.courierName || '';
    const carrierName =  consignment.carrierName || '';
    
    if(carrierName) {
        return carrierName.trim().toLowerCase() === 'endeavour own fleet' ? "Endeavour Delivery" : carrierName;
    }

    if(courierName) {
        return courierName.trim().toLowerCase() === 'nexday' ? "Endeavour Delivery" : courierName;
    }
}

export { 
    mapStatus,
    filterByNonePickUp,
    getSummaryOpenerText,
    displayCourierNameText
}