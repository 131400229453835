import * as ACTIONS from '../actions/actionTypes';
const defaultState = {
  isWidgetOpenFirstTime: true,
  liveChatUserInitialPosition: '',
  liveChatUserQueuePosition: '',
  isCustomQueueMessageTriggered: false,
  chipSelection: [],
  additionalComments: '',
  showAdditionalComments: false,
  showOrderTrackingDetail: false,
  showNomineeChangeScreen: false,
  showNomineeChangeFailedScreen: false,
  shopperId : 0,
  widgetSessionId : "",
  orderDataExpandedTile: null,
  clickedPickupOrder: null,
  lastFollowUpEventName: '',
  userFirstName: '',
  notificationBannerInfo: {
    "bannerTemplate": "",
    "bannerType": "",
    "expiry": 0,
    "isBannerActive": false
  }
}
const botData = (state = defaultState, action: any) => {
  switch (action.type) {
    case ACTIONS.IS_WIDGET_OPEN_FIRST_TIME:
      return {
        ...state,
        isWidgetOpenFirstTime: action.isWidgetOpenFirstTime
      }
    case ACTIONS.UPDATE_LC_USER_INITIAL_POSITION:
      return {
        ...state,
        liveChatUserInitialPosition: action.liveChatUserInitialPosition
      }
    case ACTIONS.UPDATE_LC_USER_POSITION:
      return {
        ...state,
        liveChatUserQueuePosition: action.liveChatUserQueuePosition
      }
    case ACTIONS.IS_LC_MESSAGE_TRIGGERED:
      return {
        ...state,
        isCustomQueueMessageTriggered: action.isCustomQueueMessageTriggered
      }
    case ACTIONS.FEEDBACK_CHIP_SELECTION:
        return {
          ...state,
          chipSelection: action.chipSelection
      }
    case ACTIONS.FEEDBACK_ADDITIONAL_COMMENTS:
        return {
          ...state,
          additionalComments: action.additionalComments
      }
    case ACTIONS.SHOW_ORDERTRACKING_DETAIL:
        return {
          ...state,
          showOrderTrackingDetail: action.showOrderTrackingDetail
      }
    case ACTIONS.SHOW_NOMINEE_CHANGE_SCREEN:
        return {
          ...state,
          showNomineeChangeScreen: action.showNomineeChangeScreen
      }
    case ACTIONS.SHOW_NOMINEE_CHANGE_Failed_SCREEN:
        return {
          ...state,
          showNomineeChangeFailedScreen: action.showNomineeChangeFailedScreen
      }
    case ACTIONS.CLICKED_PICKUP_ORDER:
      return {
          ...state,
          clickedPickupOrder: action.clickedPickupOrder
      }
    case ACTIONS.SHOPPER_ID:
      return {
        ...state,
        shopperId: action.shopperId
    }
    case ACTIONS.WIDGET_SESSION_ID:
      return {
        ...state,
        widgetSessionId: action.widgetSessionId
    }
    case ACTIONS.ORDERDATA_EXPANDED_TILE:
      return {
        ...state,
        orderDataExpandedTile: action.orderDataExpandedTile
    }
    case ACTIONS.LAST_FOLLOWUP_EVENT_NAME:
      return {
        ...state,
        lastFollowUpEventName: action.lastFollowUpEventName
    }
    case ACTIONS.UPDATE_USER_FIRST_NAME:
      return {
        ...state,
        userFirstName: action.userFirstName
    }
    case ACTIONS.UPDATE_BANNER_INFO:
      return {
        ...state,
        notificationBannerInfo: {
          "bannerTemplate": action.bannerInfo.bannerTemplate,
          "bannerType": action.bannerInfo.bannerType,
          "expiry": action.bannerInfo.expiry,
          "isBannerActive": action.bannerInfo.isBannerActive
        }
    }
    default:
      return state
  }
}

export default botData;