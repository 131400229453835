import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import './skeleton-loader-order-tracking.scss'

const SkeletonLoaderOrderTracking = () => {
    return (
        <div className='expandedTiles-skeleton'>
            <div className='expandedTiles-skeleton-status'>
                <Skeleton variant="rect" width={116} height={18} />
                <Skeleton variant="rect" width={320} height={18} />
                <Skeleton variant="rect" width={320} height={18} />
            </div>
            <div className='expandedTiles-skeleton-delivery'>
                <Skeleton variant="rect" width={320} height={40} />
            </div>
            <div className='expandedTiles-skeleton-delivery-timeline'>
                <Skeleton variant="rect" width={135} height={24} />
            </div>

            <div className='expandedTiles-skeleton-delivery-details'>
                <div className='expandedTiles-skeleton-delivery-of'><Skeleton variant="rect" width={90} height={20} style={{ marginTop: 2 }} /></div>
                <div className='expandedTiles-skeleton-delivery-status'><Skeleton variant="rect" width={75} height={26} style={{ borderRadius: 20, marginLeft: 7 }} /></div>
            </div>
            <div className='expandedTiles-skeleton-delivered-by'>
                <Skeleton variant="rect" width={231} height={20} />
            </div>
            <div className='expandedTiles-skeleton-delivered-consignment-no'>
                <Skeleton variant="rect" width={159} height={18} />
            </div>
            <div className='expandedTiles-skeleton--order-status'>
                {
                    [1, 2, 3, 4].map((i) => {
                        return (
                            <>
                                <Skeleton variant="circle" width={24} height={22} />
                                {i < 4 ?
                                    <Skeleton variant='text' width={70} height={2} /> : ''
                                }

                            </>
                        )
                    })
                }
            </div>

            <div className='expandedTiles-skeleton--order-status-info'>
                {
                    [1, 2, 3, 4].map((i) => {
                        return (
                            <>
                                <Skeleton variant="rect" width={56} height={17} />
                            </>
                        )
                    })
                }

            </div>

            <div className='expandedTiles-skeleton--order-status-info1'>
                {
                    [1, 2, 3, 4].map((i) => {
                        if (i === 1 || i === 2) {
                            return (
                                <>
                                    <Skeleton variant="rect" width={56} height={17} />
                                </>
                            )
                        }
                    })
                }

            </div>

            <div className='expandedTiles-skeleton--order-details-'>
                {
                    <div className='expandedTiles-skeleton--product-details'>
                        <div className='expandedTiles-skeleton--product-details-img'>
                            <Skeleton variant="rect" width={40} height={40} />
                        </div>
                        <div className="expandedTiles-skeleton--product-details-description">
                            <Skeleton variant="rect" width={170} height={21} />
                            <Skeleton variant="rect" width={58} height={18} />
                        </div>
                    </div>
                }
            </div>
        </div>

    )
}

export default SkeletonLoaderOrderTracking