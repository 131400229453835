/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './messageQrArticle.scss';

type ArticleListPayloadProps = {
    messages: any,
    clickHandler: any
}

const ArticleListPayloadProps = ({messages, clickHandler}: ArticleListPayloadProps) => {
    return (
        <>
        {messages.map((m: any, index: number) => (     
            <div className="messageQrArticle" key={index} tabIndex={0} onClick={() => clickHandler(m)}>
                    <div className="messageQrArticle__linktitle" >
                        <span className="messageQrArticle__linktitle-text">{m.text}</span>
                    </div>
                    <div className="messageQrArticle__linkdesc">
                        <span className="messageQrArticle__linkdesc-summary">{m.summary}</span>
                        <img src="/images/go-arrow.svg" alt=""></img>
                    </div>
            </div>
        ))}
        </>
    )
}

export default ArticleListPayloadProps
