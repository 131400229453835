import React from 'react';
import './messageOrderTracking.scss';

const MessageUnit = ({info}: any) => {
    return (
        <span className="orderTrackingMessage__value">{info}</span>              
    );
};

export default MessageUnit;
