import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { updateWineType, updatePriceRange, updateWineVarietal, updateCurrentView, fetchProducts, updateSelectedStore, resetSelection, showFawPage, showWineExpert} from '../actions/index';
import { RootState } from '../reducers/index';
import WineType from './wine-type/WineType';
import Varietal from './varietal/Varietal';
import Price from './price/Price';
import Results from './results/Results';
import RecommendationList from './recommendation-list/recommendationList';
import CancelFaw from './cancel-faw/cancelFaw';
import WineMerchantRedirect from './wine-merchant-redirect/WineMerchantRedirect';

import { Prices, PriceRangeMapping } from './price/PriceModel';
import { Types, WineTypeMapping }  from './wine-type/wineTypeModel';
import { RedVarietals, SparklingVarietals, WhiteVarietals, VarietalTypeMapping, Varietals } from './varietal/VarietalsModel';
import { FAWChildProps, FAWProps, ResultProps, recommendedWine } from './models/fawTypes';
import { getRecommendedWines } from '../selectors/recommendedWines';
import { postMessageToClient } from '../widget/chatservice/communicationService';
import { dialogflowEvent } from '../config/config';
import { DialogflowQueryType } from '../widget/models/enum';

import { toTitleCase } from '../widget/util/commonUtil';
import './findAWine.scss';
import { addAnalyticsEvent } from '../widget/chatservice/dialogflowQuery';
import { v4 } from 'uuid';
import { getUniqueId } from '../widget/util/commonUtil';

import { Button, makeStyles } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';


const theme = createTheme({
    typography: {
     "fontFamily": `"DIN 2014","Segoe UI",'Helvetica','Helvetica','sans-serif','Arial'`,
     "fontSize": 14
    }});

const useStyles = makeStyles({
    secondary: {
        backgroundColor: '#FFFFFF',
        color: '#305233',
        textTransform: 'none',
        fontStyle: 'normal',
        padding: '7.5px 24px 7.5px 24px',
        border:'1px solid #305233',
        width:'55%',
        marginRight:'10px',
        transition: 'all .2s ease',
        '&:hover': {
            backgroundColor: '#4C8251',
            color: '#FFFFFF',
            border:'1px solid #4C8251'
        },
        '&:focus': {
            backgroundColor: '#4C8251',
            color: '#FFFFFF',
            border:'2px solid #5795DB'
        },
        '&:active': {
            backgroundColor: '#305233',
            color: '#FFFFFF',
            border:'1px solid #305233'
        },
        '&:disabled': {
            backgroundColor: '#D1D2D1',
            color: '#fff',
            border:'#D1D2D1'
        },
    },
    primary: {
        backgroundColor: '#4C8251',
        color: '#FFFFFF',
        textTransform: 'none',
        fontStyle: 'normal',
        padding: '7.5px 24px 7.5px 24px',
        border:'none',
        width:'55%',
        marginRight:'10px',
        transition: 'all .2s ease',
        '&:hover': {
            backgroundColor: '#1C4220',
            color: '#FFFFFF',
        },
        '&:focus': {
            backgroundColor: '#305233',
            color: '#FFFFFF',
            border:'2px solid #5795DB'
        },
        '&:active': {
            backgroundColor: '#305233',
            color: '#FFFFFF',
            border:'1px solid #305233'
        },
        '&:disabled': {
            backgroundColor: '#D1D2D1',
            color: '#fff',
        },
    }
    })

export const FindAWine = ({storeName, streetNumber, resumeGlobalTimeout, quickReplyHandler, sessionId, userId, intentGroupId, intentGroupName, intentGroupTypeId }: FAWProps) => {
    const dispatch = useDispatch();
    const recommendedWinesResult: ResultProps = useSelector(getRecommendedWines);
    const fawWrapper = useRef(document.createElement("div"));
    const [isFetchingRequired, setIsFetchingRequired] = useState<boolean>(true);
    const [showCancelFaw, setShowCancelFaw] = useState<boolean>(false);
    const [endScreenMountingStyle, setEndScreenMountingStyle] = useState<string>('');
    const [isOverlayScreenMounted, setisOverlayScreenMounted] = useState<boolean>(false)

    const intentData = {
        sessionId : sessionId,
        userId: userId,
        intentGroupId: intentGroupId,
        intentGroupName: intentGroupName,
        intentGroupTypeId: intentGroupTypeId
    };
    
    //Fetching the values from the state
    const wineType = useSelector((state: RootState) => { return state.fawData.selectedWineType;}),
          priceRange = useSelector((state: RootState) => { return state.fawData.selectedPriceRange;}),
          wineExpert = useSelector((state: RootState) => {return state.fawData.selectWineExpert}),
          varietal = useSelector((state: RootState) => { return state.fawData.selectedVarietal;}),
          currentView = useSelector((state: RootState) => { return state.fawData.currentView;}),
          selectedType = useSelector((state: RootState) => { return state.fawData.selectedType}),
          showFAW = useSelector((state: RootState) => { return state.fawData.showFAW});
    
    //Child Props
    const fawChildProps: FAWChildProps = {
        updateType: (type: string) => dispatch(updateWineType(type)),
        updatePrice: (priceRange: string) => dispatch(updatePriceRange(priceRange)),
        updateWineExpert :(wineExpert:string) => dispatch(showWineExpert(wineExpert)),
        updateVarietal: (varietal: string) => dispatch(updateWineVarietal(varietal)),
        quickReplyHandler: quickReplyHandler,
        type: wineType,
        range: priceRange,
        selectWineExpert : wineExpert,
        selectedVarietal: varietal,
    }

    const sendAnalyticsTag = (linkName: string) => {
        const objCall = {
            data : {
                linkName : linkName.toLowerCase(),
                linkType: 'chatbot find a wine',
            },
            methodHandler : "chatbotTriggerAdobeAnalytics"
        }; 
        postMessageToClient(objCall);
    }

    const updateAnalytics = () => { 
        let linkName = 'web:chatbot:find a wine:preference:'
        let eventName = 'PREFERENCE_SELECTION_START';

        switch (currentView) {
            case 'price':
                linkName += `step1:${wineType}`;
                eventName = `WINETYPE::${wineType}`;
             break;
            case 'varietal':
                linkName += `step2:${wineType}:${priceRange}`;
                eventName = `PRICERANGE::${priceRange}`;
            break;
            case 'results':
                linkName += `step3:${wineType}:${priceRange}:${varietal}`;
                eventName = `VARIETAL::${varietal}`;
            break;
            case 'recommendationList':
                linkName = `web:chatbot:find a wine:recommendations:see more ${selectedType}`;
                eventName = `RECOMMENDATIONS::${selectedType}`;
            break;
            default:
        }    
        
        if (showFAW && currentView !== 'type' && currentView !== 'cancelFaw') {
            sendAnalyticsTag(linkName);
        }

        const analyticsData = {
            queryId : v4(),
            isSuccessful : null,
            eventName: eventName,
            intentGroupId : intentGroupId,
            intentGroupName : intentGroupName,
            intentGroupTypeId : intentGroupTypeId,
            source : "murphy"
        };

        // FAW Set Store in Analytics
        addAnalyticsEvent(sessionId, userId, analyticsData);
    }
    

    useEffect(() => {
        if(currentView === 'results' && isFetchingRequired) {
            const getProducts = async() => {
                dispatch(fetchProducts({
                    storeNumber: streetNumber,
                    wineTypes: wineType && WineTypeMapping[wineType], 
                    priceRange: priceRange && PriceRangeMapping[priceRange], 
                    wineVarietal: varietal && VarietalTypeMapping[varietal]
                }))
              }
            getProducts()
        }

        if(currentView === 'results' || currentView === 'recommendationList') {
            //Scroll to top
            if(fawWrapper.current) {
                fawWrapper.current.scrollTo(0, 0);
            }
        }

        updateAnalytics();

    },[currentView])

    //Update store details
    useEffect(()=> {
        dispatch(updateSelectedStore(streetNumber, storeName, intentData));
    }, [storeName, streetNumber])

    const getVarietals = () => {
        if (wineType === Types.Red) {
            return RedVarietals;
        }

        if (wineType === Types.WhiteRose) {
            return WhiteVarietals;
        }

        if (wineType === Types.ChampagneSparkling) {
            return SparklingVarietals;
        }

        return [];
    }

    const getRecommendedList = () => {
        let  recommendedList: recommendedWine[];
        
        switch (selectedType) {
            case 'Best Sellers':
                recommendedList = recommendedWinesResult.bestSellers;
             break;
            case 'Top Rated':
                recommendedList = recommendedWinesResult.topRatedSellers;    
            break;
            case 'New & Noteworthy':
                recommendedList = recommendedWinesResult.newAndNoteworthySellers;
            break;
            default:
                recommendedList = [];
        }

        return {recommendationList: recommendedList};
    }
    
    //To be done
    const handleCloseOrStartAgain = () => {
        if(currentView === "results" || currentView === "recommendationList") {
          //Handle start again
          dispatch(resetSelection());
          dispatch(updateCurrentView('type'));
          sendAnalyticsTag(`web:chatbot:find a wine:recommendations:${currentView === "recommendationList" ? `${selectedType}:` : ''}start again`);
        } else {
            //Handle Cancel
            setisOverlayScreenMounted(true);
            setShowCancelFaw(true);
            dispatch(updateCurrentView(currentView));
        }
    }

    const handleBackButton = () => {
        setIsFetchingRequired(true);
        switch (currentView) {
            case 'type':
                //Handle going back to the screen
             break;
            case 'price':
                dispatch(updateCurrentView('type'));
            break;
            case 'varietal':
                dispatch(updateCurrentView('price'));
            break;
            case 'results':
                wineType !== Types.NotSure ? dispatch(updateCurrentView('varietal')) : dispatch(updateCurrentView('price'))
            break
            case 'recommendationList':
                setIsFetchingRequired(false);
                dispatch(updateCurrentView('results'));
            break;
            default:
        }
    }

    const handleDone = (e: any) => {
        dispatch(showFawPage(false));
        dispatch(resetSelection());
        quickReplyHandler(e, DialogflowQueryType.Event, dialogflowEvent.Chatbot_FAW_Followup_Questions, false);
    }

    const updateView = (e: any) => {
        resumeGlobalTimeout();
        let eventName = '';
        switch (currentView) {
            case 'type':
                dispatch(updateCurrentView('price'));
             break;
            case 'price':
                wineType !== Types.NotSure ? dispatch(updateCurrentView('varietal')) : dispatch(updateCurrentView('results'))
               
            break;
            case 'varietal':
                if (priceRange === Prices.any || priceRange === Prices.under10 || priceRange === Prices.under20)
                {
                    dispatch(updateCurrentView('results'));
                }
                else
                {
                    dispatch(updateCurrentView('WineMerchantRedirect'))                        
                }
            break;
            case 'results':
                sendAnalyticsTag(`web:chatbot:find a wine:recommendations:i'm done`);
                handleDone(e);
                eventName = `I'm done`;
            break;
            case 'recommendationList':
                sendAnalyticsTag(`web:chatbot:find a wine:recommendations:${selectedType}:i'm done`);
                handleDone(e);
                eventName = `I'm done`;
            break;
            default:
        }

        if (eventName !== '') {
            const analyticsData = {
                queryId : v4(),
                isSuccessful : null,
                eventName: eventName,
                intentGroupId : intentGroupId,
                intentGroupName : intentGroupName,
                intentGroupTypeId : intentGroupTypeId,
                channel : "murphy"
            };

            // FAW Set Store in Analytics
            addAnalyticsEvent(sessionId, userId, analyticsData);
        }
    }

    const isDisabled = () => {
        switch (currentView) {
            case 'type':
                return wineType === '';
            case 'price':
                return priceRange === '';
            case 'varietal':
                return varietal === '';
            default:
                return false;
        }
    }

    const getRecommendationText = () => {
        if(currentView === "results" && !showRecommendationText()) {
            return '';
        }
        if(currentView === "results" || currentView === "recommendationList") {
            if(varietal && varietal !== Varietals.NotSure && priceRange !== Prices.any) {
                return `<span>I can recommend these <b>${varietal}</b> from <b>${priceRange}</b> at <b>Dan Murphy’s ${toTitleCase(storeName)}</b>.</span>`;
            } else if (varietal && varietal !== Varietals.NotSure && priceRange === Prices.any) {
                return `<span>I can recommend these <b>${varietal}</b> at <b>Dan Murphy’s ${toTitleCase(storeName)}</b>.</span>`;
            } else if (wineType && wineType === Types.NotSure && priceRange !== Prices.any) {
                return `<span>I can recommend these wines from <b>${priceRange}</b> at <b>Dan Murphy’s ${toTitleCase(storeName)}</b>.</span>`;
            } else {
                return `Recommendations from <b>Dan Murphy’s ${toTitleCase(storeName)}</b>.`;
            }
        } else if (currentView === 'type') {
            return `<span>Tell me what type of wine you’re curious about and I’ll give you a recommendation you can grab at <b>Dan Murphy’s ${toTitleCase(storeName)}</b>.</span>`
        } else if (currentView === 'price') {
            if (wineType === Types.Red) {
                return `<span>How much are you looking to spend per bottle on your red wine?</span>`;
            } else if (wineType === Types.WhiteRose) {
                return `<span>How much are you looking to spend per bottle on your white wine or rosé?</span>`
            } else if (wineType === Types.ChampagneSparkling) {
                return `<span>How much are you looking to spend per bottle on your Champagne or sparkling wine?</span>`
            } else if (wineType === Types.NotSure) {
                return `<span>How much are you looking to spend per bottle on your wine?</span>`
            }
        } else if (currentView === 'varietal') {
            return `<span>Which wine varietal are you interested in? I’ll show recommendations that are available at <b>Dan Murphy’s ${toTitleCase(storeName)}</b>.</span>`
        }
    }

    const getScreenHeading = () => {
        if (currentView === "results") {
            return "Wine Recommendation" 
        } else if(currentView === "recommendationList") {
            return `${selectedType} (${getRecommendedList().recommendationList.length})`
        }
            else if(currentView === "WineMerchantRedirect"){
            return ""
        }
         else {
            return "Choose your wine preference";
        }
    }

    const showRecommendationText = ( ) => {
        const {recommendedWines, bestSellers,  topRatedSellers, newAndNoteworthySellers} = recommendedWinesResult;
        if(recommendedWines.length) {
            if(!bestSellers.length && !topRatedSellers.length && !newAndNoteworthySellers.length) {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
    }

    const classes = useStyles();

    useEffect(() => {
        if (isOverlayScreenMounted) {
            setEndScreenMountingStyle(' mounting')
        } else {
            setEndScreenMountingStyle(' unmounting')
        }

        if (!isOverlayScreenMounted) {
            setTimeout(() => {
                setShowCancelFaw(false)
            }, 400);
        }
    }, [isOverlayScreenMounted])

    return (
        <ThemeProvider theme={theme}>
            <div className="faw-container">
                <>
                    {/* back button */}
                    {currentView === 'type' || currentView === "WineMerchantRedirect"  ? null : <div onClick={ handleBackButton } className="back-button" id={getUniqueId('faw-back-button', currentView)}>{`< Back`}</div>}

                    {/* scrollable container */}
                    <div className="faw-wrapper"  ref={fawWrapper} >
                        {/* Header */}
                   {currentView !== "WineMerchantRedirect" ?   <div className="faw-header">
                                <div className="faw-title">
                                    <img className="wine-logo" src="/images/wine-glass.png"/>
                                    <h3>{getScreenHeading()}</h3>
                                </div>
                                <div className="underline"></div>
                            </div> 
                   : null}
                            
                        {/* description text */}
                        {currentView !== "WineMerchantRedirect" ?     <div className="recommendation-text" dangerouslySetInnerHTML={{ __html: `${getRecommendationText()}` }}></div>
                        : null }
                        {/* Main Content */}
                        <div className="faw-type-container">
                            {currentView === "type" && <WineType {...fawChildProps}/>}
                            {currentView === "price" && <Price {...fawChildProps}/>}
                            {currentView === "WineMerchantRedirect" && <WineMerchantRedirect {...fawChildProps} /> }
                            {currentView === "varietal" && <Varietal {...fawChildProps} varietals = { getVarietals()}/>}
                            {currentView === "results" && <Results {...recommendedWinesResult} intentData = { intentData } />}
                            {currentView === "recommendationList" && <RecommendationList { ...getRecommendedList()} intentData = { intentData } />}
                        </div>
                    </div>
                    {/* fixed footer */}
                    {currentView !== "WineMerchantRedirect" ?    <div className="faw-footer">
                        <Button id={(currentView === "results" || currentView === "recommendationList" )? "start-again-button": "cancel-button"} disableRipple={true} className={classes.secondary} onClick={ () => handleCloseOrStartAgain() }>{(currentView === "results" || currentView === "recommendationList" )? "Start again": "Cancel"}</Button>
                        <Button id={(currentView === "results" || currentView === "recommendationList") ? "i-m-done-button": "continue-button"} disableRipple={true} className={(isDisabled())?classes.secondary:classes.primary} disabled={isDisabled()} onClick={ (event) => updateView(event) }>{(currentView === "results" || currentView === "recommendationList") ? "I’m done": "Continue"}</Button>
                </div> : null }
                </> 

            </div>
            <div className={ showCancelFaw ? "overlay-slideup" : ''}></div>
                               {showCancelFaw && <CancelFaw quickReplyHandler={quickReplyHandler} endScreenMountingStyle={endScreenMountingStyle} setisOverlayScreenMounted={setisOverlayScreenMounted}/>}
            </ThemeProvider>
      )
}
  
export default FindAWine




