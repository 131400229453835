import React from 'react'
import { config } from '../../config/config';
import './SkeletonLoaderFAQ.scss'
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonLoaderFAQ = () => {
    const widths = [302.78,274.01,311,302.78,283.6,302.78,302.78,274.01,311];
    return (
        <div className='articles-skeleton'>
            <div className="articles-skeleton--header">
                <Skeleton variant='rect' animation="wave" width={232} height={30} />
                <Skeleton variant='rect' animation="wave" width={200} height={18} />
                <Skeleton variant='rect' animation="wave" width={124} height={30} />
            </div>
            <div className="articles-skeleton--contents">
                {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {

                        const width = widths[i - 1];
                        return (
                            <div className='articles-skeleton--contents-para'>
                                <Skeleton variant='rect' animation="wave" width={width} height={18} />
                            </div>
                        )

                    })

                }
            </div>

            <div className="articles-skeleton--user-response">
                <Skeleton variant='rect' animation="wave" width={168} height={18} />
            </div>
            <div className="articles-skeleton--user-response-buttons">
                <Skeleton variant='rect' animation="wave" width={132} height={36} />
                <Skeleton variant='rect' animation="wave" width={132} height={36} />
            </div>

        </div>
    )
}

export default SkeletonLoaderFAQ