import React from 'react';
import Widget from './widget/Widget';
import PrivacyForm from './privacy-form/PrivacyForm';
import './App.scss';
import {
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

declare global {
  interface Window { 
      WebviewSdk: any,
      MessengerExtensions: any
  }
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const App = () => {
  let query = useQuery();

  return (
      <Switch>
        <Route path="/privacy-form">
          <PrivacyForm appId={query.get("appId")} conversationId={query.get("conversationId")} userId={query.get("userId")} projectId={query.get("projectId")} />
        </Route>
        <Route path="/">
          <div className="app">
            <Widget />
          </div>
        </Route>
        <Route>
          <p>404: Not Found</p>
        </Route>
      </Switch>
  )
}

export default App


