import * as ACTIONS from '../actions/actionTypes';
const defaultState = {
  selectedWineType: '',
  selectedPriceRange: '',
  selectedVarietal: '',
  currentView: 'type',
  selectedType: '',
  selectedStoreId: '',
  selectedStoreName: '',
  pdpOpened: false,
  lastSelectedScreen: '' ,
  showFAW: false,
  intentData: null,
  selectWineExpert: ''
}
const fawData = (state = defaultState, action:any) => {
  switch (action.type) {
    case ACTIONS.UPDATE_WINE_TYPE:
      return {
        ...state,
        selectedWineType: action.selectedWineType,
        selectedVarietal: ''
      }
    case ACTIONS.UPDATE_PRICE_RANGE: 
      return {
        ...state,
        selectedPriceRange: action.selectedPriceRange,
      }
    case ACTIONS.SHOW_WINE_EXPERT:
      return {
          ...state,
          selectWineExpert: action.selectWineExpert,
       }
    case ACTIONS.UPDATE_VARIETAL:
    return {
        ...state,
        selectedVarietal: action.selectedVarietal
      }
    case ACTIONS.UPDATE_CURRENT_VIEW:
      return {
          ...state,
          lastSelectedScreen: state.currentView,
          currentView: action.currentView
      }
    case ACTIONS.UPDATE_SELECTED_TYPE:
      return {
          ...state,
          selectedType: action.selectedType
      }
    case ACTIONS.UPDATE_STORE_DETAILS:
      return {
          ...state,
          selectedStoreId: action.selectedStoreId,
          selectedStoreName: action.selectedStoreName,
          intentData: action.intentData
      }
    case ACTIONS.PDP_OPENED:
      return {
          ...state,
          pdpOpened: action.pdpOpened
      }
    case ACTIONS.SHOW_FAW:
      return {
          ...state,
          showFAW: action.showFAW
      }
    case ACTIONS.RESET_SELECTION:
      return {
          ...state,
          selectedWineType: '',
          selectedPriceRange: '',
          selectedVarietal: '',
          currentView: 'type'
      };
      
    default:
      return state
  }
}

export default fawData