import { WineType } from '../models/fawTypes';

enum Types {
    Red = "Red",
    WhiteRose = "White & rosé",
    ChampagneSparkling = "Champagne/sparkling",
    NotSure = "I'm not sure"
}

const WineTypeMapping: WineType  = {
    "Red": ["Red"],
    "White & rosé": ["White", "Rose"],
    "Champagne/sparkling": ["Champagne", "Sparkling"],
    "I'm not sure": [],
}



export { Types, WineTypeMapping }