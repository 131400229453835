import React, { useEffect, useState } from 'react'
import './message.scss';
import Brand from '../brand/Brand';
import { Speaker } from '../models/enum';
import { Button, makeStyles } from '@material-ui/core'
import { handleAnalyticsBot } from '../../widget/util/commonUtil'

type messageProps = {
    message: any,
    isHostDevice: boolean,
    messageListArray: any,
    setShowverigyAge: any,
    setisOverlayScreenMounted: any
};

const useStyles = makeStyles({
    buttonReadChanging: {
        backgroundColor: '#4C8251',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        padding: '7.5px 7.5px 7.5px 7.5px',
        border: '1px solid #4C8251',
        width: '76%',
        height: '36px',
        marginTop: '8px',
        transition: 'all .2s ease',
        '&:hover': {
            backgroundColor: '#305233',
            color: '#FFFFFF',
            border: 'none'
        },
        '&:focus': {
            backgroundColor: '#305233',
            color: '#FFFFFF',
            border: '2px solid #5795DB'
        },
        '&:active': {
            backgroundColor: '#143017',
            color: '#FFFFFF',
            border: 'none'
        },
    }
})

const Message = ({ message, isHostDevice, messageListArray, setShowverigyAge, setisOverlayScreenMounted }: messageProps) => {
    const classes = useStyles();
    const [addMessageClass, setAddMessageClass] = useState('');
    const [brand, setBrand] = useState(true);
    const [addMargin, setAddMargin] = useState(' ');
    const [showVerifyAgeMessage, setShowVerifyAgeMessage] = useState<boolean>(false);

    const whatChangingButtonClick = () => {
        setShowverigyAge(true)
        setisOverlayScreenMounted(true)
        handleAnalyticsBot('web:chatbot:nsw id verification:read whats changing', 'chatbot nsw id verification', 'chatbotTriggerAdobeAnalytics');
    };

    useEffect(() => {
        messageListArray.push(message);
        const len: any = messageListArray.length;

        if(message.hasOwnProperty('verifyAge')){
            setShowVerifyAgeMessage(true);
        }
        
        if (messageListArray.length > 1) {
            const prev = messageListArray[len - 2];
            if (prev.from === undefined) {
                setBrand(false)
            }


            if (message.from === 'user' || message.from === 'murphy') {
                if (prev.from === undefined || prev.from === 'murphy') {
                    setAddMargin('large-margin ')
                }
                if (prev.from === 'user' || message.from === 'murphy') {
                    setAddMargin('small-margin')
                }
            }

            if (message.from === undefined) {
                if (prev.from === undefined && !prev.hasQr) {
                    setAddMargin('small-margin ')
                }
                else {
                    setAddMargin('large-margin ')
                }
            }
        }


        if (message.from !== 'user' || message.from === undefined || message === null) 
        {
            if (message.from !== Speaker.System) {
                setAddMessageClass('message__text--bot ')
            }

        }

    }, [])

    return (
        <div className={"message " + addMessageClass + addMargin}>
            {brand && message.from !== Speaker.User && message.from !== Speaker.System && (
                <div className="message__brand-container">
                    <Brand size='small' />
                    <span className="message__brand-container-label">Murphy (bot)</span>
                </div>)
            }
            {showVerifyAgeMessage &&
                <div className="message__alert-container">
                    <div className="message__alert-image">
                        <img src="/images/Id-verification-alert.svg" />
                    </div>
                    <div className="message__alert-text">
                        <span>Online age check required for same-day deliveries in NSW from June 1</span>
                        <br />
                        <Button id="whats-changing" disableRipple={true}  onClick={whatChangingButtonClick} style={{fontSize: 12,fontWeight: 600}} className={classes.buttonReadChanging}>Read what's changing</Button>
                    </div>
                </div>
            }

            <div className={message.hasQr && !isHostDevice ? "message__container message__container--has-qr" : "message__container"}>
                {
                    message.from === Speaker.System && !showVerifyAgeMessage &&
                    <div className="message__system">
                        {message.icon && message.icon !== '' && <img src={message.icon} className="message__system-icon" alt="system-icon" />}
                        <span className="message__system-text">{message.text}&nbsp;{message.shopper && message.shopper !== '' && <b>{message.shopper}</b>}</span>
                    </div>
                }

                {
                    message.from === Speaker.User &&
                    <div className="message__text message__text--user">
                        {message.text}
                    </div>
                }

                {
                    (message.from === Speaker.Murphy || message.from === Speaker.Agent || message.from === undefined || message.from === null) &&
                    <div className="message__text">
                        {message.text}
                    </div>
                }
            </div>
        </div>
    )
}

export default Message
