/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './message.scss';
import { DialogflowQueryType } from '../../widget/models/enum';
import { getUniqueId, removeIntents } from '../../widget/util/commonUtil';

type propsMessageQR = {
    text: string,
    eventText ?: string,
    quickReplyHandler: any,
    indexParent: number,
    msgs: any;
}



const MessageQR = ({text, eventText, quickReplyHandler, indexParent, msgs}: propsMessageQR) => {
    
    const onMessageClick = (e: any) => {
        //To remove intents after user selection
        removeIntents(msgs, indexParent)

        quickReplyHandler(e, DialogflowQueryType.Text, eventText, true);
    }

    return (
        <div className='message__text--quick-reply' id={getUniqueId('qr', text)} tabIndex={0}>
            <a onClick={onMessageClick} id={getUniqueId('message', text)}>{text}</a>
        </div>
    )
}

export default MessageQR
