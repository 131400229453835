import { config } from '../../config/config';

const URL_GREET_SHOPPER: string = `${config.CHATSERVER_URL}/api/Shopper/GreetShopper`;
const URL_GREET_SHOPPER_ENCRYPTED: string = `${config.CHATSERVER_URL}/api/Shopper/GreetShopperEncrypted`;
const URL_SHOPPER_GET_ORDER_DETAIL: string = `${config.CHATSERVER_URL}/api/Shopper/GetOrderDetail`;
const URL_SHOPPER_GET_SUBSCRIPTION_DETAIL: string = `${config.CHATSERVER_URL}/api/Shopper/GetSubscriptionDetail`;
const URL_SETTINGS_GET_FEATURE: string = `${config.CHATSERVER_URL}/api/Settings/IsFeatureEnabled/`;
const URL_CONFIGURATIONS: string = `${config.CHATSERVER_URL}/api/Settings/GetWidgetConfiguration/murphy`;
const URL_NOMINEE_DETAILS: string = `${config.CHATSERVER_URL}/api/shopper/GetNomineeDetail`;
const URL_SET_NOMINEE_DETAILS: string = `${config.CHATSERVER_URL}/api/shopper/SetNomineeDetail`;

const requestPost = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': config.APIMGT_KEY
    },
    body: JSON.stringify({})
};

const requestGet = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json','Ocp-Apim-Subscription-Key': config.APIMGT_KEY }
};

const greetLoggedInShopper = async (shopperId: number, widgetSessionId: string, sessionId: string, userId: string, intentGroupId: string, intentGroupName: string) => {
    try {
        requestPost.body = JSON.stringify({ 
            "shopperId": shopperId, 
            "widgetSessionId": widgetSessionId, 
            "dialogFlowSessionId" : sessionId , 
            "chatbotUserId" : userId,
            "intentGroupId" : intentGroupId,
            "intentGroupName" : intentGroupName
        });

        const response = await fetch(URL_GREET_SHOPPER, requestPost);
        const result = await response.json();
        return result;
    } catch(error) {
        return error;
    }
    
}

const greetLoggedInShopperEncrypted = async (encryptedShopperId: string, widgetSessionId: string, sessionId: string, userId: string, intentGroupId: string, intentGroupName: string) => {
    try {
        requestPost.body = JSON.stringify({ 
            "encryptedShopperId": encryptedShopperId, 
            "widgetSessionId": widgetSessionId, 
            "dialogFlowSessionId" : sessionId , 
            "chatbotUserId" : userId,
            "intentGroupId" : intentGroupId,
            "intentGroupName" : intentGroupName
        });

        const response = await fetch(URL_GREET_SHOPPER_ENCRYPTED, requestPost);
        const result = await response.json();
        return result;
    } catch(error) {
        return error;
    }
    
}

const getOrderDetailByShopperId = async (shopperId: number, widgetSessionId: string, orderNumber: number, sessionId: string, userId: string) => {
    try {
        requestPost.body = JSON.stringify({ "shopperId": shopperId, "widgetSessionId": widgetSessionId, "dialogFlowSessionId" : sessionId , "chatbotUserId" : userId });
        const response = await fetch(`${URL_SHOPPER_GET_ORDER_DETAIL}/${ orderNumber }`, requestPost);
        const result = await response.json();
        return result;
    } catch(error) {
        return error;
    }   
}

const getNomineeDetails = async (shopperId: number, widgetSessionId: string, orderNumber: number, sessionId: string, userId: string) => {
    try {
        requestPost.body = JSON.stringify({ "shopperId": shopperId, "widgetSessionId": widgetSessionId, "dialogFlowSessionId" : sessionId , "chatbotUserId" : userId });
        const response = await fetch(`${URL_NOMINEE_DETAILS}/${ orderNumber }`, requestPost);
        const result = await response.json();
        return result;
    } catch(error) {
        return error;
    }   
}

const setNomineeDetails = async (shopperId: number, widgetSessionId: string, orderNumber: number, sessionId: string, userId: string, nomineeName: string, nomineePhone: string) => {
    try {
        requestPost.body = JSON.stringify({ "shopperId": shopperId, "widgetSessionId": widgetSessionId, "dialogFlowSessionId" : sessionId , "chatbotUserId" : userId, "nomineeName" : nomineeName, "nomineePhone" : nomineePhone });
        const response = await fetch(`${URL_SET_NOMINEE_DETAILS}/${ orderNumber }`, requestPost);
        const result = await response.json();
        return result;
    } catch(error) {
        return error;
    }   
}

const getSubscriptionDetailByShopperId = async (shopperId: number, widgetSessionId: string, subscriptionId: string) => {
    try {
        requestPost.body = JSON.stringify({ "shopperId": shopperId, "widgetSessionId": widgetSessionId });
        const response = await fetch(`${URL_SHOPPER_GET_SUBSCRIPTION_DETAIL}/${ subscriptionId }`, requestPost);
        const result = await response.json();
        return result;
    } catch(error) {
        return error;
    }
    
}

const getFeatureSetting = async (featureName: string) => {
    try {
        const response = await fetch(`${ URL_SETTINGS_GET_FEATURE}${ featureName }`, requestGet);
        const result = await response.json();
        return result;
    } catch(error) {
        return error;
    }
}

const getConfigSettings = async () => {
    try {
        const response = await fetch(`${ URL_CONFIGURATIONS }`, requestGet);
        const result = await response.json();
        return result;
    } catch(error) {
        return error;
    }
}

export {
    greetLoggedInShopper,
    greetLoggedInShopperEncrypted,
    getOrderDetailByShopperId,
    getSubscriptionDetailByShopperId,
    getFeatureSetting,
    getConfigSettings,
    getNomineeDetails,
    setNomineeDetails
}
