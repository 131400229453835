import React from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { RootState } from '../../reducers/index';

import { RecommendationListProps } from '../models/fawTypes';
import './recommendationList.scss';

import { openPdpDansPage } from '../../actions/index';
import { getUniqueId } from '../../widget/util/commonUtil';

const RecommendationList = ({ recommendationList, intentData }: RecommendationListProps ) => {
  
  const dispatch = useDispatch();
  const storeName = useSelector((state: RootState) => { return state.fawData.selectedStoreName });
  const storeID = useSelector((state: RootState) => { return state.fawData.selectedStoreId });
  const selectedType = useSelector((state: RootState) => { return state.fawData.selectedType });

  const openPDPpage = (wineLink: string, wineName:string) => {
    dispatch(openPdpDansPage(wineLink, storeName, storeID, wineName, selectedType, intentData));
  }
  return (
      <>
      {recommendationList.map((item, index) => 
          <div key={index} className="wine-list" id={getUniqueId('wine-list', (index+1)?.toString())} onClick={() => openPDPpage(item.wineLink, item.wineName)}>

            <div className="wine-list-sno">
              #{index+1}
            </div>
            <div className="wine-list-image">
            <img className="image" 
                            src={item.imageURL} 
                            alt={item.wineName}/>
            </div>
            <div className="wine-list-info">
              <div className="brandName">{item.brandName}</div>
              <div className="wineName">{item.wineName}</div>
              <div className="price-section">
                <span className="price">${item.price}</span> 
                <span className="per-bottle">per bottle</span>
              </div>
              <div className="card-footer">
                <div className="rating-review">
                  <img className="star" src="/images/star.png"/>
                  <span className="rating">{(Math.round(item.rating * 10) / 10).toFixed(1)}</span>
                  <span className="review">({item.review} reviews)</span>
                </div>
                <div className="more-details" id="more-details-link">More details</div>
              </div>
            </div>
          </div>
          )
      }
      </>
    )
}
  
export default RecommendationList