import React from 'react';
import '../findAWine.scss';
import './price.scss';
import { Prices } from './PriceModel';

type PriceProps = {
    updatePrice: (priceRange: string) => void;
    range: string;
}

const Price = ({updatePrice, range }: PriceProps) => {
    return (
            <div className="faw-type-container">
                <p className="sub-header"><b>I would like to spend ...</b></p>
                <div className="price-flex-container">
                    <div className="column">
                        <input type="button" id="price-under10" value={Prices.under10} className={`form-button price-button ${range === Prices.under10 ? 'primary' : 'secondary'}`} onClick={ () => {updatePrice(Prices.under10)}}/>
                        <input type="button" id="price-under20" value={Prices.under20} className={`form-button price-button ${range === Prices.under20 ? 'primary' : 'secondary'}`} onClick={ () => {updatePrice(Prices.under20)}}/>
                        <input type="button" id="price-under30" value={Prices.under30} className={`form-button price-button ${range === Prices.under30 ? 'primary' : 'secondary'}`} onClick={ () => {updatePrice(Prices.under30)}}/>
                        <input type="button" id="price-under50" value={Prices.under50} className={`form-button price-button ${range === Prices.under50 ? 'primary' : 'secondary'}`} onClick={ () => {updatePrice(Prices.under50)}}/>
                    </div>
                    <div className="column">
                        <input type="button" id="price-under75" value={Prices.under75} className={`form-button price-button ${range === Prices.under75 ? 'primary' : 'secondary'}`} onClick={ () => {updatePrice(Prices.under75)}}/>
                        <input type="button" id="price-under100" value={Prices.under100} className={`form-button price-button ${range === Prices.under100 ? 'primary' : 'secondary'}`} onClick={ () => {updatePrice(Prices.under100)}}/>
                        <input type="button" id="price-over100" value={Prices.over100} className={`form-button price-button ${range === Prices.over100 ? 'primary' : 'secondary'}`} onClick={ () => {updatePrice(Prices.over100)}}/>
                        <input type="button" id="price-any" value={Prices.any} className={`form-button price-button ${range === Prices.any ? 'primary' : 'secondary'}`} onClick={ () => {updatePrice(Prices.any)}}/>
                    </div>
                </div>    
            </div>
      )
}
  
export default Price