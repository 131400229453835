import React, { useEffect, useState } from 'react';
import './storeSelect.scss';
import '../message/message.scss';
import { useDispatch } from 'react-redux';
import { resetSelection } from '../../actions/index';
import { postMessageToClient } from '../../widget/chatservice/communicationService';
import { addAnalyticsEvent } from '../chatservice/dialogflowQuery';
import { v4 } from 'uuid';

import { Button, makeStyles } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import { storeConfig } from '../../config/config';

type StoreSelectProps = {
  store: any,
  isHostDevice: boolean,
  chooseWinePreference: any,
  sessionId?: any,
  userId?: any,
}

const theme = createTheme({
  typography: {
    "fontFamily": `"din-2014","Segoe UI",'Helvetica','Helvetica','sans-serif','Arial'`,
    "fontSize": 14
  }
});

const useStyles = makeStyles({
  button: {
    backgroundColor: '#FFFFFF',
    color: '#305233',
    textTransform: 'none',
    fontStyle: 'normal',
    padding: '7.5px 24px 7.5px 24px',
    borderTop: '1px solid #eee',
    borderLeft: '1px solid #eee',
    borderRight: '1px solid #eee',
    borderBottom: '1px solid #eee',
    borderRadius: '0px',
    fontSize: 'small',
    transition: 'all .2s ease',
    width: '100%',
    '&:hover': {
      backgroundColor: '#F8F8F8',
      color: '#305233',
    },
    '&:focus': {
      backgroundColor: '#F8F8F8',
      color: '#305233',
      border: '2px solid #5795DB'
    },
    '&:active': {
      backgroundColor: '#F3F3F3',
      color: '#474947',
      border: 'none'
    }
  }
})

const StoreSelect = ({ store, isHostDevice, chooseWinePreference, sessionId, userId }: StoreSelectProps) => {
  const [timeStr1, timeStr2] = store?.data?.store_trading_hours?.map((x: { tradingStatus: any }) => ({ "simpleTradingstatus": x.tradingStatus })) ?? ['', ''];
  const [fawOpen, setFawOpen] = useState(false);
  const dispatch = useDispatch();

  const classes = useStyles();


  const onClickHandler = (e: any) => {
    postMessageToClient({
      data: {
        linkName: 'web:chatbot:find a wine:select store',
        linkType: 'chatbot find a wine',
      },
      methodHandler: "chatbotTriggerAdobeAnalytics"
    });
    setFawOpen(true);
    chooseWinePreference(store.data.suburb.toLowerCase(), store.data.streetNumber, store.intentPayload.intentGroupId, store.intentPayload.intentGroupName, store.intentPayload.intentGroupTypeId);

    const analyticsData = {
      queryId: v4(),
      isSuccessful: null,
      eventName: 'STORE_SELECT',
      intentGroupId: store.intentPayload.intentGroupId,
      intentGroupName: store.intentPayload.intentGroupName,
      intentGroupTypeId: store.intentPayload.intentGroupTypeId,
      source: "murphy"
    };

    // FAW Set Store in Analytics
    addAnalyticsEvent(sessionId, userId, analyticsData);
  }

  useEffect(() => {
    fawOpen && dispatch(resetSelection());
  }, [fawOpen]);

  const renderTradingHours = (store: { data: { store_trading_hours: any[]; }; }) => {
    return (
      <div className='store__week-status'>
        {store?.data?.store_trading_hours?.map((day, index) => {
          return renderTradingHour(day, index);
        })}
      </div>
    );
  }

  const renderTradingHour = (day: { day: string | any[]; openHour: string; }, index: React.Key | null | undefined) => {
    if (index !== 0 && index !== 7) {
      return (
        <div className="store__trading-Hours" key={index}>
          <div className="store__trading-Hours__day">
            <span>
              {day.day}
            </span>
          </div>
          <span className="store__trading-Hours__openHour">
            {day.openHour.toLowerCase() !== "closed" ? (
              <div>
                <span className="store__trading-Hours__openHour-time">
                  {day.openHour}
                </span>
              </div>
            ) : ("Closed")}
          </span>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div className='store'>
      <div className='store__location'>
        <div className='store__title'>
          {<span className='store__suburb'>
            {storeConfig.DAN_MURPHY_CELLAR_STORE_SUBURB.includes(store.data.suburb?.toLowerCase())
              ? `${storeConfig.DAN_MURPHY_CELLAR_STORE}${store.data.suburb?.toLowerCase()}`
              : store.data.suburb?.toLowerCase()}
          </span>}
          {store.data.distance && <div className='store__distance'>{store.data.distance}km</div>}
        </div>

        <div className='store__address'>{store.data.addressLine1}</div>
        <div className='store__address'><span className='store__suburb'>{store.data.suburb.toLowerCase()}</span> {store.data.postcode}</div>
      </div>
      <div className={'store__hours ' + (isHostDevice && 'store__hours--device')} >
        <div className='store__date'>
          <span className={timeStr1.simpleTradingstatus?.[0] === 'Closed now' ? 'store__status-today-closed' : 'store__status-today-open'}>
            <b>{timeStr1.simpleTradingstatus?.[0]}</b>
          </span>
          <span className='store__opening-hours'>
            {timeStr2.simpleTradingstatus && ''} {timeStr1.simpleTradingstatus?.[1]} {timeStr1.simpleTradingstatus?.[2]}
          </span>
        </div>
      </div>
      {renderTradingHours(store)}
      <div>
        <Button id="store-select-link" disableRipple={true} style={{ letterSpacing: '0.01em' }} className={classes.button} onClick={onClickHandler}>Select store</Button>
      </div>
    </div>
  );
}

export {
  StoreSelect
}