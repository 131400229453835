import { PriceType } from '../models/fawTypes'

enum Prices {
    under10 = "Under $10",
    under20 = "$10 - $20",
    under30 = "$20 - $30",
    under50 = "$30 - $50",
    under75 = "$50 - $75",
    under100 = "$75 - $100",
    over100 = "$100 +",
    any = "Any price"
}

const PriceRangeMapping: PriceType = {
    "Under $10": [0,10],
    "$10 - $20": [10, 20],
    "$20 - $30": [20, 30],
    "$30 - $50": [30, 50],
    "$50 - $75": [50, 75],
    "$75 - $100": [75, 100],
    "$100 +": [100],
    "Any Price": []
}

export { Prices, PriceRangeMapping }