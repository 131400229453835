import * as ACTIONS from './actionTypes';
import { config } from '../config/config';
import { postMessageToClient } from '../widget/chatservice/communicationService';
import { updateAnalyticsStatus } from '../widget/chatservice/dialogflowQuery';
import { BannerInfoDataModel } from '../widget/models/NotificationBannerInfoModel';

//FAW
export const updateWineType = (selectedWineType: string) => {
    return {
        type: ACTIONS.UPDATE_WINE_TYPE,
        selectedWineType: selectedWineType
    }
}

export const updatePriceRange = (selectedPriceRange: string) => {
    return {
        type: ACTIONS.UPDATE_PRICE_RANGE,
        selectedPriceRange: selectedPriceRange
    }
}

export const showWineExpert = (selectedWineType: string) => {
    return {
        type: ACTIONS.SHOW_WINE_EXPERT,
        selectWineExpert: selectedWineType
    }
}

export const updateWineVarietal = (selectedVarietal: string) => {
    return {
        type: ACTIONS.UPDATE_VARIETAL,
        selectedVarietal: selectedVarietal
    }
}

export const updateCurrentView = (currentView: string) => {
    return {
        type: ACTIONS.UPDATE_CURRENT_VIEW,
        currentView: currentView
    }
}

export const updateSelectedType = (selectedType: string) => {
    return {
        type: ACTIONS.UPDATE_SELECTED_TYPE,
        selectedType: selectedType
    }
}

export const updateSelectedStore = (selectedStoreId: string, selectedStoreName: string, intentData: any) => {
    return {
        type: ACTIONS.UPDATE_STORE_DETAILS,
        selectedStoreId: selectedStoreId,
        selectedStoreName: selectedStoreName,
        intentData: intentData
    }
}

export const resetSelection = () => {
    return {
      type: ACTIONS.RESET_SELECTION
    }
}


export const openPdp = (pdpOpened: boolean) => {
    return {
      type: ACTIONS.PDP_OPENED,
      pdpOpened: pdpOpened
    }
};

export const showFawPage = (showFaw: boolean) => {
    return {
      type: ACTIONS.SHOW_FAW,
      showFAW: showFaw
    }
};

//Product Search
const requestProducts = () => {
    return {
      type: ACTIONS.REQUEST_PRODUCTS
    }
}

const receivedProducts = (productsData: any) => {
    return {
        type: ACTIONS.RECEIVED_PRODUCTS,
        productsData: productsData
    };
};

const errorInReceivingProducts = (errorMessage: string) => {
    return {
      type: ACTIONS.ERROR_RECEIVED,
      errorMessage: errorMessage
    }
};


export const fetchProducts = (reqData: any) => {
    const APi_URL = `${config.CHATSERVER_URL}/api/Azure/GetRecommendations`;
    return (dispatch: any) => {
        dispatch(requestProducts());
        
        fetch(APi_URL, {
            method: 'post',
            headers: { 
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': config.APIMGT_KEY
            },
            body: JSON.stringify({"parameters": reqData, "event": "FAW"})
        }).then(response => response.json())
        .then(res => { 
            if(res.statusCode === 200) {
                dispatch(receivedProducts(res.data));
            } else {
                throw res;
            }
        })
        .catch(error => {
            dispatch(errorInReceivingProducts(error.message))
        })
    }
}

export const openPdpDansPage = (wineLink: string, storeName: string, storeId: string, wineName:string, selectedType:string, intentData: any) => {
    return (dispatch: any) => {
        const prodDetailsLink = wineLink ? `/product/DM_${wineLink.split('/product/')[1]}` : '';
        const linkName = `web:chatbot:find a wine:recommendations:${selectedType}:tile:${wineName}`;

        dispatch(openPdp(true));

        postMessageToClient({
            data : {
                linkName : linkName.toLowerCase(),
                linkType: 'chatbot find a wine',
            },
            methodHandler : "chatbotTriggerAdobeAnalytics"
        });

        postMessageToClient({
            data : {
                prodDetailsUrl : prodDetailsLink,
                queryString: { i_cid: 'cbfaw' },
                storeId: storeId,
                storeName: storeName.charAt(0).toUpperCase() + storeName.slice(1).toLowerCase()
            },
            methodHandler : "chatbotInitChangeUrl"
        });

        // if message type is DQR post a success to analytics when clicked.
        updateAnalyticsStatus(intentData.sessionId, intentData.userId, intentData.intentGroupId, true);
    }
}

//Bot Data

export const isBotOpenFirstTime = (isWidgetOpenFirstTime: boolean) => {
    return {
        type: ACTIONS.IS_WIDGET_OPEN_FIRST_TIME,
        isWidgetOpenFirstTime: isWidgetOpenFirstTime
    };
};

export const updateUserFirstName = (userFirstName: string) => {
    return {
        type: ACTIONS.UPDATE_USER_FIRST_NAME,
        userFirstName: userFirstName
    };
};

export const updateBannerInfo = (bannerInfo: BannerInfoDataModel) => {
    return {
        type: ACTIONS.UPDATE_BANNER_INFO,
        bannerInfo: bannerInfo
    };
};

//Live Chat Data

export const updateLCUserInitalPosition = (userInitialPosition: number) => {
    return {
        type: ACTIONS.UPDATE_LC_USER_INITIAL_POSITION,
        liveChatUserInitialPosition: userInitialPosition
    };
};


export const updateLCUserPosition = (userPosition: number) => {
    return {
        type: ACTIONS.UPDATE_LC_USER_POSITION,
        liveChatUserQueuePosition: userPosition
    };
};

export const isCustomQueueMessageTriggered = (messageTriggered: boolean) => {
    return {
        type: ACTIONS.IS_LC_MESSAGE_TRIGGERED,
        isCustomQueueMessageTriggered: messageTriggered
    };
};

export const setChipsSelected = (selection: any) => {
    return {
        type: ACTIONS.FEEDBACK_CHIP_SELECTION,
        chipSelection: selection
    };
};

export const setAddtlComments = (comments: any) => {
    return {
        type: ACTIONS.FEEDBACK_ADDITIONAL_COMMENTS,
        additionalComments: comments
    };
};

export const setShowOrderTrackingDetail = (showDetail: boolean) => {
    return {
        type: ACTIONS.SHOW_ORDERTRACKING_DETAIL,
        showOrderTrackingDetail: showDetail
    };
};

export const setShowNomineeChangeScreen = (showNomineeChangeScreen: boolean) => {
    return {
        type: ACTIONS.SHOW_NOMINEE_CHANGE_SCREEN,
        showNomineeChangeScreen: showNomineeChangeScreen
    };
}

export const setShowNomineeChangeFailedScreen = (showNomineeChangeFailedScreen: boolean) => {
    return {
        type: ACTIONS.SHOW_NOMINEE_CHANGE_Failed_SCREEN,
        showNomineeChangeFailedScreen: showNomineeChangeFailedScreen
    };
}

export const setShopperId = (shopperId: number) => {
    return {
        type: ACTIONS.SHOPPER_ID,
        shopperId: shopperId
    };
};

export const setWidgetSessionId = (widgetSessionId: string) => {
    return {
        type: ACTIONS.WIDGET_SESSION_ID,
        widgetSessionId: widgetSessionId
    };
};

export const setOrderForTile = (orderDataExpandedTile: any) => {
    return {
        type: ACTIONS.ORDERDATA_EXPANDED_TILE,
        orderDataExpandedTile: orderDataExpandedTile
    };
};

export const setClickedPickupOrder = (clickedPickupOrder: any) => {
    return {
        type: ACTIONS.CLICKED_PICKUP_ORDER,
        clickedPickupOrder: clickedPickupOrder
    };
};

export const updateLastFollowUpEventName = (followUpEventName: any) => {
    return {
        type: ACTIONS.LAST_FOLLOWUP_EVENT_NAME,
        lastFollowUpEventName: followUpEventName
    };
};