/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './thankYou.scss';

type thankYouProps = {
    onGoBackClick: () => void
}

const ThankYou = ({onGoBackClick}: thankYouProps) => {
    return (        
        <div className="success__container">
            <img src="/images/success.svg" className="" alt="success" />
            <span className="success__thanks">Thanks for reaching out</span>
            <span className="success__note">Someone will get back to you soon</span>
            <a onClick={onGoBackClick} className="success__back">Go back</a>
        </div>   
    )
}

export default ThankYou
