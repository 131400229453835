import { config } from './config.env';

const livechat = {
    HEADER_TEXT: `Live chat`,
    ENDCHAT_TIMEOUT : 1500,
    LIVECHAT_TIMEOUT : 1000 * 60 * 20,
    RATING_TIMEOUT: 3000
} 


const acceptanceIntent = {
    DEFAULT_TIMEOUT: 3000,
    NEARBY_STORES_TIMEOUT :6000,
    ARTICLES_FOLLOWUP_DEFAULT_TIMEOUT: 3000,
    ORDERTRACKING_AUTH_TIMEOUT: 10000,
    SDD_ARTICLE_TIMEOUT: 5000
}

const vote = {
    QUESTION_TEXT: "Does this article help answer your question?",
    QUESTION_TEXT_ORDERTRACKING:"Does this help answer your question?",
    YES_TEXT: "\u{1F603} Yes",
    NO_TEXT: "\u{1F97A} I need help"
}

const storeConfig = {
    STORE_NUMBER_IN_CAROUSEL: 5,
    GOOGLE_MAP_API_DIRECTION_URL: "https://www.google.com/maps/dir/?api=1&destination=",
    DAN_MURPHY_CELLAR_STORE_SUBURB: ['prahran', 'lane cove'],
    DAN_MURPHY_CELLAR_STORE: "The Dan Murphy Cellar - ",
}

const dialogflowEvent = {
    End_Interaction: "End_Interaction",
    Chatbot_Additional_Questions: "Chatbot_Additional_Questions",
    Authenticated_Chatbot_Additional_Questions: "Chatbot_Authenticated_Additional_Questions",
    Chatbot_Contact_Options: "Chatbot_Contact_Options",
    Authenticated_Chatbot_Contact_Options: "Chatbot_Authenticated_Contact_Options",
    Dans_Chatbot_Welcome: "Dans_Chatbot_Welcome",
    Chatbot_Article_FirstSuggestions: "Chatbot_Article_FirstSuggestions",
    KEEP_ALIVE: "KEEP_ALIVE",
    LIVECHAT_GETINTOUCH: "LIVECHAT_GETINTOUCH",
    ACCEPTANCE_RATE: "ACCEPTANCE_RATE",
    PRODUCTAVAIL_FOLLOWUP: "PRODUCTAVAIL_FOLLOWUP",
    Chatbot_FAW_Followup_Questions: "Chatbot_FAW_Followup_Questions",
    Chatbot_FAW_Try_Again: "Chatbot_FAW_Try_Again",
    Chatbot_FAW_Select_Store_Again: "Chatbot_FAW_Select_Store_Again",
    PRODUCT_AVAIL_FIND_NEARBY_STORES:"PRODUCT_AVAIL_FIND_NEARBY_STORES",
    ORDERTRACKING_AUTHENTICATED_FOLLOWUP: "ORDERTRACKING_AUTHENTICATED_FOLLOWUP",
    ORDER_TRACKING_DELIVERY_TYPE: "ORDER_TRACKING_DELIVERY_TYPE",
    RESENDTAXINVOICE_ASK_ORDER_NUMBER_AFTER_LOGIN: "RESENDTAXINVOICE_ASK_ORDER_NUMBER_AFTER_LOGIN",
    ORDER_TRACKING_MY_ORDERS_PICKUP: "ORDER_TRACKING_MY_ORDERS_PICKUP",
    ORDER_TRACKING_PICK_UP_ORDER_CLICKED: "ORDER_TRACKING_PICK_UP_ORDER_CLICKED",
    ORDER_TRACKING_PICK_UP_NOMINEE_UPDATED: "ORDER_TRACKING_PICK_UP_NOMINEE_UPDATED",
    ORDER_TRACKING_MY_ORDERS_PICKUP_CHANGE_NOMINEE_CANCEL:"ORDER_TRACKING_MY_ORDERS_PICKUP_CHANGE_NOMINEE_CANCEL",
    ORDER_TRACKING_MY_ORDERS_PICKUP_COLLECTED:"ORDER_TRACKING_MY_ORDERS_PICKUP_COLLECTED",
    ORDER_TRACKING_MY_ORDERS_PICKUP_CANCELLED:"ORDER_TRACKING_MY_ORDERS_PICKUP_CANCELLED"
}

const orderTrackingStatusConfig = {
    PROCESSED: "Processed",
    IN_DEPOT: "In depot",
    ON_ITS_WAY: "On its way",
    DELIVERED: "Delivered",
    DELIVERY_FAILED: "Delivery failed",
    DELIVERY_CANCELLED: "Delivery cancelled",
    DELAYED: "Delayed"
}

type statusMapping = { [key: string]: string };

const orderTrackingStatusMapping : statusMapping= { 
    "Manifest Received" : orderTrackingStatusConfig.PROCESSED,
    "Confirmed" : orderTrackingStatusConfig.PROCESSED,
    "In transit to delivery depot" : orderTrackingStatusConfig.PROCESSED,
    "Awaiting Picking" : orderTrackingStatusConfig.PROCESSED,
    "Packed" : orderTrackingStatusConfig.PROCESSED,
    "Pickup Complete" : orderTrackingStatusConfig.IN_DEPOT,
    "Received in Depot" : orderTrackingStatusConfig.IN_DEPOT,
    "Consignment Merged" : orderTrackingStatusConfig.IN_DEPOT,
    "Dispatched" : orderTrackingStatusConfig.IN_DEPOT,
    "Out For Delivery" : orderTrackingStatusConfig.ON_ITS_WAY,
    "With Driver" : orderTrackingStatusConfig.ON_ITS_WAY,
    "Consignment with Delivery Partner" : orderTrackingStatusConfig.ON_ITS_WAY,
    "Delivered" : orderTrackingStatusConfig.DELIVERED,
    "Delivered - Left as Instructed" : orderTrackingStatusConfig.DELIVERED,
    "Delivered - Signed" : orderTrackingStatusConfig.DELIVERED,
    "Delivery attempt failed" : orderTrackingStatusConfig.DELIVERY_FAILED,
    "Consignment closed" : orderTrackingStatusConfig.DELIVERED,
    "Manifest Import Reversed" : orderTrackingStatusConfig.DELIVERY_CANCELLED,
    "Consignment Cancelled" : orderTrackingStatusConfig.DELIVERY_CANCELLED,
    "Consignment Confirmed" : orderTrackingStatusConfig.PROCESSED,
    "Picking in Progress" : orderTrackingStatusConfig.PROCESSED
}

const orderTrackingConfig = {
    QUICK_REPLY_TEXT: "View detailed tracking",
    REBOOK_REPLY_TEXT: "Rebook your delivery"
}

const zendeskErrorConfig = {
    ERROR_RESPONSE_ARRAY : ["Hmm... looks like I'm having some technical issues. Give it another try in a few minutes."
    ,"Uh oh... Looks like someone unplugged me. Please retry live chat later or leave the customer service team a message."
    ,"Oh no... Looks like someone switched around my wires and I can't connect you to live chat right now. Please try again in a few minutes."]
}
const resendTaxInvoiceAcceptIntent = {
    RESEND_TAX_INVOICE_CANCEL_ORDER : "Resend Tax Invoice-my-order-cancel-order",
    RESEND_TAX_INVOICE_MY_ORDER : "Resend Tax Invoice-my-order"
}

const cdnImgUrl = "https://egl-assets.scene7.com/is/image/endeavour";

export {
    config,
    vote,
    dialogflowEvent,
    storeConfig,
    orderTrackingStatusMapping,
    orderTrackingStatusConfig,
    livechat,
    acceptanceIntent,
    orderTrackingConfig,
    zendeskErrorConfig,
    resendTaxInvoiceAcceptIntent,
    cdnImgUrl
}