import React, { useEffect, useState } from 'react'
import './message.scss';
import { Speaker } from '../models/enum';
import Brand from '../brand/Brand';
import { isValidURL } from '../util/validators';

const LiveChatMessage = ({liveChatMessage, liveChatAgent,liveChatMessageListArray}: any) => {    
    
    const [brand, setBrand] = useState(true);
    const [addMargin, setAddMargin] = useState('large-margin ');
    
    const garnishResponse = (responseText: string) => {
        if (isValidURL(responseText)) {
            let link = (responseText.indexOf('https://') === 0 || responseText.indexOf('http://') === 0) ?
                responseText : `http://${responseText}`;
            return <a className="message__text--link" href={link} target="_blank" rel="noopener noreferrer">{responseText}</a>
        } else {
            return responseText;
        }
    }

    useEffect(() => {

        if (liveChatMessage.from != 'system') {
            liveChatMessageListArray.push(liveChatMessage);
        }

        const len:number = liveChatMessageListArray.length;

        if (liveChatMessageListArray.length > 1) {
            const prev = liveChatMessageListArray[len-2];
            if (prev.from === undefined || (prev.from === 'agent' && liveChatMessage.from ==='agent') || (prev.from === 'murphy' && liveChatMessage.from === 'murphy')) {
                setBrand(false)
                setAddMargin('small-margin ');
            }
            if ( prev.from === 'user' && liveChatMessage.from === 'user') {
                setAddMargin('small-margin ');
            }
          
        }
      
    }, [])
    


    return (
        <div className="livechat">
            <div className="livechat__message">
                {
                    liveChatMessage.from === Speaker.System &&
                    <div className="livechat__message--system">
                        {liveChatMessage.text}
                    </div>
                }
                {
                    liveChatMessage.from === Speaker.Agent &&
                    <div className={"livechat__message--agent "+addMargin}>
                        {brand &&
                        <div className="message__brand">
                            <Brand isLiveChat={true} size='small' />
                            <span className='message__label'>{liveChatAgent} (agent)</span>
                        </div>
                        }
                        <div className="livechat__message--agent-text"> 
                            <div className="message__text">
                                {garnishResponse(liveChatMessage.text)}
                            </div>
                        </div>
                    </div>
                }

                {
                    liveChatMessage.from === Speaker.Murphy &&
                    <div className={"livechat__message--agent "+addMargin}>
                        <div className="message__brand">
                            <Brand size='small' />
                            <span className='message__label'>Murphy (bot)</span>
                        </div>
                        <div className="livechat__message--agent-text"> 
                            <div className="message__text">
                                    {liveChatMessage.text}
                            </div>
                        </div>
                    </div>
                }

                {
                    liveChatMessage.from === Speaker.User &&
                    <div className={"livechat__message--right "+ addMargin}>
                        <div className="livechat__message__text">
                                {liveChatMessage.text}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default LiveChatMessage
