import React from 'react';
import '../messageOrderTracking.scss';
import MessageUnit from '../MessageUnit';

type messageDeliveredProps = {
    deliveredObj: {
        courierName: string,
        orderNumber: string,
        dateDisplayText: string,
        barCodeNumber: string
    }
};

const MessageDelivered = ({deliveredObj}: messageDeliveredProps) => {
    return (
        <div className="orderTrackingMessage__delivery">
            <div>Your order <MessageUnit info={deliveredObj.orderNumber}/> was delivered on <MessageUnit info={deliveredObj.dateDisplayText} /> by <MessageUnit info={deliveredObj.courierName} />

                { deliveredObj.courierName.toLowerCase() === 'xl express'
                    &&
                    <span><br /><br />
                        To track your delivery, view detailed tracking below and enter the following number { deliveredObj.barCodeNumber }.
                    </span>
                }
            </div>            
        </div>
    );
};

export default MessageDelivered;
