import React, { useState, useEffect } from 'react';
import '../message.scss';
import './messageOrderTracking.scss';
import Brand from '../../brand/Brand';
import MessageOrderTrackingSingle from './Single/MessageOrderTrackingSingle';
import MessageOrderTrackingMultiple from './Multiple/MessageOrderTrackingMultiple';
import MessageSummary from './MessageSummary';
import { toDate } from '../../util/dateTimeHelper';
import { mapStatus, getSummaryOpenerText } from './orderTrackingService';
import { filterByNonePickUp } from './orderTrackingService';

const MessageOrderTracking = ({message,indexParent, msgs}: any) => {
    const [isMultiple, setIsMultiple] = useState<boolean>(false);
    const numberOfDeliveries = message.data.trackingInfo[0].consignmentNotes.length;
    const filteredConsignments = filterByNonePickUp(message.data.trackingInfo[0].consignmentNotes);
    const numberOfConsignments = filteredConsignments.length;

    useEffect(() => {
        setIsMultiple(numberOfDeliveries > 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="message">            
            { message.from !== 'user' && (
                <div className="message__brand">
                    <Brand size='small'/>
                </div>
            )}

            <div>
                <div className="message__text orderTrackingMessage">
                    <MessageSummary text={getSummaryOpenerText(message)} isMultiple={numberOfConsignments > 1} orderNumber={message.data.searchTermOrderName} numberOfConsignments={numberOfConsignments} />
                </div>
                <div className="orderTrackingMessage">                    
                    {isMultiple ? <MessageOrderTrackingMultiple consignments={filteredConsignments} toDate={toDate} mapStatus={mapStatus}  indexParent={indexParent} msgs={msgs} /> :
                        <MessageOrderTrackingSingle message={message} toDate={toDate} mapStatus={mapStatus}  indexParent={indexParent}  msgs={msgs}/>}                   
                </div>               
            </div>          
        </div>
    );
};

export default MessageOrderTracking;
