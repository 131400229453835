import React from 'react';
import '../messageOrderTracking.scss';
import MessageUnit from '../MessageUnit';

type messageDelayedProps = {
    orderNumber: string
};

const MessageDelayed = ({orderNumber}: messageDelayedProps) => {
    return (
        <div className="orderTrackingMessage__delivery">
            <div>Unfortunately, your delivery <MessageUnit info={orderNumber}/> is <b>delayed</b>. </div>
            <br />
            <div>Please check your tracking below. If its only been a day or so, bear with us. Longer than a week? Please get in touch.</div>
        </div>            
    );
};

export default MessageDelayed;
