import React, { useEffect, useState } from 'react';
import { useSelector  } from 'react-redux';
import { RootState } from '../../reducers/index';

import ResultCatergory from '../result-category/resultCatergory'
import Loader from '../../widget/loader/Loader';
import { ResultProps } from '../models/fawTypes';
import { toTitleCase } from '../../widget/util/commonUtil';
import { Varietals } from '../varietal/VarietalsModel';
import { Prices } from '../price/PriceModel';
import { Types }  from '../wine-type/wineTypeModel';

const Results = ({recommendedWines, bestSellers, topRatedSellers, newAndNoteworthySellers, intentData}: ResultProps) => {
  
  const isFetching = useSelector((state: any) => { return state.productData.isFetching});
  const [noResultDisplay, setNoResultDisplay] = useState(false);
  const wineType = useSelector((state: RootState) => { return state.fawData.selectedWineType;});
  const priceRange = useSelector((state: RootState) => { return state.fawData.selectedPriceRange;});
  const varietal = useSelector((state: RootState) => { return state.fawData.selectedVarietal;});
  const storeName = useSelector((state: RootState) => { return state.fawData.selectedStoreName });

  useEffect(() => {
    if(recommendedWines.length) {
      if(!bestSellers.length && !topRatedSellers.length && !newAndNoteworthySellers.length) {
        setNoResultDisplay(true);
      } else {
        setNoResultDisplay(false);
      }
    } else {
      setNoResultDisplay(true);
    }
  }, [recommendedWines.length]);

  const getNoResultDisplayText = () => {
    if(varietal && varietal !== Varietals.NotSure && priceRange !== Prices.any) {
      return `<p>Hmm, looks like <b>Dan Murphy’s ${toTitleCase(storeName)}</b> is running dry on <b>${varietal}</b> between <b>${priceRange}</b>.</p>
              <p>Try changing the filters you used by starting again or going back.</p>`;
    } else if (varietal && varietal !== Varietals.NotSure && priceRange === Prices.any) {
      return `<p>Hmm, looks like <b>Dan Murphy’s ${toTitleCase(storeName)}</b> is running dry on <b>${varietal}</b>.</p>
              <p>Try changing the filters you used by starting again or going back.</p>`;
    } else if (wineType && wineType === Types.NotSure && priceRange !== Prices.any) {
      return `<p>Hmm, looks like <b>Dan Murphy’s ${toTitleCase(storeName)}</b> is running dry for anything between <b>${priceRange}</b>.</p>
              <p>Try changing the filters you used by starting again or going back.</p>`;
    } else {
      return `<p>Hmm, looks like <b>Dan Murphy’s ${toTitleCase(storeName)}</b> is running dry at the moment.</p>
              <p>Try changing the filters you used by starting again or going back.</p>`;
    }
  }


    return (
      <>
      {isFetching ? 
        <div className="loader-container">
          <Loader />
        </div> : 
        <div className="faw-type-container">
          { noResultDisplay ? <div className="no-result-display" dangerouslySetInnerHTML={{ __html: `${getNoResultDisplayText()}` }}></div>:     
            <>
              <ResultCatergory fawRecommendation= {[...bestSellers]} categoryType="Best Sellers" intentData={intentData}></ResultCatergory>
              <ResultCatergory fawRecommendation= {[...topRatedSellers]} categoryType="Top Rated" intentData={intentData}></ResultCatergory>
              <ResultCatergory fawRecommendation= {[...newAndNoteworthySellers]} categoryType="New & Noteworthy" intentData={intentData}></ResultCatergory>
            </>
          }
        </div>
      }
      </>
    )
}
  
export default Results