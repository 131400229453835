import React, { useState } from 'react';
import Arrow from './Arrow';
import { StoreSelectorDetail } from '../store-selector-detail/StoreSelectDetail';
import './store-selector.scss';
import { getUniqueId } from '../../widget/util/commonUtil';

type storeSelectorProps = {
    stores: any,
    isHostDevice: boolean,
    setSelectedStoreData:any,
    quickReplyHandler: any,
};

const StoreSelector = ({stores, isHostDevice, setSelectedStoreData, quickReplyHandler} : storeSelectorProps) => {

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [itemLength] = useState<number>(stores.length);

    const previous = () => {
        if(activeIndex!=0){
        let index = activeIndex;
        let length = itemLength;

        if (index < 1) {
            index = length - 1;
        } else {
            index--;
        }

        setActiveIndex(index);
    }
    }

    const next = () => { 
        if(activeIndex!=4){ 
        let index = activeIndex;
        let length = itemLength;

        if (index === length - 1) {
            index = 0;
        } else {
            index++;
        }

        setActiveIndex(index);
    }
    }

    const setStore = (storeId: number) => {
        for (let i = 0; i < stores.length; i++) {
            if (parseInt(stores[i].data.storeId) === storeId) {
                stores[i].isSelected = true;
            } else {
                stores[i].isSelected = false;
            }
            
        }
    }

    return (
        <div className='carousel__container'>
            <div>
                {
                stores.map((s: any, index: number) => (
                    <div className={index === activeIndex ? 'carousel__active' : 'carousel__inactive'} key={index} id={getUniqueId('store-select', (index+1).toString())}>
                        <StoreSelectorDetail store={s} isHostDevice={isHostDevice} setStore={setStore} setSelectedStoreData={setSelectedStoreData} quickReplyHandler={quickReplyHandler}/>
                    </div>
                    ))
               }
            </div>
            <div className='carousel__arrows'>
                <Arrow direction='left' clickHandler={previous} activeIndex={activeIndex} />
                <div className="carousel__paging">{activeIndex+1} of {stores.length} stores</div>            
                <Arrow direction='right' clickHandler={next} activeIndex={activeIndex}/>
            </div>
        </div>
    );
}

export {
    StoreSelector
};