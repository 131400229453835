
import { sortBy } from 'lodash';
import  moment  from 'moment';

type tradingHour = {
    date: Date,
    text: string,
    hours: string
}

const weekday = new Array<string>(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

const isToday = (date: Date) => {
    const today = new Date()
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
}

const isTomorrow = (date: Date) => {
    const today = new Date();
    return date.getDate() === today.getDate() + 1 && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
}

const tradingHoursFormatter = (rawTradingHours: any) : Array<tradingHour>  => {
    let formatted: Array<tradingHour> = [];

    formatted = Object.keys(rawTradingHours).map((t: string) => {
        let date = new Date(t);
        let text = isToday(date) ? 'Today' : isTomorrow(date) ? 'Tomorrow' : weekday[date.getDay()];
        let hours = rawTradingHours[t].stringValue;
        return {
            date: date,
            text: text,
            hours: hours
        }
    });

    const result = sortBy(formatted, 'date');
  
    return result;
}

const isPast = (dateString: string) => {
    if (dateString === '' || dateString === null || dateString === undefined) {
        return false;
    }

    const today = new Date();
    const date = new Date(dateString);
    return today > date;
}

const toDate = (value: string) => {
    if (value === '' || value === null || value === undefined) {
        return 'N/A';
    }

    let dateObj = new Date(value);
    return dateObj.toDateString();
}

const getDST = (): string => {
  const date = new Date();
  return moment(date).isDST() ? 'AEDT' : 'AEST';
}

export { 
    tradingHoursFormatter,
    isPast,
    toDate,
    getDST
}