import React, { useEffect, useState } from 'react';
import { storeConfig } from '../../config/config';
import { DialogflowQueryType } from '../models/enum';
import './store-selector-detail.scss';
import '../message/message.scss';
import { postMessageToClient } from '../chatservice/communicationService';
import { Button, makeStyles } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';


type StoreSelectorDetailProps = {
  store: any,
  isHostDevice: boolean,
  setStore: any,
  quickReplyHandler: any,
  setSelectedStoreData: any
}

const theme = createTheme({
  typography: {
    "fontFamily": `"din-2014","Segoe UI",'Helvetica','Helvetica','sans-serif','Arial'`,
    "fontSize": 14
  }
});

const useStyles = makeStyles({
  button: {
    backgroundColor: '#4C8251',
    color: '#FFF',
    textTransform: 'none',
    fontStyle: 'normal',
    padding: '10px 24px',
    borderTop: '1px solid #eee',
    borderLeft: '1px solid #eee',
    borderRight: '1px solid #eee',
    borderBottom: '1px solid #eee',
    borderRadius: '0px',
    fontSize: '14px',
    width: '100%',
    transition: 'all .2s ease',
    '&:hover': {
      backgroundColor: '#305233',
    },
    '&:focus-visible': {
      backgroundColor: '#305233',
      boxShadow: '0 0 0 3px #FFFFFF, 0 0 0 5px #5795DB',
      borderRadius: '2px',
    },
    '&:active': {
      backgroundColor: '#143017',
      border: 'none'
    }
  },
  isSelected: {
    background: '#143017',
  },
})

const StoreSelectorDetail = ({ store, isHostDevice, setStore, setSelectedStoreData, quickReplyHandler }: StoreSelectorDetailProps) => {
  const [timeStr1, timeStr2] = store?.data?.store_trading_hours?.map((x: { tradingStatus: any }) => ({ "simpleTradingstatus": x.tradingStatus })) ?? ['', ''];
  const classes = useStyles();

  const onClickHandler = (e: any) => {
    const params: any = {
      selectedStoreId: store.data.storeId,
      selectedStoreName: 'Dan Murphy\'s ' + store.data.suburb.charAt(0).toUpperCase() + store.data.suburb.slice(1).toLowerCase()
    }


    if (!store.isSelected) {
      quickReplyHandler(e, DialogflowQueryType.Event, 'PRODUCT_AVAIL_SET_STORE', false, params);
      store.isSelected = true;
      setStore(parseInt(store.data.storeId));
      setSelectedStoreData(store.data);
    }
  }

  const renderTradingHours = (store: { data: { store_trading_hours: any[]; }; }) => {
    return (
      <div className='store__week-status'>
        {store?.data?.store_trading_hours?.map((day, index) => {
          return renderTradingHour(day, index);
        })}
      </div>
    );
  }

  const renderTradingHour = (day: { day: string | any[]; openHour: string; }, index: React.Key | null | undefined) => {
    if (index !== 0 && index !== 7) {
      return (
        <div className="store__trading-Hours" key={index}>
          <div className="store__trading-Hours__day">
            <span>
              {day.day}
            </span>
          </div>
          <span className="store__trading-Hours__openHour">
            {day.openHour.toLowerCase() !== "closed" ? (
              <div>
                <span className="store__trading-Hours__openHour-time">
                  {day.openHour}
                </span>
              </div>
            ) : ("Closed")}
          </span>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className='store'>
        <div className='store__location'>
          <div className='store__title'>
            {<span className='store__suburb'>
              {storeConfig.DAN_MURPHY_CELLAR_STORE_SUBURB.includes(store.data.suburb?.toLowerCase())
                ? `${storeConfig.DAN_MURPHY_CELLAR_STORE}${store.data.suburb?.toLowerCase()}`
                : store.data.suburb?.toLowerCase()}
            </span>}
            {store.data.distance && <div className='store__distance'>{store.data.distance} km</div>}
          </div>
          <div className='store__address'>{store.data.addressLine1}, <span className='store__suburb'>{store.data.suburb.toLowerCase()},</span> {store.data.postCode}</div>
        </div>
        <div className={'store__hours ' + (isHostDevice && 'store__hours--device')} >
          <div className='store__date'>
            <span className={timeStr1.simpleTradingstatus?.[0] === 'Closed now' ? 'store__status-today-closed' : 'store__status-today-open'}>
              <b>{timeStr1.simpleTradingstatus?.[0]}</b>
            </span>
            <span className='store__opening-hours'>
              {timeStr2.simpleTradingstatus && ''} {timeStr1.simpleTradingstatus?.[1]} {timeStr1.simpleTradingstatus?.[2]}
            </span>
          </div>
        </div>
        {renderTradingHours(store)}
        <div className='store__button'>
          <Button id="store-select-link" className={`${classes.button} ${(store.isSelected) ? classes.isSelected : '' }`} disableRipple={true} style={{ letterSpacing: '0.01em' }} onClick={onClickHandler}>{(!store.isSelected) ? "Select store" : "Selected"}
            {store.isSelected && <img className='store__button--icon' src='images/check-Filled.svg'/>}
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
}

export {
  StoreSelectorDetail
}