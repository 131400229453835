import React from 'react';
import { orderTrackingStatusConfig } from '../../../../config/config';
import '../../message.scss';
import '../messageOrderTracking.scss';
import MessageConsignment from './MessageConsignment';

type messageOrderTrackingMultipleProps = {
    consignments: any,
    toDate: any,
    mapStatus: any,
    indexParent: number,
    msgs: any
};

const MessageOrderTrackingMultiple = ({consignments, toDate, mapStatus, indexParent, msgs}: messageOrderTrackingMultipleProps) => { 
    const isAllDelayed = consignments.every((consignment: { status: string; expectedDeliveryDate: string; }) => {
        const mappedStatus = mapStatus(consignment.status, consignment.expectedDeliveryDate);  
        return mappedStatus === orderTrackingStatusConfig.DELAYED
    });
    
    return (
        <div>
            {consignments.map((c: any, index: number) => (
                <MessageConsignment key={index} consignment={c} totalNumberOf={consignments.length} index={index} mapStatus={mapStatus} toDate={toDate} orderTrackingUrl={c.trackingURL} isAllDelayed={isAllDelayed} indexParent={indexParent} msgs={msgs} />
            ))}
        </div>
    );
};

export default MessageOrderTrackingMultiple;
