import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import parse from 'html-react-parser';
import './article.scss';
import { viewArticle, fetchArticle } from '../chatservice/zendeskApis';
import { postMessageToClient } from '../chatservice/communicationService';
import { config } from '../../config/config';
import SkeletonLoaderFAQ from '../skeleton-loader/SkeletonLoaderFAQ'

type ArticleDetailProps = {
    sessionId: any,
    userId: any,
    data: any,
    setIsArticleFound: any,
    isArticleFound: any
}


const ArticleDetail = ({ sessionId, userId, data, isArticleFound, setIsArticleFound }: ArticleDetailProps) => {
    const articleStartRef: any = useRef(null);
    const [articleTitle, setArticleTitle] = useState("");
    const [articleBody, setArticleBody] = useState("");
    const [articleLink, setArticleLink] = useState("");
    const [articleLastUpdated, setArticleLastUpdated] = useState("");

    const clickHandler = () => {
        postMessageToClient({ name: "chatbotOpenArticle", articleName: data.text });
    }

    const scrollToStart = () => {
        articleStartRef?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const trimTheLastUpdated = (articleBodyHtmlString: string) => {
        const pattern = /<strong>Last updated: .*?<\/strong>/gi;
        return articleBodyHtmlString.replace(pattern, '');
    }

    const appendOutboundIconAndAriaTag = (articleBodyHtmlString: string) => {
        const regex = /<a href="(.*?)">(.*?)<\/a>/g;
        const modifiedHTMLString = articleBodyHtmlString?.replace(regex, (match, href, content) => {
            const urlParts = href?.split('/');
            const imageTag = '<img src="/images/external-link-chip.svg" alt="External Link" class="outbound-link__icon" />';
            return `<a class="outbound-link" href="${href}" aria-label="Go to ${urlParts[2] || 'content page'}"><span class="outbound-link__title">${content}</span>${imageTag}</a>`;
        });

        return modifiedHTMLString;
    }

    const articleToBeRendered = (articleBody: string) => {
        const stringWithLastUpdatedTrimmed = trimTheLastUpdated(articleBody);
        const stringWithAppendedOutBoundIcon = appendOutboundIconAndAriaTag(stringWithLastUpdatedTrimmed);
        return parse(stringWithAppendedOutBoundIcon);
    }

    useEffect(() => {
        viewArticle(sessionId, userId, data.intentGroupId, data.queryId, data.articleId, true);
        scrollToStart();

        // fetch article data
        if (isArticleFound === null) {
            fetchArticle(data.articleId).then
                (response => {
                    if (!response.error) {
                        setArticleTitle(response.article.name);
                        setArticleBody(response.article.body);
                        setArticleLastUpdated(response.article.edited_at);

                        const articlePageName = response.article.html_url && response.article.html_url.split('.zendesk.com/hc/en-us/articles/')[1];
                        setArticleLink(`${config.DAN_HELP_CENTRE_URL}/articles/${articlePageName}`);
                        setIsArticleFound(true);
                    } else {
                        setIsArticleFound(false);
                    }
                })
        }
    }, []);

    return (
        <div className="article">
            {
                isArticleFound === true &&
                <div className="article__container">
                    <div ref={articleStartRef} />
                    <div className="article__container-title">
                        <div className="article__container-title-text">{parse(articleTitle)}</div>
                        <a href={articleLink} target="_blank" rel="noopener noreferrer">
                            <img src="/images/external-link-chip.svg" onClick={clickHandler} alt="view article in a new tab" />
                        </a>
                    </div>
                    <div className="article__container-lastupdated">Last updated: {moment(articleLastUpdated)?.format('Do MMMM YYYY')}</div>
                    <div className="article__container-content">{articleToBeRendered(articleBody)}</div>
                </div>
            }
            {isArticleFound === null && <SkeletonLoaderFAQ />}
            {isArticleFound === false && <span>There was an issue loading the article you selected.  Please come back again at another time.</span>}
        </div>
    )
}

export default ArticleDetail
