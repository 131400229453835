import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../reducers/index';
import Cookies from 'universal-cookie';
import { v4 as uuid } from 'uuid';
import { config, dialogflowEvent, livechat, acceptanceIntent, zendeskErrorConfig, resendTaxInvoiceAcceptIntent, cdnImgUrl } from '../config/config';
import { DialogflowQueryType, FollowUpEventTypes, MessageTypes, Speaker } from './models/enum';
import './widget.scss';
import Header from './header/Header';
import Message from './message/Message';
import Banner from './message/Banner';
import MessageQrArticle from './message/MessageQrArticle';
import MessageOrderTracking from './message/orderTracking/MessageOrderTracking';
import MessageQR from './message/MessageQR';
import Vote from './article/Vote';
import MessageLink from './message/MessageLink';
import MessageQuickEvent from './message/MessageQuickEvent';
import MessageQrDynamic from './message/MessageQrDynamic';
import TicketForm from './ticket-form/TicketForm';
import ThankYou from './thank-you/ThankYou';
import Loader from './loader/Loader';
import { eventQueryAsync, textQueryAsync } from './chatservice/dialogflowQuery';
import { handleMessageNewRoutine } from './models/messageHandler';
import { postMessageToClient } from './chatservice/communicationService';
import { Carousel } from './carousel/Carousel';
import zChat from '../zendesk/web-sdk';
import { MessageModel } from './models/messageModel';
import { isLiveChatAvailable, selectUserMessages, selectLiveChatMessages, sendLiveChatMessage } from './chatservice/liveChat';
import LiveChatMessage from './message/LiveChatMessage';
import LiveChatUserForm from './message/livechat/LiveChatUserForm';
import LiveChatRating from './message/livechat/LiveChatRating';
import MessageLiveChatQR from './message/MessageLiveChatQR';
import ManualEndSessionScreen from './message/sessionHandling/ManualEndSession';
import AutoEndSession from './message/sessionHandling/AutoEndSession';
import { StoreSelector } from './store-selector/StoreSelector';
import { ProductList } from './product-list/ProductList';
import { ProductNearbyStoreList } from './product-nearby-store-list/ProductNearbyStoreList';
import { FindAWine } from '../find-a-wine/FindAWine';
import { FAWProps } from '../find-a-wine/models/fawTypes';
import { openPdp, showFawPage, isBotOpenFirstTime, updateLCUserInitalPosition, updateLCUserPosition, isCustomQueueMessageTriggered, setAddtlComments, setWidgetSessionId, setShopperId, setShowOrderTrackingDetail, setOrderForTile, updateLastFollowUpEventName, setClickedPickupOrder, setShowNomineeChangeScreen, updateUserFirstName, updateBannerInfo, setShowNomineeChangeFailedScreen } from '../actions/index';
import ArticleList from './message/ArticleList';
import ArticleDetail from './article/ArticleDetail';
import NotificationBanner from './notification-banner/NotificationBanner';
import { updateLiveChatStatus } from './chatservice/zendeskApis';
import { pickRandomString, getOrdinalSuffix, isMobileOS} from '../widget/util/commonUtil';
import { getDST } from '../widget/util/dateTimeHelper';
import { QrPayloadModel } from './models/qrPayloadModel';
import ArticleListPayloadProps from './message/ArticleListPayload';
import { getConfigSettings, getFeatureSetting, greetLoggedInShopper, greetLoggedInShopperEncrypted } from './chatservice/chatserverApis';

import OrderExpandedTile from './message/verifiedOrderTracking/OrderExpandedTile';
import NomineeChangeScreen from './nominee/NomineeChangeScreen';
import VerifiedOrderList from './message/verifiedOrderTracking/VerifiedOrderList';
import LiveChatTransfer from './message/livechat/LiveChatTransfer'
import SurveyScreen from './message/survey/SurveyScreen';
import { FollowUpEventDataModel } from './models/followUpEventDataModel';
import { Style } from '@material-ui/icons';
import { BannerInfoDataModel } from './models/NotificationBannerInfoModel';

const cookies: Cookies = new Cookies();

let intervalFunction: any;
let timeoutAcceptance: any;
let globalBotTimeout: any;
let startBotSessionTimeout: any;
let globalBotSessionAlive: any;
let lCQueueNotificationInterval: any;
let messageListArray: any[] = [];
let liveChatMessageListArray: any[] = [];
let fawProps: FAWProps;

declare global {
    var userShopperId: number;
    var userSessionId: string;
}

const Widget = () => {
    const messagesEndRef: any = useRef(null);
    const [isHostDevice, setIsHostDevice] = useState<any>((config.ISSTAGING) ? false : null);
    const [input, setInput] = useState<string>('');
    const [messages, setMessages] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [openLiveChat, setOpenLiveChat] = useState<boolean>(false);
    const [showWidget, setShowWidget] = useState<boolean>(false);
    const [showArticle, setShowArticle] = useState<boolean>(false);
    const [articleData, setArticleData] = useState<any>();
    const [paSelectedStoreData, setPaSelectedStoreData] = useState<any>();
    const [showTicketForm, setShowTicketForm] = useState<boolean>(false);
    const [showThankYou, setShowThankYou] = useState<boolean>(false);
    const [acceptIntentTriggeredOnQueryResp, setAcceptIntentTriggeredOnQueryResp] = useState<boolean>(false);

    const [showInputTextBox, setShowInputTextBox] = useState<boolean>(true);
    const [shouldInitWithWelcome, setShouldInitWithWelcome] = useState<boolean>(true);
    const [isArticleFound, setIsArticleFound] = useState<any>(null);

    const [showUserForm, setShowUserForm] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [showLiveChatRating, setShowLiveChatRating] = useState<boolean>(false);
    const [liveChatRating, setLiveChatRating] = useState<string>("");
    const [liveChatAgent, setLiveChatAgent] = useState<string>("");
    const [liveChatTransfer, setliveChatTransfer] = useState<boolean>(false);

    const [showManualEndSessionScreen, setShowManualEndSessionScreen] = useState<boolean>(false);
    const [autoEndSessionScreen, setAutoEndSessionScreen] = useState<boolean>(false);
    const [showSurvey, setShowSurvey] = useState<boolean>(false);
    const [isLiveChatInitiated, setIsLiveChatInitiated] = useState<boolean>(false);

    const [endScreenMountingStyle, setEndScreenMountingStyle] = useState<string>('');
    const [showverigyAge, setShowverigyAge] = useState<boolean>(false)
    const [isOverlayScreenMounted, setisOverlayScreenMounted] = useState<boolean>(false)
    const [showWelcomeMessage, setShowWelcomeMessage] = useState<boolean>(false);

    const [timeoutType, setTimeoutType] = useState<number>(0);
    const [isLCReviewDone, setLCReviewDone] = useState<boolean>(false);
    const [isPickupOrderTriggeredByKeyword, setIsPickupOrderTriggeredByKeyword] = useState<boolean>(false);
    const [lCQueueMessage, setLCQueueMessage] = useState<string[]>([]);
    const [qRPayloadLoaded, setQRPayloadLoaded] = useState(false);

    const [liveChatComment, setLiveChatComment] = useState<string>("");
    const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);
    const [agentNames, setAgentNames] = useState<string[]>([livechat.HEADER_TEXT]);
    const queuePosition: any = useRef();
    const agentChatCount: any = useRef(0);
    const sessionId: any = useRef("");  // this is the dialogflow session id as handled by the chatserver
    const intentGroupId: any = useRef("");
    const intentGroupPendingRenew: any = useRef(false);
    const queryId: any = useRef("");
    const intentGroupName: any = useRef("");
    const intentName: any = useRef("");
    const currentText: any = useRef("");

    const chatbotUserId: any = useRef("");
    const shopperEncryptedEmail: any = useRef("");
    const isChangeAlerted: any = useRef(false);  // isChangeAlerted flag tells the widget whether to display log on status or not, when the user toggles the widget open
    const isUserLoggedOn: any = useRef(false);
    const isWidgetShown: any = useRef(false);
    const isInitWelcomeShown: any = useRef(false);

    const [isNomineeUpdated, setIsNomineeUpdated] = useState(false);

    const isLiveChatOn: any = useRef(false);
    const liveChatHours: any = useRef("");
    const dispatch = useDispatch();

    const pdpOpened = useSelector((state: RootState) => { return state.fawData.pdpOpened });
    const showFAW = useSelector((state: RootState) => { return state.fawData.showFAW });
    const fawStoreName = useSelector((state: RootState) => { return state.fawData.selectedStoreName });
    const fawStreetNumber = useSelector((state: RootState) => { return state.fawData.selectedStoreId });
    const isWidgetOpenFirstTime = useSelector((state: RootState) => { return state.botData.isWidgetOpenFirstTime });
    const lcUserInitialPosition = useSelector((state: RootState) => { return state.botData.liveChatUserInitialPosition });
    const liveChatUserQueuePosition = useSelector((state: RootState) => { return state.botData.liveChatUserQueuePosition });
    const customQueueMessageTriggered = useSelector((state: RootState) => { return state.botData.isCustomQueueMessageTriggered });
    const showOrderTrackingDetail = useSelector((state: RootState) => { return state.botData.showOrderTrackingDetail });
    const showNomineeChangeScreen = useSelector((state: RootState) => { return state.botData.showNomineeChangeScreen });
    const showNomineeChangeFailedScreen = useSelector((state: RootState) => { return state.botData.showNomineeChangeFailedScreen });
    const orderDataExpandedTile = useSelector((state: RootState) => { return state.botData.orderDataExpandedTile });
    const clickedPickupOrder = useSelector((state: RootState) => { return state.botData.clickedPickupOrder });
    const lastFollowUpEventName = useSelector((state: RootState) => { return state.botData.lastFollowUpEventName });
    const showNotificationBanner = useSelector((state: RootState) => { return state.botData.notificationBannerInfo.isBannerActive });
    const [currentOrderId, setCurrentOrderId] = useState<string>("");
    const featureSddPrompt: any = useRef(false);
    const greetingTemplate: any = useRef('');
    const firstName: string = useSelector((state: RootState) => { return state.botData.userFirstName });
    const notificationBannerInfo: BannerInfoDataModel = useSelector((state: RootState) => { return state.botData.notificationBannerInfo });
    //const shopperId: number = useSelector((state: RootState) => { return state.botData.shopperId });
    const widgetSessionId: string = useSelector((state: RootState) => { return state.botData.widgetSessionId });    // this is the widget session, it changes every start of use
    const shopperId: any = useRef(0);
    const encryptedShopperId: any = useRef('');
    const widgetSessionIdLive: any = useRef("");
    let divFooterContainer: HTMLDivElement | null = null;

    async function dialogFlowPing() {
        //To keep session alive with dialog flow
        const params = {
            intentGroupName: intentGroupName.current,
            intentGroupId: intentGroupId.current,
            intentName: intentName.current,
            intentGroupPendingRenew: false,
            shopperId: shopperId.current,
            widgetSessionId: widgetSessionId
        };

        // set livechat availability parameter as false since its irrelevant to this event
        await eventQueryAsync(dialogflowEvent.KEEP_ALIVE, chatbotUserId.current, sessionId.current, false, params);
    }

    const manualDialogFlowPing = async () => {
        // show timeout screen after 10 mins
        resumeGlobalTimeout();
        dialogFlowPing();
    }

    const dialogflowEventQueryAsync = async (text: string, params: any = null, tempWidgetSessionId: any = null) => {
        // show timeout screen after 10 mins
        resumeGlobalTimeout();

        //To keep session alive with dialog flow
        keepSessionAlive();
        const isLCAvailable = isLiveChatAvailable();

        if (params) {
            params['intentGroupName'] = intentGroupName.current;
            params['intentGroupId'] = intentGroupId.current;
            params['intentName'] = intentName.current;
            params['intentGroupPendingRenew'] = intentGroupPendingRenew.current;
            params['shopperId'] = shopperId.current;
            params['widgetSessionId'] = tempWidgetSessionId != null ? tempWidgetSessionId : widgetSessionId;
        } else {
            params = {
                intentGroupName: intentGroupName.current,
                intentGroupId: intentGroupId.current,
                intentName: intentName.current,
                intentGroupPendingRenew: intentGroupPendingRenew.current,
                shopperId: shopperId.current,
                widgetSessionId: tempWidgetSessionId != null ? tempWidgetSessionId : widgetSessionId
            }
        }

        // clear the last follow up event name once the follow up has been triggered
        dispatch(updateLastFollowUpEventName(''));

        // set the false after use
        intentGroupPendingRenew.current = false;
        const result = await eventQueryAsync(text, chatbotUserId.current, sessionId.current, isLCAvailable, params);

        // process response from DF
        const processedMessages = processDFQueryResponse(result);

        if (processedMessages && processedMessages.length > 0) {
            addDelayToMessages(processedMessages);
        }

        setLoader(false);
    }

    const dialogflowTextQueryAsync = async (text: string) => {

        currentText.current = text;
        // remove timeout for acceptane rate
        clearTimeout(timeoutAcceptance);

        // show timeout screen after 10 mins
        resumeGlobalTimeout();

        //To keep session alive with dialog flow
        keepSessionAlive();

        const isLCAvailable = isLiveChatAvailable();

        const params = {
            intentGroupName: intentGroupName.current,
            intentGroupId: intentGroupId.current,
            intentName: intentName.current,
            intentGroupPendingRenew: intentGroupPendingRenew.current,
            shopperId: shopperId.current,
            widgetSessionId: widgetSessionId
        }

        // set the false after use
        intentGroupPendingRenew.current = false;

        const result = await textQueryAsync(text, chatbotUserId.current, sessionId.current, isLCAvailable, params);

        // process response from DF
        const processedMessages = processDFQueryResponse(result);


        if (processedMessages && processedMessages.length > 0) {
            addDelayToMessages(processedMessages);
        }

        setLoader(false);
    }

    const processDFQueryResponse = (messages: MessageModel[]) => {

        let overrideResponse: string = "";
        let acceptanceRateTriggered: boolean = false;
        let productAvailFollowUpTriggered: boolean = false;
        let orderTrackingFollowUpTriggered: boolean = false;
        const numberOfFAQArticles = messages.filter(m => m.type === MessageTypes.Article || m.type === MessageTypes.ArticleList)?.length;

        const numberOfResponses = messages.filter(a => a.type === MessageTypes.Message);


        for (let z = messages.length - 1; z >= 0; z--) {
            if (messages[z].type === MessageTypes.Message) {
                // if there is an override response, use that and override the response 
                messages[z].text = (overrideResponse !== '') ? overrideResponse : messages[z].text;
                overrideResponse = "";

                if (!acceptanceRateTriggered && messages[z].text.indexOf('Are you looking for a product') >= 0) {
                    acceptanceRateTriggered = true;
                    const params: any = {
                        acceptanceIntentName: 'PRODUCT AVAILABILITY-general-lookup'
                    }

                    initAcceptanceRate(params);
                    continue;
                }

                if (!acceptanceRateTriggered && messages[z].text.indexOf('The product you’re looking for is not available anywhere') >= 0) {
                    acceptanceRateTriggered = true;
                    const params: any = {
                        acceptanceIntentName: getIntentName(messages[z].type)
                    }

                    let timeout = acceptanceIntent.NEARBY_STORES_TIMEOUT + (numberOfResponses.length * 2000);

                    initAcceptanceRate(params, timeout);
                    continue;
                }

                continue;
            }


            if (messages[z].type === MessageTypes.Command) {
                // execute command triggers
                executeCommand(messages[z].data?.commandName);

                // remove item from array after command is executed
                messages.splice(z, 1);
                continue;
            }

            if (messages[z].type === MessageTypes.LiveChatMessage) {
                // remove item from array since message is just the command message
                messages.splice(z, 1);
                continue;
            }

            // save the session id and delete from message response
            if (messages[z].type === MessageTypes.SessionId) {
                const userSessionId = messages[z].text;
                sessionId.current = userSessionId;
                globalThis.userSessionId = userSessionId;
                messages.splice(z, 1);
                continue;
            }

            // save the intent group id and delete from message response
            if (messages[z].type === MessageTypes.QueryId) {
                queryId.current = messages[z].text;
                messages.splice(z, 1);
                continue;
            }

            // save the intent group id and delete from message response
            if (messages[z].type === MessageTypes.IntentGroupId) {
                intentGroupId.current = messages[z].text;
                messages.splice(z, 1);
                continue;
            }

            // save the intent group name and delete from message response
            if (messages[z].type === MessageTypes.IntentGroupName) {
                intentGroupName.current = messages[z].text;
                messages.splice(z, 1);
                continue;
            }

            // save the intent previous intent name and delete from message response
            if (messages[z].type === MessageTypes.IntentName) {
                intentName.current = messages[z].text;
                messages.splice(z, 1);
                continue;
            }

            // save the last order id checked if present
            if (messages[z].type === MessageTypes.LastOrderChecked) {
                if (messages[z].data !== null) {
                    setCurrentOrderId(messages[z].data);
                }
                messages.splice(z, 1);
                continue;
            }

            // save the last order id checked if present
            if (messages[z].type === MessageTypes.FollowUpEventData && messages[z].data !== null) {

                if (!acceptanceRateTriggered) {
                    acceptanceRateTriggered = true;

                    let eventData: FollowUpEventDataModel = messages[z].data;
                    const params: any = {
                        shopperId: shopperId.current,
                        widgetSessionId: widgetSessionId
                    }

                    switch (eventData.followUpEventType) {
                        case FollowUpEventTypes.SDDFAQAcceptance:
                            dispatch(updateLastFollowUpEventName(eventData.followUpEventName));
                            initDynamicFollowup(params, acceptanceIntent.SDD_ARTICLE_TIMEOUT + (numberOfResponses.length * 2000), eventData.followUpEventName);
                            break;
                        default:
                            dispatch(updateLastFollowUpEventName(''));
                            initDynamicFollowup(params, acceptanceIntent.DEFAULT_TIMEOUT + (numberOfResponses.length * 2000), '');
                            break;
                    }
                }

                // after processing, remove it from the messages
                messages.splice(z, 1);
                continue;
            }

            // we need to manually remove live chat option because
            // we're stuck with using the sdk on the UI side
            if (messages[z].type === MessageTypes.ConditionalQr || (messages[z].type === MessageTypes.Form
                && (messages[z].text.toLowerCase() === 'send us a message' || messages[z].text.toLowerCase() === 'leave us a message'))) {
                // block QR if not allowed
                if (!isQRAllowed(messages[z].text)) {
                    // override message to follow response from conditional QR
                    overrideResponse = (messages[z].unavailable_response) ? messages[z].unavailable_response : "";

                    // remove message since QR is not allowed
                    messages.splice(z, 1);
                    continue;
                }

            }

            if (messages[z].type === MessageTypes.QuickReply) {
                messages[z]?.text?.forEach((m: MessageModel) => {
                    if (m.type === MessageTypes.ConditionalQr || m.type === MessageTypes.Form)
                        setLiveChatHoursSubtext(m);
                });
            }

            if (messages[z].type === MessageTypes.QuickReplyPayload || messages[z].type === MessageTypes.ArticlePayload) {
                messages[z]?.data?.forEach((m: QrPayloadModel) => {
                    if (m.type === MessageTypes.ConditionalQr || m.type === MessageTypes.Form)
                        setLiveChatHoursSubtextQr(m);
                });
            }


            if (messages[z].type === MessageTypes.Stores || messages[z].type === MessageTypes.OrderTracking
                || messages[z].type === MessageTypes.ProductNearbyStore || messages[z].type === MessageTypes.Url || messages[z].type === MessageTypes.ResendTaxInvoice) {
                const params: any = {
                    acceptanceIntentName: getIntentName(messages[z].type)
                }

                if (!acceptanceRateTriggered) {
                    acceptanceRateTriggered = true;
                    let timeout = acceptanceIntent.DEFAULT_TIMEOUT + (numberOfResponses.length * 2000);

                    initAcceptanceRate(params, timeout);
                    continue;
                }
            }

            if (messages[z].type === MessageTypes.PickupOrderDetail) {
                setIsPickupOrderTriggeredByKeyword(true);
                dispatch(setClickedPickupOrder(messages[z].data));
                dispatch(setShowNomineeChangeScreen(true));

                return;
            }

            if (messages[z].type === MessageTypes.Article || messages[z].type === MessageTypes.ArticleList || messages[z].type === MessageTypes.ArticlePayload) {
                const params: any = {
                    acceptanceIntentName: getIntentName(messages[z].type)
                }

                if (!acceptanceRateTriggered) {
                    acceptanceRateTriggered = true;
                    const timeOut = (numberOfFAQArticles * 1000) + acceptanceIntent.ARTICLES_FOLLOWUP_DEFAULT_TIMEOUT;
                    initAcceptanceRate(params, timeOut);
                    continue;
                }
            }

            if (messages[z].type === MessageTypes.Product) {
                if (!productAvailFollowUpTriggered) {
                    productAvailFollowUpTriggered = true;
                    let timeout = acceptanceIntent.DEFAULT_TIMEOUT + (numberOfResponses.length * 2000);

                    initProductAvailFollowup(null, timeout);
                    continue;
                }
            }

            if (messages[z].type === MessageTypes.VerifiedOrders) {

                if (messages[z].data && messages[z]?.data[0]?.orderType === "Pickup") {
                    continue;
                }
                if (!orderTrackingFollowUpTriggered) {
                    orderTrackingFollowUpTriggered = true;

                    let timeout = acceptanceIntent.ORDERTRACKING_AUTH_TIMEOUT;

                    let acceptanceName = (messages[z].acceptanceEventName !== null) ? messages[z].acceptanceEventName : "";

                    const params: any = {
                        shopperId: shopperId.current,
                        widgetSessionId: widgetSessionId
                    }

                    initOrderTrackingFollowup(params, timeout, acceptanceName);
                    continue;
                }
            }
        }

        return messages;
    }

    const addDelayToMessages = (processedMessages: any) => {
        let messageCounter: number = 0;
        let splitMessages: MessageModel[] = []
        let messageGroup: any = [];

        processedMessages.forEach((elem: MessageModel) => {
            if (elem.type === MessageTypes.Message) {
                messageCounter += 1;
            }

            if (messageCounter > 1) {
                messageGroup.push(splitMessages);
                splitMessages = [];
                splitMessages.push(elem);
            } else {
                splitMessages.push(elem);
            }
        });

        // push the last group 
        messageGroup.push(splitMessages);

        messageGroup.forEach((group: any, index: number) => {
            let timeout = 1;

            if (index !== 0) {
                timeout = 2000;
            }

            setTimeout(() => {
                setMessages((messages: []) => [...messages, ...handleMessageNewRoutine(group)]);
            }, timeout * index);
        });
    }

    const setLiveChatHoursSubtext = (message: MessageModel) => {
        const messageText = message.text.toLowerCase();
        if (messageText === "live chat" || messageText == "send us a message" || messageText === "leave us a message") { message.subtext = `Live chat operating hours (${getDST()}): ${liveChatHours.current[0]} - ${liveChatHours.current[1]}` }
    }

    const setLiveChatHoursSubtextQr = (message: QrPayloadModel) => {
        const messageText = message.text.toLowerCase();
        if (messageText === "live chat" || messageText === "send us a message" || messageText === "leave us a message") { message.subtext = `Live chat operating hours (${getDST()}): ${liveChatHours.current[0]} - ${liveChatHours.current[1]}` }
    }

    const getIntentName = (messageType: string) => {
        let acceptanceIntentName: string = "";
        switch (messageType) {
            case MessageTypes.Stores:
                acceptanceIntentName = "STORE LOCATOR-address";
                break;
            case MessageTypes.OrderTracking:
                acceptanceIntentName = "ORDER TRACKING-delivery-type";
                break;
            case MessageTypes.Article:
            case MessageTypes.ArticleList:
                acceptanceIntentName = "FAQ-Default-Intent";
                break;
            case MessageTypes.Product:
                acceptanceIntentName = "PRODUCT AVAILABILITY-general-lookup";
                break;
            case MessageTypes.ResendTaxInvoice:
                acceptanceIntentName = "Resend Tax Invoice-my-order";
                break;
            default:
                acceptanceIntentName = "Unknown Message Type";
                break;
        }

        return acceptanceIntentName;
    }

    // check if QR is currently allowed in curent chat state
    const isQRAllowed = (qrText: string): boolean => {
        let isAllowed: boolean = false;

        // other future conditional QRs can be expanded here
        switch (qrText.toLowerCase()) {
            case "live chat":
                isAllowed = isLiveChatAvailable();
                break;
            case "leave us a message":
            case "send us a message":
                isAllowed = !isLiveChatAvailable();
                break;
            default:
                break;
        }

        return isAllowed;
    }

    const executeCommand = (commandText: string) => {
        switch (commandText) {
            case "liveChatInit":
                initLiveChat(false);

                // set cookie session variable to keep track that live chat is on
                // even on page refresh
                // TODO : Set cookie
                break;
            case "endBotSession":
                setTimeoutType(3);
                setisOverlayScreenMounted(true)
                setShowManualEndSessionScreen(true);

                // set cookie session variable to keep track that live chat is on
                // even on page refresh
                // TODO : Set cookie
                break;
            case "openFAWForm":
                chooseWinePreference(fawStoreName, fawStreetNumber, intentGroupId.current, "FindAWine", 9);
                break;
            case "openNomineeChangeForm":
                dispatch(setShowNomineeChangeScreen(true));
                break;
            default:
                break;
        }
    }

    const initLiveChat = function (bypassUserForm: boolean = true) {
        // check if agents or chat is available before initiating the chat.
        // this part should be moved to chatserver when possible
        // check live chat availability again
        if (isLiveChatAvailable()) {
            // clear bot timeout
            clearTimeout(globalBotTimeout);

            // show live chat 
            isLiveChatOn.current = true;

            if (bypassUserForm === false) {
                // clear name and email first
                //setName('');
                //setEmail('');

                if (isUserLoggedOn.current) {
                    setShowUserForm(false);
                    setliveChatTransfer(true)
                }
                else {
                    setShowUserForm(true);
                    setliveChatTransfer(false)
                }
                showUserInput(false);

            } else {
                // send the visitor info immdiately from variable
                const visitorInfo = getCookie("visitorInfo");

                zChat.setVisitorInfo(visitorInfo, function () { });

                // send history of messages as first message
                const messageHistory = getlocalStorage("messageHistory");
                const liveChatHistory = selectLiveChatMessages(getlocalStorage("allMessageHistory"));
                const combinedMessages = `${messageHistory}\n\n${liveChatHistory}`;
                sendLiveChatMessage(combinedMessages);
            }

            setArticleData({});
            setShowArticle(false);
            setShowTicketForm(false);
            setShowThankYou(false);

            setLiveChatRating('');
            setLiveChatComment('');

            /* disabled for now
            intervalFunction = setInterval(() => {
                if (queuePosition.current > 0) {
                    setMessages((liveChatMessages: MessageModel[]) => 
                    [...liveChatMessages, 
                    ...[{ 
                            text: `It shouldn’t be too long now. If you don’t want to wait you can send us a message and we’ll get back to you as soon as we can.`,
                            from: Speaker.Murphy, 
                            type: MessageTypes.LiveChatMessage 
                        },
                        { 
                            text: "Send us a message", 
                            from: Speaker.Agent, 
                            type: MessageTypes.LiveChatQr
                        }
                        ]
                    ]);
                }                 
            }, livechat.LIVECHAT_TIMEOUT);*/
        } else {
            // display status to user
            setMessages((messages: any) => [...messages, ...[{ text: "Live Chat is currently unavailable.  Please check on of our other contact options.", from: Speaker.Agent, type: MessageTypes.Message }]]);
            dialogflowEventQueryAsync(dialogflowEvent.LIVECHAT_GETINTOUCH);
        }
    }

    const addToLiveChatMessages = (text: string, from: string, options?: string[]) => {
        setMessages((liveChatMessages: any) =>
            [...liveChatMessages,
            ...[{
                text: text,
                from: from,
                type: MessageTypes.LiveChatMessage
            }]
            ]);

        if (options) {
            options.forEach(opt => {
                setMessages((liveChatMessages: MessageModel[]) =>
                    [...liveChatMessages,
                    ...[{
                        text: opt,
                        from: from,
                        type: MessageTypes.Qr,
                        hasQr: true
                    }]
                    ]);
            });
        }

        if (!showWidget) {
            setUnreadMessageCount(unreadMessageCount => unreadMessageCount + 1);
        }
    };

    const addToStateArray = (name: string) => {
        var array = Array.from(agentNames); // make a separate copy of the array
        array.unshift(name);
        return array;
    }

    const ratingWindowDisplayDecision = () => {
        // wait 1.5 seconds and if no agent comes in, end the chat
        // check again if there are no agents left
        // show the rating request page 
        if (agentChatCount.current <= 0) {
            setLiveChatRating('');
            setLiveChatComment('');
            setShowLiveChatRating(true);
        }
    }

    const removeFromStateArray = (name: string) => {
        var array = Array.from(agentNames); // make a separate copy of the array

        var index = array.indexOf(name);
        if (index !== -1) {
            array.splice(index, 1);
        }

        return array;
    }

    const getLcQueueMessage = (queuePosition: number) => {
        const messages: string[][] = [];
        let queueWaitingMessage: string[] = [];
        let randomIndex: number;

        switch (true) {
            case (queuePosition >= 50): //eg: Queue position 50 and above
                if (isMobileOS()) {
                    messages.push(
                        [`Hey ${name}, it's going to be a bit of a wait. Live chat is popular right now.`,
                            `You can navigate away from this page, but keep it open in the background. Please check back from time to time to see your progress in the queue.`],
                        [`Woah, what a queue! Sorry for the long wait, ${name}.`,
                            `Please keep this page open when you navigate to a different one and check back regularly to see your progress in line.`]);
                } else {
                    messages.push(
                        [`Hey ${name} it is going to be bit of wait . Live chat is popular right now .`,
                            `You can navigate away from this page, but keep it open in the background. You’ll get an audio notification once you have reached 3rd in place in the queue..`
                        ],
                        [`Hey ${name} it is going to be bit of wait . Live chat is popular right now .`,
                            `Please keep this page open when you navigate to a different one and keep an 'ear' out for an audio notification.`
                        ]
                    )
                }
                randomIndex = Math.round(Math.random() * messages.length);
                queueWaitingMessage = messages[randomIndex];
                break;
            case (queuePosition <= 49 && queuePosition >= 10)://eg: Queue position between 49 and 10
                if (isMobileOS()) {
                    messages.push(
                        [`You can navigate away from this page, but keep it open in the background. Please check back from time to time to see your progress in the queue.`],
                        [`Please keep this page open when you navigate to a different one and check back regularly to see your progress in line.`])
                } else {
                    messages.push(
                        [`You can navigate away from this page, but keep it open in the background. You'll get an audio notification once you reach 3rd place in the queue.`],
                        [`Please keep this page open when you navigate to a different one and keep an 'ear' out for an audio notification. I'll let you know once it's almost your turn.`])
                }
                randomIndex = Math.round(Math.random() * messages.length);
                queueWaitingMessage = messages[randomIndex];
                break;
            case queuePosition >= 5 && queuePosition <= 9: //eg: Queue position between 5 and 9
                queueWaitingMessage = [`Thank you for your patience. It shouldn't be too long now.`];
                break;
            default:
                queueWaitingMessage = [];
        }

        setLCQueueMessage(queueWaitingMessage);
    }

    const liveChatEvents = (data: any) => {
        switch (data.type) {
            case 'chat.msg':
                if (data.msg.indexOf('==========================================') !== 0) {
                    const speaker = (data.nick === "agent:trigger") ? Speaker.Murphy : (data.nick.indexOf('visitor') >= 0) ? Speaker.User : Speaker.Agent;
                    addToLiveChatMessages(data.msg, speaker, data.options);
                }
                break;
            case 'chat.memberleave':
                if (data.nick.includes("agent")) {
                    let agent_name: string = data.display_name.split(' ')[0];

                    addToLiveChatMessages(`${agent_name} has left the chat`, Speaker.System);

                    setAgentNames(removeFromStateArray(agent_name));

                    agentChatCount.current = parseInt(agentChatCount.current) - 1;

                    // trigger this countdown only if there are no more agents left
                    if (agentChatCount.current <= 0) {
                        intervalFunction = setTimeout(ratingWindowDisplayDecision, livechat.ENDCHAT_TIMEOUT);
                    }
                }
                break;
            case 'chat.memberjoin':
                if (intentGroupId.current !== '' && data.nick.startsWith("agent:")) {
                    // intentGroupId is empty when we refresh the page.
                    // if an agent joins the conversation
                    // update analytics
                    updateLiveChatStatus(sessionId.current, chatbotUserId.current, intentGroupId.current, true, intentGroupName.current);
                    // then renew the intent group
                    intentGroupPendingRenew.current = true;
                }
                break;
            case 'chat.request.rating':
                setShowLiveChatRating(true);
                break;
            case 'typing':
                setLoader(data.typing);
                break;
            case 'chat.queue_position':
                if (queuePosition.current !== data.queue_position && data.queue_position !== 0) {
                    addToLiveChatMessages(`You are now no ${data.queue_position} in the queue.`, Speaker.System);
                }

                if (!queuePosition.current) {
                    dispatch(updateLCUserInitalPosition(data.queue_position));
                }

                dispatch(updateLCUserPosition(data.queue_position));

                //Resetting back the triggered message for another group
                if (data.queue_position === 49 || data.queue_position === 9) {
                    dispatch(isCustomQueueMessageTriggered(false));
                }

                queuePosition.current = data.queue_position;
                break;
            default:
                break;
        }
    }

    const closeUserForm = () => {
        setShowUserForm(false);
        setliveChatTransfer(false);

        let visitorInfo = {
            display_name: name,
            email: email
        };

        // set user information
        zChat.setVisitorInfo(visitorInfo, function (err: any) {
            if (err) {
                console.log(`Unable to send name and email to agent.`);
            }

            // set name and email to cookie
            setCookie("visitorInfo", visitorInfo);

            showUserInput(true);


            let data: any = {};

            if (currentOrderId !== null && currentOrderId !== '')
                data['orderNumber'] = currentOrderId;

            if (shopperId.current > 0) {
                data['shopperId'] = shopperId.current;
                data['isLoggedIn'] = true;
            }

            // send history of messages as first message
            let initialLCMessagesForAgent = selectUserMessages(messages, data);
            initialLCMessagesForAgent = initialLCMessagesForAgent || 'User:  Live Chat'; //In case of opening live chat directly
            sendLiveChatMessage(initialLCMessagesForAgent);

            // set history to storage
            setlocalStorage("messageHistory", initialLCMessagesForAgent);
        });
    }

    const getCookie = (cookieName: string) => {
        if (cookies.get(cookieName) === undefined) {
            return null
        } else {
            return cookies.get(cookieName);
        }
    }

    const setlocalStorage = (storageName: string, storageObj: any) => {
        //var encrypted = CryptoJS.AES.encrypt(storageObj, "Avowed96Fairs66Wry");

        //const storageString = JSON.stringify(storageObj);
        //window.localStorage.setItem(storageName, encrypted);
    }

    const getlocalStorage = (storageName: string) => {
        var items = null; //window.localStorage.getItem(storageName);
        if (items === null) return null;

        return JSON.parse(items);
    }

    const setCookie = (cookieName: string, cookieObj: any) => {
        const cookieString = JSON.stringify(cookieObj);

        if (window.location.hostname === "localhost") {
            cookies.set(cookieName, cookieString, {
                domain: 'localhost'
            });
        } else {
            cookies.set(cookieName, cookieString, {
                path: '/',
                domain: '.windows.net',
                sameSite: 'none',
                secure: true,
                maxAge: 31536000
            });
        }
    }


    const isSafeInput = (trimmedInput: any) => {
        if (!trimmedInput || trimmedInput === '') {
            return false;
        }
        if ((trimmedInput.length > 256 || trimmedInput.length < 1) && isLiveChatOn.current === false) {
            setMessages((messages: any) => [...messages, ...[{ text: 'Sorry, I didn\'t quite get that - can you make your question shorter for me? I can only accept up to 256 characters', from: Speaker.System, type: MessageTypes.Message }]]);
            return false;
        }

        var regex = new RegExp(config.ALLOWED_CHARACTERS, 'i');
        if (!trimmedInput.match(regex)) {
            setMessages((messages: any) => [...messages, ...[{ text: 'Sorry, I can\'t receive your message as it contains special characters such as ; ><{}[]|^. Please remove them and try again.', from: Speaker.Murphy, type: MessageTypes.Message }]]);
            return false;
        }
        return true;
    }


    const sendMessage = (event: any) => {
        event.preventDefault();
        let divTextBox = event.currentTarget.ownerDocument.getElementById('divTextBox');

        // if the user is chatting with an agent, send message to livechat
        // if not, send to dialogflow
        const trimmedInput = divTextBox.innerText.trim();

        if (isLiveChatOn.current === true) {
            addToLiveChatMessages(trimmedInput, Speaker.User);
            sendLiveChatMessage(trimmedInput);
            setLoader(false);
        } else {
            // check validity of input
            if (!isSafeInput(trimmedInput)) {
                return false;
            }

            setMessages((messages: any) => [...messages, ...[{ text: trimmedInput, from: Speaker.User, type: MessageTypes.Message }]]);
            dialogflowTextQueryAsync(trimmedInput);
            setLoader(true);
        }

        setInput('');
        divTextBox.innerText = '';
        if (divFooterContainer != null) {
            divFooterContainer.style.height = '72px';
        }
    }

    const chooseWinePreference = (storeName: string, streetNumber: string, intentGroupId: string, intentGroupName: string, intentGroupTypeId: number) => {
        fawProps = {
            storeName: storeName,
            streetNumber: streetNumber,
            resumeGlobalTimeout: () => resumeGlobalTimeout(),
            quickReplyHandler: (event: any, queryType: DialogflowQueryType, eventText: string, showText: boolean) => quickReplyHandler(event, queryType, eventText, showText),
            sessionId: sessionId.current,
            userId: chatbotUserId.current,
            intentGroupId: intentGroupId,
            intentGroupName: intentGroupName,
            intentGroupTypeId: intentGroupTypeId
        }
        dispatch(showFawPage(true));
    }

    const handleAnalyticsBot = (linkName: string, linkType: string, methodHandler: string) => {
        const objCall = {
            data: {
                linkName: linkName,
                linkType: linkType,
            },
            methodHandler: methodHandler
        };
        postMessageToClient(objCall);
    }

    const quickReplyHandler = (event: any, queryType: DialogflowQueryType = DialogflowQueryType.Text, eventText: string = "", showText: boolean = true, params: any = null, renew: boolean = false, intentGroupInfo: any = null) => {
        event.preventDefault();
        event.stopPropagation();
        // show timeout screen after 10 mins
        resumeGlobalTimeout();

        if (intentGroupInfo !== null) {
            intentGroupName.current = intentGroupInfo.intentGroupName;
            intentGroupId.current = intentGroupInfo.intentGroupId;
        }

        let text = event.currentTarget.textContent;
        if (event.currentTarget.className !== 'vote__button') {
            if (text.toLowerCase() === 'store stock availability') {

                handleAnalyticsBot('web:chatbot:linkname:store stock availability', 'chatbot product availability', 'chatbotTriggerAdobeAnalytics');

            } else if (text.toLowerCase() === 'find another product') {

                handleAnalyticsBot('web:chatbot:product availability:follow up:find another product', 'chatbot product availability', 'chatbotTriggerAdobeAnalytics');

            } else if (text.toLowerCase() === 'find nearby' && eventText === dialogflowEvent.PRODUCT_AVAIL_FIND_NEARBY_STORES) {

                handleAnalyticsBot(`web:chatbot:product availability:find nearby:${params.productData.productName}`, 'chatbot product availability find nearby', 'chatbotTriggerAdobeAnalytics');

            } else if (text.toLowerCase() === "yes, i'm done") {

                handleAnalyticsBot('web:chatbot:product availability:follow up:yes I am done', 'chatbot product availability', 'chatbotTriggerAdobeAnalytics');

            } else if (text.toLowerCase() === 'change store') {

                handleAnalyticsBot('web:chatbot:product availability:follow up:change store', 'chatbot product availability', 'chatbotTriggerAdobeAnalytics');

            } else if ((text.toLowerCase() === 'select store') && (eventText === 'PRODUCT_AVAIL_SET_STORE')) {

                handleAnalyticsBot('web:chatbot:product availability:select store', 'chatbot product availability', 'chatbotTriggerAdobeAnalytics');

            } else if (text.toLowerCase() === 'help me choose a wine') {

                handleAnalyticsBot('web:chatbot:linkname:find a wine', 'chatbot find a wine', 'chatbotTriggerAdobeAnalytics');

            } else if (text.toLowerCase() === 'yes, thanks' && eventText === dialogflowEvent.Chatbot_FAW_Followup_Questions) {

                handleAnalyticsBot('web:chatbot:find a wine:did i help you choose a wine:yes thanks', 'chatbot find a wine', 'chatbotTriggerAdobeAnalytics');

            } else if (text.toLowerCase() === 'no' && eventText === dialogflowEvent.Chatbot_FAW_Followup_Questions) {

                handleAnalyticsBot('web:chatbot:find a wine:did i help you choose a wine:no', 'chatbot find a wine', 'chatbotTriggerAdobeAnalytics');

            } else if (text.toLowerCase() === 'yes, lets try again' && eventText === dialogflowEvent.Chatbot_FAW_Try_Again) {

                handleAnalyticsBot('web:chatbot:find a wine:would you like to try again:yes', 'chatbot find a wine', 'chatbotTriggerAdobeAnalytics');

            } else if (text.toLowerCase() === 'no' && eventText === dialogflowEvent.Chatbot_FAW_Try_Again) {

                handleAnalyticsBot('web:chatbot:find a wine:would you like to try again:no', 'chatbot find a wine', 'chatbotTriggerAdobeAnalytics');

            } else if (text.toLowerCase() === 'same store' && eventText === dialogflowEvent.Chatbot_FAW_Select_Store_Again) {

                handleAnalyticsBot('web:chatbot:find a wine:try different store:same store', 'chatbot find a wine', 'chatbotTriggerAdobeAnalytics');

            } else if (text.toLowerCase() === 'choose a different store' && eventText === dialogflowEvent.Chatbot_FAW_Select_Store_Again) {

                handleAnalyticsBot('web:chatbot:find a wine:try different store:choose a different store', 'chatbot find a wine', 'chatbotTriggerAdobeAnalytics');

            } else {
                switch (text.toLowerCase()) {
                    case 'subscriptions':
                        handleAnalyticsBot('web:chatbot:linkname:subscription', 'chatbot subscription', 'chatbotTriggerAdobeAnalytics');
                        break;
                    case 'manage subscription':
                        handleAnalyticsBot('web:chatbot:subscription:manage subscription', 'chatbot subscription', 'chatbotTriggerAdobeAnalytics');
                        break;
                    case 'returns and refunds':
                        handleAnalyticsBot('web:chatbot:subscription:returns and refunds', 'chatbot subscription', 'chatbotTriggerAdobeAnalytics');
                        break;
                    case 'how to subscribe':
                        handleAnalyticsBot('web:chatbot:subscription:how to subscribe', 'chatbot subscription', 'chatbotTriggerAdobeAnalytics');
                        break;
                    case 'track subscriptions delivery':
                        handleAnalyticsBot('web:chatbot:subscription:track subscriptions delivery', 'chatbot subscription', 'chatbotTriggerAdobeAnalytics');
                        break;
                    case 'delivery faqs':
                        handleAnalyticsBot('web:chatbot:subscription:delivery faqs', 'chatbot subscription', 'chatbotTriggerAdobeAnalytics');
                        break;
                    case 'skip a subscriptions delivery':
                        handleAnalyticsBot('web:chatbot:subscription:manage subscription:skip a subscriptions delivery', 'chatbot subscription', 'chatbotTriggerAdobeAnalytics');
                        break;
                    case 'cancel subscription':
                        handleAnalyticsBot('web:chatbot:subscription:manage subscription:cancel subscription', 'chatbot subscription', 'chatbotTriggerAdobeAnalytics');
                        break;
                    case 'update my subscription':
                        handleAnalyticsBot('web:chatbot:subscription:manage subscription:update my subscription', 'chatbot subscription', 'chatbotTriggerAdobeAnalytics');
                        break;
                    case 'update my details':
                        handleAnalyticsBot('web:chatbot:subscription:manage subscription:update my details', 'chatbot subscription', 'chatbotTriggerAdobeAnalytics');
                        break;
                    default:
                        if (eventText !== dialogflowEvent.Chatbot_Additional_Questions && eventText !== dialogflowEvent.Chatbot_FAW_Followup_Questions) {
                            postMessageToClient({ name: "chatbotQRClicked", text: text });
                        }
                        break;
                }
            }
        }

        // if live chat is on. handle Quick Replies differently
        if (isLiveChatOn.current === true) {
            addToLiveChatMessages(text, Speaker.User);
            sendLiveChatMessage(text);

        } else {
            if (showText) {
                setMessages((messages: any) => [...messages, ...[{ text: text, from: Speaker.User, type: MessageTypes.Message }]]);
            }
            setLoader(true);

            if (queryType === DialogflowQueryType.Text) {
                dialogflowTextQueryAsync(text);
            } else {
                dialogflowEventQueryAsync(eventText, params);
            }
        }

        if (renew === true) {
            // set pending renew back to false
            intentGroupPendingRenew.current = true;
        } else {
            intentGroupPendingRenew.current = false;
        }
    }

    const globalBotTimeoutHandler = () => {
        setTimeoutType(2);
        setisOverlayScreenMounted(true);
        setShowManualEndSessionScreen(true);

        clearTimeout(globalBotTimeout);
        globalBotTimeout = setTimeout(() => {
            setShowManualEndSessionScreen(false);
            setAutoEndSessionScreen(true);

            sessionId.current = "";
            globalThis.userSessionId = "";

            autoEndBotSession();
        }, config.AUTOEND_TIMEOUT);

    }

    useEffect(() => {
        if (showOrderTrackingDetail === true) {
            clearTimeout(timeoutAcceptance);
        }

        if (orderDataExpandedTile !== null) {
            setCurrentOrderId(orderDataExpandedTile?.orderId);
        }

    }, [showOrderTrackingDetail, orderDataExpandedTile]);

    useEffect(() => {
        if (clickedPickupOrder !== null) {
            const orderId = orderDataExpandedTile?.orderId;
            const message = { text: `Order #${clickedPickupOrder.orderId}`, from: Speaker.User, type: MessageTypes.Message };

            setCurrentOrderId(orderId);

            if (clickedPickupOrder.status === 'Cancelled') {
                setMessages((messages: any) => [...messages, ...[message]]);
                dialogflowEventQueryAsync(dialogflowEvent.ORDER_TRACKING_MY_ORDERS_PICKUP_CANCELLED);
            } else if (clickedPickupOrder.status === 'Collected' || clickedPickupOrder.status === 'Completed') {
                setMessages((messages: any) => [...messages, ...[message]]);
                dialogflowEventQueryAsync(dialogflowEvent.ORDER_TRACKING_MY_ORDERS_PICKUP_COLLECTED);
            } else if (!isPickupOrderTriggeredByKeyword) {
                setMessages((messages: any) => [...messages, ...[message]]);
                dialogflowEventQueryAsync(dialogflowEvent.ORDER_TRACKING_PICK_UP_ORDER_CLICKED);
            }
        }

    }, [clickedPickupOrder]);


    useEffect(() => {
        if (openLiveChat) {
            toggleWidget();
            setLoader(true);
            dialogflowTextQueryAsync('Live Chat');
        }
    }, [openLiveChat]);

    useEffect(() => {
        if (pdpOpened) {
            toggleWidget();
            dispatch(openPdp(false));
        }
    }, [pdpOpened]);

    useEffect(() => {
        if (liveChatUserQueuePosition !== '') {
            //If the initial position was 10 or more then notify the user on queue position less than or equal to 5
            if (lcUserInitialPosition >= 10 && (liveChatUserQueuePosition <= 5)) {
                const displayText = liveChatUserQueuePosition === 0 ? 'Murphy messaged you. Agent joined.' : `Murphy messaged you. You're ${liveChatUserQueuePosition}${getOrdinalSuffix(liveChatUserQueuePosition)} in line`;
                postMessageToClient({ name: "notifyUser", text: displayText, queuePosition: liveChatUserQueuePosition });
            }

            (liveChatUserQueuePosition !== 0) && getLcQueueMessage(liveChatUserQueuePosition);
        }
    }, [liveChatUserQueuePosition]);


    useEffect(() => {
        if (!customQueueMessageTriggered && lCQueueMessage && lCQueueMessage.length) {
            clearInterval(lCQueueNotificationInterval);
            lCQueueNotificationInterval = setTimeout(() => {
                lCQueueMessage.forEach(queueMessage => {
                    addToLiveChatMessages(queueMessage, Speaker.Murphy);
                })
                dispatch(isCustomQueueMessageTriggered(true));
            }, 6000)
        }
    }, [customQueueMessageTriggered, lCQueueMessage]);

    useEffect(() => {
        if (!isWidgetOpenFirstTime && !showWidget) {
            toggleWidget();
            dispatch(isBotOpenFirstTime(true));
        }

        if (!isWidgetOpenFirstTime && showWidget) {
            dispatch(isBotOpenFirstTime(true)); //Resetting
        }
    }, [isWidgetOpenFirstTime]);

    const openWelcomeMessage = () => {
        // set the autoend session screen to false immediately to prevent delay on the user's click
        setAutoEndSessionScreen(false);

        if (isUserLoggedOn.current === true && encryptedShopperId.current && isChangeAlerted.current === false) {
            callGreetShopper(encryptedShopperId.current, startBotSession);
            setShowWelcomeMessage(false);
        } else {
            setLoader(true);
            clearInterval(startBotSessionTimeout);
            startBotSessionTimeout = setTimeout(() => {
                startBotSession();
            }, 1000);
        }
    }

    const callGreetShopper = (encryptedShopperIdParam: string, callBackFn: any = null) => {
        // call the chatserver greet endpoint
        setLoader(true);
        let tempLiveSessionId = widgetSessionIdLive.current;
        greetLoggedInShopperEncrypted(encryptedShopperIdParam, widgetSessionIdLive.current, sessionId.current, chatbotUserId.current, intentGroupId.current, intentGroupName.current)
            .then((result: any) => {
                // store the new session if present
                const userSessionId = result?.Data?.SessionId;
                if (userSessionId) {
                    sessionId.current = userSessionId;
                    globalThis.userSessionId = userSessionId;
                }

                isChangeAlerted.current = true;
                setLoader(false);

                if (result && result.StatusCode === 200) {
                    dispatch(updateUserFirstName(result.Data.LoggedInFirstName));
                    setEmail(result.Data.LoggedInEmail);
                    setName(result.Data.LoggedInName);
                    updateShopperId(result.Data.ShopperId);
                    shopperId.current = result.Data.ShopperId;
                    encryptedShopperId.current = encryptedShopperIdParam;

                    setMessages((messages: []) => [...messages, ...[
                        {
                            icon: `/images/icon-user-authenticated.svg`,
                            shopper: result.Data.LoggedInName,
                            text: `You're logged in as`,
                            from: Speaker.System,
                            type: MessageTypes.Message
                        }
                    ]]);

                    if (intentName.current === 'ORDER TRACKING-my-orders' && encryptedShopperIdParam) {

                        let textMessage = `Great, thanks for logging in ${result.Data.LoggedInFirstName}!` + (currentText.current === 'Pick up' ? ' Let me find your orders. One moment please...' : '');

                        setMessages((messages: any) => [...messages, ...[{ text: textMessage, from: Speaker.Murphy, type: MessageTypes.Message }]]);
                        if (currentText.current === 'Delivery') {
                            dialogflowEventQueryAsync(dialogflowEvent.ORDER_TRACKING_DELIVERY_TYPE, null, tempLiveSessionId);
                        }
                        else if (currentText.current === 'Pick up') {
                            dialogflowEventQueryAsync(dialogflowEvent.ORDER_TRACKING_MY_ORDERS_PICKUP, null, tempLiveSessionId);
                        }
                    }
                    else if (intentName.current === 'Resend Tax Invoice-my-order' && encryptedShopperIdParam) {
                        let textMessage = `Great, thanks for logging in ${result.Data.LoggedInFirstName}!`;
                        setMessages((messages: any) => [...messages, ...[{ text: textMessage, from: Speaker.Murphy, type: MessageTypes.Message }]]);

                        dialogflowEventQueryAsync(dialogflowEvent.RESENDTAXINVOICE_ASK_ORDER_NUMBER_AFTER_LOGIN, null, tempLiveSessionId);
                    }
                    else if (intentName.current === 'ORDER TRACKING-my-orders-pickup-cancelled' && encryptedShopperIdParam) {

                        dialogflowEventQueryAsync(dialogflowEvent.ORDER_TRACKING_MY_ORDERS_PICKUP_CANCELLED, null, tempLiveSessionId);
                    }
                    else if (intentName.current === 'ORDER TRACKING-my-orders-pickup-collected' && encryptedShopperIdParam) {

                        dialogflowEventQueryAsync(dialogflowEvent.ORDER_TRACKING_MY_ORDERS_PICKUP_COLLECTED, null, tempLiveSessionId);
                    }
                }

                if (callBackFn) {
                    callBackFn();
                }
            });
    }

    const toggleWidget = (toggledByPopup?: boolean) => {
        if (shouldInitWithWelcome && isWidgetOpenFirstTime) {
            setShouldInitWithWelcome(false);
            setShowManualEndSessionScreen(false);
            setAutoEndSessionScreen(false);

            isInitWelcomeShown.current = true;
            if (!openLiveChat) {
                setIsLiveChatInitiated(false)
                openWelcomeMessage();
            }
        } else {
            // we check for the previous value of showWidget before the new value is assigned
            if (isUserLoggedOn.current === true && isChangeAlerted.current === false && !showWidget) {
                callGreetShopper(encryptedShopperId.current);
            }
        }

        // set the unread count to 0 if the user opens or closes the widget
        setUnreadMessageCount(0);

        setShowWidget(!showWidget);
        isWidgetShown.current = !showWidget;

        const objCall = {
            data: { showWidget: !showWidget },
            methodHandler: "toggleWidget"
        };

        postMessageToClient(objCall);

        if (!showWidget) {
            //skip call to client for analytics in case bot opened by popup click
            if (!toggledByPopup) {
                postMessageToClient({ name: "chatbotOpened" });
            }
        }
    }

    const closeButton = () => {
        setShowverigyAge(false);
        if (showSurvey === true) {
            // minimize widget
            setShowWidget(false);
            isWidgetShown.current = false;

            const objCall = {
                data: { showWidget: false },
                methodHandler: "toggleWidget"
            };

            postMessageToClient(objCall);
            setShowSurvey(false);

        }


        if (!showManualEndSessionScreen) {
            setTimeoutType(1);
            setisOverlayScreenMounted(true);
            setShowManualEndSessionScreen(true);
        } else {
            endBotSession(true);
        }

        if (autoEndSessionScreen) {
            // just minimize the widget if the session has already ended
            setShowWidget(false);
            isWidgetShown.current = false;
            isChangeAlerted.current = false;

            const objCall = {
                data: { showWidget: false },
                methodHandler: "toggleWidget"
            };

            postMessageToClient(objCall);
        }
    }

    const backToWidget = (hasVoted: boolean = false, isInvokedBy?: string, event?: any) => {
        if (showNomineeChangeScreen && isInvokedBy === undefined && !isNomineeUpdated) {
            dialogflowEventQueryAsync(dialogflowEvent.ORDER_TRACKING_MY_ORDERS_PICKUP_CHANGE_NOMINEE_CANCEL)
        }
        else if (showNomineeChangeScreen && isInvokedBy === undefined && isNomineeUpdated) {
            dialogflowEventQueryAsync(dialogflowEvent.ORDER_TRACKING_PICK_UP_NOMINEE_UPDATED)
        }
        resumeGlobalTimeout();

        setArticleData({});
        setShowArticle(false);
        setIsArticleFound(null);
        setShowTicketForm(false);
        setShowThankYou(false);

        isLiveChatOn.current = false;
        setShowInputTextBox(true);
        setShowUserForm(false);
        setliveChatTransfer(false);

        dispatch(setShowOrderTrackingDetail(false));
        dispatch(setShowNomineeChangeScreen(false));
        dispatch(setShowNomineeChangeFailedScreen(false));
        dispatch(setOrderForTile(null));
        dispatch(setClickedPickupOrder(null));

        if (hasVoted !== true && !acceptIntentTriggeredOnQueryResp) {
            if (lastFollowUpEventName !== null && lastFollowUpEventName !== '') {
                const params: any = {
                    acceptanceIntentName: intentName.current
                }

                initDynamicFollowup(params, acceptanceIntent.DEFAULT_TIMEOUT, lastFollowUpEventName);
            } else {
                const params: any = {
                    acceptanceIntentName: 'FAQ-Default-Intent'
                }
                if (!showNomineeChangeScreen) {
                initAcceptanceRate(params);
            }
        }
        }

        if (openLiveChat) {
            openWelcomeMessage();
            setOpenLiveChat(false);
        }
    }

    const resumeGlobalTimeout = () => {
        if (globalBotTimeout) clearTimeout(globalBotTimeout);
        globalBotTimeout = setTimeout(globalBotTimeoutHandler, config.IDLE_TIMEOUT);
    }

    //Bot session dialog flow pings

    const keepSessionAlive = () => {
        if (!globalBotSessionAlive) {
            globalBotSessionAlive = setInterval(dialogFlowPing, config.SESSION_ALIVE_PING);
        }
    }

    const clearBotSession = () => {
        clearInterval(globalBotSessionAlive);
        globalBotSessionAlive = '';
    }

    const clearUserDetails = () => {
        setEmail("");
        setName("");
        dispatch(updateUserFirstName(""));
    }

    const initAcceptanceRate = (params: any = null, timeout: number | null = null) => {
    if(intentName.current !== resendTaxInvoiceAcceptIntent.RESEND_TAX_INVOICE_CANCEL_ORDER 
        && intentName.current !== resendTaxInvoiceAcceptIntent.RESEND_TAX_INVOICE_MY_ORDER)
        {
            setAcceptIntentTriggeredOnQueryResp(false);
            timeout = timeout ?? acceptanceIntent.DEFAULT_TIMEOUT;
            timeoutAcceptance = setTimeout(() => {
                // fire event
                if (params === null) {
                    dialogflowEventQueryAsync(dialogflowEvent.Chatbot_Contact_Options);
                } else {
                    dialogflowEventQueryAsync(dialogflowEvent.Chatbot_Contact_Options, params);
                }
                setAcceptIntentTriggeredOnQueryResp(true);
            }, timeout);
        }
    }

    const initProductAvailFollowup = (params: any = null, timeout: number | null = null) => {
        setAcceptIntentTriggeredOnQueryResp(false);
        timeout = timeout ?? acceptanceIntent.DEFAULT_TIMEOUT;
        timeoutAcceptance = setTimeout(() => {
            // fire event
            if (params === null) {
                dialogflowEventQueryAsync(dialogflowEvent.PRODUCTAVAIL_FOLLOWUP);
            } else {
                dialogflowEventQueryAsync(dialogflowEvent.PRODUCTAVAIL_FOLLOWUP, params);
            }
            setAcceptIntentTriggeredOnQueryResp(true);
        }, timeout);
    }

    const initOrderTrackingFollowup = (params: any = null, timeout: number | null = null, acceptanceName: string = "") => {
        timeout = timeout ?? acceptanceIntent.DEFAULT_TIMEOUT;
        timeoutAcceptance = setTimeout(() => {

            acceptanceName = (acceptanceName !== '') ? acceptanceName : dialogflowEvent.ORDERTRACKING_AUTHENTICATED_FOLLOWUP;
            dialogflowEventQueryAsync(acceptanceName, params);
            setAcceptIntentTriggeredOnQueryResp(true);
        }, timeout);
    }

    const initDynamicFollowup = (params: any = null, timeout: number | null = null, acceptanceName: string = '') => {
        // if acceptanceName is blank or null call default acceptance
        if (acceptanceName == null || acceptanceName === '') {
            initAcceptanceRate(params, timeout);
            return;
        }

        setAcceptIntentTriggeredOnQueryResp(false);
        timeout = timeout ?? acceptanceIntent.DEFAULT_TIMEOUT;
        clearTimeout(timeoutAcceptance);

        timeoutAcceptance = setTimeout(() => {
            // fire event
            if (params === null) {
                dialogflowEventQueryAsync(acceptanceName);
            } else {
                dialogflowEventQueryAsync(acceptanceName, params);
            }
            setAcceptIntentTriggeredOnQueryResp(true);
        }, timeout);
    }

    const showUserInput = (show: boolean) => {
        setShowInputTextBox(show);
    }

    const openArticle = (data: any) => {
        // remove timeout for acceptane rate
        clearTimeout(timeoutAcceptance);
        setShowArticle(true);
        setArticleData(data);

        dispatch(updateLastFollowUpEventName(data?.followUpEventName));
    }

    const onSubmitTicket = () => {
        setShowThankYou(true);
        setShowTicketForm(false);
    }

    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const setRatingValue = (value: number) => {
        switch (value) {
            case 1:
                setLiveChatRating(liveChatRating === 'good' ? '' : 'good')
                break;
            case -1:
                setLiveChatRating(liveChatRating === 'bad' ? '' : 'bad')
                break;
            default:
                break;
        }
    }

    const startBotSession = () => {
        setQRPayloadLoaded(false);
        setAutoEndSessionScreen(false);
        setShowManualEndSessionScreen(false);
        setShowWelcomeMessage(true);
        verifyAge();
        clearTimeout(startBotSessionTimeout);
    }

    useEffect(() => {
        if (showWelcomeMessage) {
            setLoader(true);

            var formedGreetingText = greetingTemplate.current.replace('{0}', (firstName !== '') ? ' ' + firstName : '');

            setMessages((messages: []) => [...messages, ...[{ text: formedGreetingText, from: Speaker.Murphy, type: MessageTypes.Message }]]);
            dialogflowEventQueryAsync(dialogflowEvent.Chatbot_Article_FirstSuggestions);
            setShouldInitWithWelcome(false);
            isInitWelcomeShown.current = true;
        }
    }, [showWelcomeMessage]);

    const verifyAge = () => {
        if (!featureSddPrompt.current) return;

        setMessages((messages: []) => [...messages, ...[
            {
                icon: `/images/Id-verification-alert.svg`,
                text: `Online age check is required for same-day delivery in NSW from the 1st June.`,
                from: Speaker.System,
                type: MessageTypes.Message,
                verifyAge: `true`
            }
        ]]);

    }

    const endBotSession = (value: boolean) => {
        switch (value) {
            case true:
                // end live chat
                endLiveChat("Triggered by end session screen");



                ResetWidgetSettings();

                //clear live chat comments
                setLiveChatRating("");
                setLiveChatComment("");

                setLCReviewDone(false);
                setShowWelcomeMessage(false);

                if (isLiveChatInitiated) {
                    setShowSurvey(false);
                    setShowWidget(false);

                } else {
                    setShowSurvey(true);
                }

                break;
            case false:
                setLCReviewDone(false);
                manualDialogFlowPing();
                break;
            default:
                break;
        }

        setisOverlayScreenMounted(false);
        setTimeout(() => {
            setShowManualEndSessionScreen(false);
          }, 1000);
        setShowverigyAge(false);
        setQRPayloadLoaded(false);

    }

    const clearAnalyticsVariables = () => {
        intentGroupId.current = "";
        intentGroupName.current = "";
        intentGroupPendingRenew.current = false;
        queryId.current = "";
    }

    const autoEndBotSession = () => {
        ResetWidgetSettings();
        setShowverigyAge(false);
    }

    const ResetWidgetSettings = () => {
        setUnreadMessageCount(0);

        // clear all messages 
        setMessages([]);

        setShouldInitWithWelcome(true);

        isInitWelcomeShown.current = false;
        isChangeAlerted.current = false;

        // clear session id
        sessionId.current = "";
        intentName.current = "";
        intentGroupName.current = "";
        currentText.current = "";

        // always create a new sessionId
        refreshWidgetSessionId();

        //Clear bot session interval
        clearBotSession();

        //Clear customer details
        clearUserDetails();

        // close order tracking detail and remove order info set for expanded tile
        dispatch(setShowOrderTrackingDetail(false));
        dispatch(setShowNomineeChangeScreen(false));
        dispatch(setShowNomineeChangeFailedScreen(false));
        dispatch(setOrderForTile(null));
        dispatch(setClickedPickupOrder(null));

        // close the live chat user form
        setShowUserForm(false);
        setliveChatTransfer(false);

        // close the find a wine out put page
        dispatch(showFawPage(false));

        clearAnalyticsVariables();

        setCurrentOrderId('');

        // close FAQ
        setShowArticle(false);
    }

    const postLiveChatAvailabilityToHC = () => {
        const objCall = {
            data: { isLCAvailable: isLiveChatAvailable() },
            methodHandler: "isLCAvailable"
        };

        postMessageToClient(objCall);
    }

    const refreshChatBotUserId = () => {
        chatbotUserId.current = uuid();

        // the new chatbot user id should always be saved to the cookie to prevent any
        // security issues 
        cookies.set(config.COOKIES_CHATBOT_USER_ID_TEXT, chatbotUserId.current, {
            path: '/',
            domain: '.windows.net',
            sameSite: 'none',
            secure: true,
            maxAge: 31536000
        });
    }

    const refreshWidgetSessionId = () => {
        let newId = uuid();
        widgetSessionIdLive.current = newId;
        dispatch(setWidgetSessionId(newId));
    }

    //initial load
    useEffect(() => {
        if (cookies.get(config.COOKIES_CHATBOT_USER_ID_TEXT) === undefined) {
            refreshChatBotUserId();
        } else {
            chatbotUserId.current = cookies.get(config.COOKIES_CHATBOT_USER_ID_TEXT);
        }

        // fetch feature settings
        getFeatureSetting('SddPrompt')
            .then((result: any) => {
                featureSddPrompt.current = (result !== null) ? result.isEnabled : false;
            });

        // fetch banner settings
        getConfigSettings()
            .then((result: { greetingTemplate: string, bannerSettings: BannerInfoDataModel }) => {
                greetingTemplate.current = (result !== null) ? result.greetingTemplate : `Hi{0}, I’m Dan Murphy’s assistant bot. Ask me anything, if I can’t help I’ll connect you to a live chat agent.`;
                dispatch(updateBannerInfo(result.bannerSettings));
            })
            .catch(() => {
                dispatch(updateBannerInfo({ ...notificationBannerInfo, isBannerActive: false }));
            });

        // always create a new sessionId
        refreshWidgetSessionId();

        zChat.on('connection_update', function (event_data: any) {
            if (event_data === "connected") {
                try {
                    zChat.setVisitorDefaultDepartment(parseInt(config.ZENDESK_LIVECHAT_GROUP_ID), () => {
                        postLiveChatAvailabilityToHC();
                    });
                } catch (err) { }

                const isChatting = zChat.isChatting();
                if (isChatting === true) {
                    const agentsInChat = zChat.getServingAgentsInfo();

                    // if the user is currently in live chat disconnect
                    // and start a new session
                    addToLiveChatMessages("Resuming Live Chat.", Speaker.System);

                    setShouldInitWithWelcome(false);
                    isInitWelcomeShown.current = true;

                    // clear bot timeout
                    clearTimeout(globalBotTimeout);

                    // make sure live chat is on when agent is joined in a chat with the user
                    isLiveChatOn.current = true;

                    if (agentsInChat.length > 0 && agentChatCount.current === 0) {
                        agentChatCount.current = parseInt(agentChatCount.current) + 1;
                        const agent_name: string = agentsInChat[agentsInChat.length - 1].display_name.split(' ')[0];

                        setAgentNames(addToStateArray(agent_name));
                        addToLiveChatMessages(`${agent_name} has joined`, Speaker.System);
                    }
                }
            } else {
                handleZendeskConnectionError(event_data);
            }
        });

        zChat.on('visitor_update', function (data: any) {
            setName('');
            setEmail(data.email);
        });

        zChat.on('agent_update', function (data: any) {
            if (data.nick.indexOf('agent') >= 0) {
                agentChatCount.current = parseInt(agentChatCount.current) + 1;
                const agent_name: string = data.display_name.split(' ')[0];
                setLiveChatAgent(agent_name);

                setAgentNames(addToStateArray(agent_name));
                addToLiveChatMessages(`${agent_name} has joined`, Speaker.System);
                setQRPayloadLoaded(true);
            }
        })

        // initiate zendesk widget
        // this is necessary to be able to check availability of zendesk agents 
        if (zChat.getConnectionStatus() === 'closed') {
            zChat.init({
                account_key: config.ZENDESK_CHAT_CONNECTION
            });

            // set live chat events
            zChat.on('chat', liveChatEvents);
        }

        // make sure the live chat overlays and form are down
        setShowUserForm(false);
        setShowLiveChatRating(false);
        setliveChatTransfer(false);

        // this will not be used for the moment 
        // until the time we need to link the user data from 
        window.addEventListener("message", messageHandler, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.localStorage.setItem('messageLength', '0');
        // COMMENTING TILL CACHING IMPLEMENTATION
        //fetchLiveChatHours().then(res => liveChatHours.current = res?.data);

        window.addEventListener("keyup", onChangeHandler, false);
        window.addEventListener("keydown", onChangeHandler, false);

        // if staging.. load more params
        // only allow staging mode on UAT
        if (config.ISSTAGING && window.location.hostname === 'devmurphystorage.danmurphys.com.au') {
            const params = new URL(document.location.toString()).searchParams;
            const shopperIdParam = params.get("shopperId") ?? undefined;

            if (shopperIdParam !== undefined && shopperIdParam !== '')
                // shopper code
                setTimeout(() => {
                    const objCall = {
                        data: {
                            encryptedShopperId: shopperIdParam
                        },
                        methodHandler: 'sendDataToWidget'
                    };
                    window.postMessage(objCall, window.location.origin);
                }, 500);
        }

        // Post onLoad to client.
        postMessageToClient({ name: "chatbotLoaded" });
    }, []);

    const handleZendeskConnectionError = (event_data: any) => {
        switch (event_data) {
            case "connecting": resetLiveChatOnError();
                break;
            // consider connection lost in case of null or closed.
            case null:
            case "closed":
                setLoader(true);
                resetLiveChatOnError();
                setTimeout(() => {
                    setMessages((messages: []) => [...messages, ...[{ text: pickRandomString(zendeskErrorConfig.ERROR_RESPONSE_ARRAY), from: Speaker.Murphy, type: MessageTypes.Message }]]);
                    setLoader(false);
                }, 3000);
                break;
            default: break;
        }
    }


    const resetLiveChatOnError = () => {
        if (isLiveChatOn.current === true) {
            // if the user is currently in live chat disconnect
            // and start a new session
            addToLiveChatMessages("There has been a disruption.  Please check your connection.", Speaker.System);
            agentChatCount.current = 0;
            setAgentNames([livechat.HEADER_TEXT]);
            resumeGlobalTimeout();
            // set the default value of live chat status to false
            isLiveChatOn.current = false;
        }
    }


    const messageHandler = (event: any) => {
        let params = event.data;

        switch (params.methodHandler) {
            case "setIsHostDevice":
                setIsHostDevice(params.data.setIsHostDevice === true)
                break;
            case "changeShopperId":
                isChangeAlerted.current = false;
                if (params.data.shopperId > 0) {
                    // if it is a valid shopper id
                    // and if the new shopper id is not the same as the current one
                    if (shopperId.current !== params.data.shopperId) {
                        // refresh widget session Id
                        refreshWidgetSessionId();

                        updateShopperId(params.data.shopperId);

                        isUserLoggedOn.current = true;
                        isChangeAlerted.current = false;

                        if ((isInitWelcomeShown.current === true || isWidgetShown.current === true) && isChangeAlerted.current === false) {

                            // call the shopper greeting if the wiget has already been opened
                            // for the first time
                            isChangeAlerted.current = true;
                            callGreetShopper(params.data.shopperId);
                        }
                    }

                    // if its the same id, dont do anything
                } else {
                    // if its 0 then log off the user if the user is not currently logged off
                    if (isUserLoggedOn.current === true) {
                        intentGroupPendingRenew.current = true;
                        // refresh widget session Id
                        refreshWidgetSessionId();

                        updateShopperId(0);

                        shopperEncryptedEmail.current = "";

                        isUserLoggedOn.current = false;
                        isChangeAlerted.current = true;

                        dispatch(setShowOrderTrackingDetail(false));
                        dispatch(setShowNomineeChangeScreen(false));
                        dispatch(setShowNomineeChangeFailedScreen(false));
                        dispatch(setOrderForTile(null));
                        dispatch(setClickedPickupOrder(null));

                        setCurrentOrderId('');

                        if (isInitWelcomeShown.current === true || isWidgetShown.current === true) {
                            // call the shopper greeting if the wiget has already been opened
                            // for the first time
                            setMessages((messages: []) => [...messages, ...[{ text: `You're logged off`, from: Speaker.System, type: MessageTypes.Message }]]);
                        }
                    }
                }
                if ((intentName.current === 'ORDER TRACKING-my-orders' || intentName.current === 'ORDER TRACKING-delivery-type' || intentName.current === 'Resend Tax Invoice-my-order') && params.data.shopperId > 0) {
                    if (!showWidget) {
                        setShowWidget(!showWidget);
                        isWidgetShown.current = !showWidget;

                        const objCall = {
                            data: { showWidget: !showWidget },
                            methodHandler: "toggleWidget"
                        };

                        postMessageToClient(objCall);
                    }
                }
                break;
            case "sendDataToWidget":
                isChangeAlerted.current = false;

                if (params.data.encryptedShopperId) {
                    // if it is a valid shopper id
                    // and if the new shopper id is not the same as the current one
                    if (encryptedShopperId.current !== params.data.encryptedShopperId) {
                        // refresh widget session Id
                        refreshWidgetSessionId();
                        encryptedShopperId.current = params.data.encryptedShopperId;
                        
                        if ((isInitWelcomeShown.current === true || isWidgetShown.current === true) && isChangeAlerted.current === false) {
                            callGreetShopper(params.data.encryptedShopperId);

                            // call the shopper greeting if the wiget has already been opened
                            // for the first time
                            isChangeAlerted.current = true;
                        }

                        isUserLoggedOn.current = true;
                        isChangeAlerted.current = false;
                    }

                    // if its the same id, dont do anything
                } else {
                    // if its 0 then log off the user if the user is not currently logged off
                    if (isUserLoggedOn.current === true) {
                        intentGroupPendingRenew.current = true;
                        // refresh widget session Id
                        refreshWidgetSessionId();

                        updateShopperId(0);

                        encryptedShopperId.current = "";
                        shopperEncryptedEmail.current = "";

                        isUserLoggedOn.current = false;
                        isChangeAlerted.current = true;

                        dispatch(setShowOrderTrackingDetail(false));
                        dispatch(setOrderForTile(null));

                        setCurrentOrderId('');

                        if (isInitWelcomeShown.current === true || isWidgetShown.current === true) {
                            // call the shopper greeting if the wiget has already been opened
                            // for the first time
                            setMessages((messages: []) => [...messages, ...[{ text: `You're logged off`, from: Speaker.System, type: MessageTypes.Message }]]);
                        }
                    }
                }
                if ((intentName.current === 'ORDER TRACKING-my-orders' || intentName.current === 'ORDER TRACKING-delivery-type' || intentName.current === 'Resend Tax Invoice-my-order') && params.data.encryptedShopperId) {
                    if (!showWidget) {
                        setShowWidget(!showWidget);
                        isWidgetShown.current = !showWidget;

                        const objCall = {
                            data: { showWidget: !showWidget },
                            methodHandler: "toggleWidget"
                        };

                        postMessageToClient(objCall);
                    }
                }
                break;
            case "openWidget":
                if (!showWidget) {
                    const messageHistory = window.localStorage.getItem("messageLength");
                    if (messageHistory === '0') {
                        toggleWidget();
                    } else {
                        dispatch(isBotOpenFirstTime(false));
                    }
                }
                break;
            case "sendMessage":
                sendMessageFromBackground(params.data.messageString);
                break;
            case "openLiveChatOnBot":
                setOpenLiveChat(true);
                break;
            case "getLiveChatStatusFromBot":
                postLiveChatAvailabilityToHC();
                break;
            default:
                break;
        }
    }

    const onChangeHandler = (event: any) => {
        if (event.target.className === "divTextBox") {
            setInput(event.target.innerText.trim());
            let footerContainer = event.currentTarget.document.getElementById("divFooterContainer");
            if (footerContainer !== null) {
                if (event.target.scrollHeight <= 20) {
                    footerContainer.style.height = '72px';
                } else if (event.target.scrollHeight > 20 && event.target.scrollHeight <= 40) {
                    footerContainer.style.height = '92px';
                } else if (event.target.scrollHeight > 40) {
                    footerContainer.style.height = '112px';
                }

                event.target.style.overflowY = (event.target.scrollHeight >= 80) ? 'scroll' : 'hidden';
            }
        }
    }

    const updateShopperId = (id: number) => {
        dispatch(setShopperId(id));
        shopperId.current = id;
        globalThis.userShopperId = id;
    }

    const sendMessageFromBackground = (messageString: string) => {
        // if the user is chatting with an agent, send message to livechat
        // if not, send to dialogflow
        if (isLiveChatOn.current === true) {
            addToLiveChatMessages(messageString, Speaker.User);
            sendLiveChatMessage(messageString);
            setLoader(false);
        } else {
            setMessages((messages: any) => [...messages, ...[{ text: messageString, from: Speaker.User, type: MessageTypes.Message }]]);
            dialogflowTextQueryAsync(messageString);
            setLoader(true);
        }
    }

    useEffect(scrollToBottom);

    useEffect(() => {
    }, [isHostDevice]);

    useEffect(() => {
        // save conversation to session storage
        setlocalStorage("allMessageHistory", messages);
        window.localStorage.setItem("messageLength", messages.length);
    }, [messages]);

    const closeLiveChatRating = (event: any) => {
        event.preventDefault();

        setShowLiveChatRating(false);
        setIsLiveChatInitiated(true);

        // this sends the Zendesk ratin and additional comments
        endLiveChat("Natural end live chat session");

        setTimeoutType(4);
        setShowManualEndSessionScreen(true);
        setLCReviewDone(true);
    }

    const closeLiveChat = () => {
        if (zChat.isChatting()) {
            zChat.endChat({ clear_dept_id_on_chat_ended: true }, function () {
            });
        }
    }

    const sendLiveChatComment = () => {
        zChat.sendChatComment(liveChatComment, function (err: any) {
            console.log("Live chat comment sent." + err);
            closeLiveChat();
        });
    }

    const sendLiveChatRating = () => {
        zChat.sendChatRating(liveChatRating, function (err: any) {
            console.log("Live chat rating sent." + err);
            // send comment
            if (liveChatComment !== '') {
                sendLiveChatComment();
            } else {
                closeLiveChat();
            }
        });
    }

    const endLiveChat = (endLiveChatReason = "") => {
        // send ratings
        if (liveChatRating !== '') {
            sendLiveChatRating();
        } else if (liveChatComment !== '') {
            sendLiveChatComment();
        } else {
            closeLiveChat();
        }

        setShowLiveChatRating(false);

        // set agent counts to 0
        agentChatCount.current = 0;

        addToLiveChatMessages("Live Chat has ended.", Speaker.System);

        isLiveChatOn.current = false;

        // remove chat related cookies
        cookies.remove("visitorInfo");

        // reset agent name array
        setAgentNames([livechat.HEADER_TEXT]);

        // clear live chat interval markers
        clearInterval(intervalFunction);

        clearInterval(lCQueueNotificationInterval);

        resumeGlobalTimeout();

        // remove additional comments
        dispatch(setAddtlComments(""));
    }

    const onEnterPress = (e: any) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            if (e.target.innerText) {
                sendMessage(e);
            }
        }
    }

    const getMessageParentClassName = (m: MessageModel) => {
        const classNames: string[] = [];
        if (m.from === Speaker.User) {
            classNames.push('widget__message-right');
        } else if (m.from === Speaker.System) {
            classNames.push('widget__message-center');
        } else if (m.type === MessageTypes.ArticlePayload) {
            classNames.push('widget__message-faq');
        } else if (
            m.type !== MessageTypes.Message &&
            m.type !== MessageTypes.OrderTracking &&
            m.type !== MessageTypes.LiveChatMessage &&
            m.type !== MessageTypes.QuickReply &&
            m.type !== MessageTypes.QuickReplyPayload
        ) {
            classNames.push('widget__message-qr');
        } else {
            classNames.push('widget__message-left');
        }
        return classNames.join(' ');
    }

    const isQRPayloadLoaded = (obj: any): boolean => {
        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                if (Array.isArray(obj[key])) {
                    for (let i = 0; i < obj[key].length; i++) {
                        if (isQRPayloadLoaded(obj[key][i])) {
                            if (!qRPayloadLoaded) {
                                setQRPayloadLoaded(true);
                            }
                            return true;
                        }
                    }
                } else {
                    if (obj[key].type === 'qrPayload') {
                        if (!qRPayloadLoaded) {
                            setQRPayloadLoaded(true);
                        }
                        return true;
                    }
                    if (isQRPayloadLoaded(obj[key])) {
                        if (!qRPayloadLoaded) {
                            setQRPayloadLoaded(true);
                        }
                        return true;
                    }
                }
            }
        }
        return false;
    };

    // callback function to update the state of isNomineeUpdated variable
    const updateNomineeState = (updatedValue: boolean) => {
        setIsNomineeUpdated(updatedValue);
    };

    //closing bot after 3s form surveyScreen(voc)
    useEffect(() => {
        let surveyScreenTimeout: ReturnType<typeof setTimeout> | null = null;

        window.addEventListener("MDigital_Submit_Feedback", () => {
            surveyScreenTimeout = setTimeout(() => {
                setShowWidget(false)
                setShowSurvey(false)
            }, 2000);
        }, false);

        return () => {
            if (surveyScreenTimeout) { clearTimeout(surveyScreenTimeout); }
        }

    }, [showSurvey])

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout> | null = null;

        if (isOverlayScreenMounted) {
            setEndScreenMountingStyle(' mounting')
        } else {
            setEndScreenMountingStyle(' unmounting')
        }

        if (!isOverlayScreenMounted && showManualEndSessionScreen) {
            timeoutId = setTimeout(() => {
                setShowManualEndSessionScreen(false)
            }, 400);
        }

        return () => {
            if (timeoutId) { clearTimeout(timeoutId); }
        }
    }, [isOverlayScreenMounted])

    return (
        <div>

            <div className={"widget " + (showWidget && "widget--open ") + (isHostDevice ? "widget--device" : "widget--website")}>

                <div className={"widget__header " + (!isHostDevice && "widget__header--website")}>
                    <Header name={(showManualEndSessionScreen || autoEndSessionScreen) ? config.HEADER_TEXT : showArticle ? config.ARTICLE_TEXT : showTicketForm ? config.TICKET_FORM_HEADER_TEXT : showThankYou ? config.MESSAGE_SENT : (isLiveChatOn.current || liveChatTransfer) ? agentNames[0] : showOrderTrackingDetail ? 'Track delivery' : showNomineeChangeScreen ? 'Update nominee' : config.HEADER_TEXT}
                        toggleWidget={toggleWidget}
                        closeButton={closeButton}
                        backToWidget={showArticle || showTicketForm || showThankYou || showOrderTrackingDetail || showNomineeChangeScreen ? backToWidget : () => { }}
                        isAgentName={agentNames.length > 1}
                    />
                </div>

                {showNotificationBanner && <NotificationBanner />}

                {showUserForm &&
                    <LiveChatUserForm setName={setName}
                        setEmail={setEmail}
                        email={email}
                        name={name}
                        backToWidget={backToWidget}
                        closeUserForm={closeUserForm}
                        isHostDevice={isHostDevice}
                    />
                }
                {liveChatTransfer &&
                    <LiveChatTransfer setName={setName}
                        setEmail={setEmail}
                        email={email}
                        name={name}
                        backToWidget={backToWidget}
                        closeUserForm={closeUserForm}
                        isHostDevice={isHostDevice}
                        showNomineeChangeFailedScreen={showNomineeChangeFailedScreen}
                        setliveChatTransfer={setliveChatTransfer}
                    />
                }

                {showOrderTrackingDetail &&
                    <OrderExpandedTile order={orderDataExpandedTile} quickReplyHandler={quickReplyHandler} backToWidget={backToWidget}
                        sessionId={sessionId.current}
                        userId={chatbotUserId.current}
                        setMessages={setMessages}
                        msgs={messages}
                    />
                }

                {showLiveChatRating &&
                    <LiveChatRating liveChatRating={liveChatRating} liveChatAgent={liveChatAgent} setRatingValue={setRatingValue} closeLiveChatRating={closeLiveChatRating} setLiveChatComment={setLiveChatComment} isHostDevice={isHostDevice} />
                }

                {showManualEndSessionScreen &&
                    <ManualEndSessionScreen endBotSession={endBotSession} timeoutType={timeoutType} endScreenMountingStyle={endScreenMountingStyle} />
                }

                {showNomineeChangeScreen &&
                    <NomineeChangeScreen order={clickedPickupOrder} quickReplyHandler={quickReplyHandler} backToWidget={backToWidget}
                        sessionId={sessionId.current}
                        userId={chatbotUserId.current}
                        setMessages={setMessages}
                        msgs={messages}
                        dialogflowEventQueryAsync={dialogflowEventQueryAsync}
                        updateNomineeState={updateNomineeState} setliveChatTransfer={setliveChatTransfer}
                        showNomineeChangeFailedScreen={showNomineeChangeFailedScreen} />
                }

                {autoEndSessionScreen &&
                    <AutoEndSession openWelcomeMessage={openWelcomeMessage} setShowWelcomeMessage={setShowWelcomeMessage} showWelcomeMessage={showWelcomeMessage} qRPayloadLoaded={qRPayloadLoaded} setQRPayloadLoaded={setQRPayloadLoaded} />
                }

                {showSurvey &&
                    <SurveyScreen setSurveyScreen={setShowSurvey}  setShowWidget={setShowWidget}/>
                }

                {
                    showFAW && <FindAWine {...fawProps} />
                }

                {
                    showArticle &&
                    <div className="widget__body--article">
                        <ArticleDetail sessionId={sessionId.current} userId={chatbotUserId.current} data={articleData} isArticleFound={isArticleFound} setIsArticleFound={setIsArticleFound} />
                    </div>
                }

                {showverigyAge && featureSddPrompt.current &&
                    <Banner setShowverigyAge={setShowverigyAge} endScreenMountingStyle={endScreenMountingStyle} showverigyAge={showverigyAge} setisOverlayScreenMounted={setisOverlayScreenMounted} />
                }

                {!showFAW && !showOrderTrackingDetail && !showNomineeChangeScreen &&
                    <div className={`widget__body ${showThankYou ? "widget__body--success" : showTicketForm ? "" : !isQRPayloadLoaded(messages) ? "widget__body--messages" : "widget__body--no-radius"}`}>
                        {
                            showTicketForm ? <TicketForm showThankYou={onSubmitTicket} /> :
                                showThankYou ? <ThankYou onGoBackClick={backToWidget} /> :
                                    <div className="widget__messages">
                                            {messages.map((m: any, indexParent: number) => (
                                                <div key={indexParent}
                                                    className={getMessageParentClassName(m)}>
                                                    {m.type === MessageTypes.Message && <Message message={m} isHostDevice={isHostDevice} messageListArray={messageListArray} setShowverigyAge={setShowverigyAge} setisOverlayScreenMounted={setisOverlayScreenMounted} />}
                                                    {m.type === MessageTypes.LiveChatMessage && <LiveChatMessage liveChatMessage={m} liveChatAgent={liveChatAgent} liveChatMessageListArray={liveChatMessageListArray} />}
                                                    {m.type === MessageTypes.Article && <MessageQrArticle message={m} clickHandler={openArticle} />}
                                                    {m.type === MessageTypes.ArticleList && <ArticleList message={m} clickHandler={openArticle} />}
                                                    {m.type === MessageTypes.Stores && <Carousel stores={m.data} isHostDevice={isHostDevice} />}
                                                    {m.type === MessageTypes.PAStoreSelector && <StoreSelector stores={m.data} isHostDevice={isHostDevice} setSelectedStoreData={setPaSelectedStoreData} quickReplyHandler={quickReplyHandler} />}
                                                    {m.type === MessageTypes.StoreSelector && <Carousel stores={m.data} isHostDevice={isHostDevice} chooseWinePreference={chooseWinePreference} sessionId={sessionId.current} userId={chatbotUserId.current} />}
                                                    {m.type === MessageTypes.Product && <ProductList sessionId={sessionId.current} userId={chatbotUserId.current} products={m.products} toggleWidget={toggleWidget} selectedStore={paSelectedStoreData} quickReplyHandler={quickReplyHandler} />}
                                                    {m.type === MessageTypes.ProductNearbyStore && <ProductNearbyStoreList nearbyStores={m.stores} isHostDevice={isHostDevice} toggleWidget={toggleWidget} />}
                                                    {m.type === MessageTypes.OrderTracking && <MessageOrderTracking message={m} />}
                                                    {   // this code is specific to Chatserver V2, duplicate code can be retired after we go live
                                                        m.type === MessageTypes.QuickReplyPayload && m.data?.length > 0 &&
                                                        <div className="widget__message-qr-container" >
                                                            {
                                                                m.data.map((message: QrPayloadModel, index: number) => (
                                                                    <div key={index} >
                                                                        {message.type === MessageTypes.Qr && <MessageQR text={message.text} eventText={message.eventText} quickReplyHandler={quickReplyHandler} indexParent={indexParent} msgs={messages} />}
                                                                        {message.type === MessageTypes.ConditionalQr && <MessageQR text={message.text} quickReplyHandler={quickReplyHandler} indexParent={indexParent} msgs={messages} />}
                                                                        {message.type === MessageTypes.DynamicQr && <MessageQrDynamic message={message} quickReplyHandler={quickReplyHandler} sessionId={sessionId.current} userId={chatbotUserId.current} indexParent={indexParent} msgs={messages}/>}
                                                                        {message.type === MessageTypes.Help && <MessageLink text={message.text} link={config.DAN_HELP_CENTRE_URL} toggleWidget={toggleWidget} indexParent={indexParent}msgs={messages} />}
                                                                        {message.type === MessageTypes.Form && <MessageLink text={message.text} link={config.DAN_CONTACT_US_FORM_URL} sessionId={sessionId.current} userId={chatbotUserId.current} intentGroupId={message.intentGroupId} type={message.type} indexParent={indexParent} msgs={messages}/>}
                                                                        {message.type === MessageTypes.Url && <MessageLink text={message.text} link={message.uri} target={message.target} sessionId={sessionId.current} userId={chatbotUserId.current} intentGroupId={message.intentGroupId} type={message.type} toggleWidget={toggleWidget} indexParent={indexParent} msgs={messages}/>}
                                                                        {message.type === MessageTypes.LiveChatQr && <MessageLiveChatQR text={message.text} link={config.DAN_CONTACT_US_FORM_URL} callBackFn={endLiveChat} />}
                                                                        {message.type === MessageTypes.End && <MessageQuickEvent text={message.text} quickReplyHandler={quickReplyHandler} eventName={dialogflowEvent.End_Interaction} showTextResponse={true} indexParent={indexParent} msgs={messages}/>}
                                                                        {message.type === MessageTypes.QrEvent && <MessageQuickEvent text={message.text} quickReplyHandler={quickReplyHandler} eventName={dialogflowEvent.Chatbot_Article_FirstSuggestions} showTextResponse={false} indexParent={indexParent} msgs={messages}/>}
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    }

                                                    {   // this code is specific to Chatserver V2, duplicate code can be retired after we go live
                                                        m.type === MessageTypes.ArticlePayload &&
                                                        <div className="widget__message-qr-container">
                                                            <ArticleListPayloadProps messages={m.data} clickHandler={openArticle} />
                                                        </div>
                                                    }

                                                    {   // this code is specific to Chatserver V2, duplicate code can be retired after we go live
                                                        m.type === MessageTypes.VerifiedOrders &&
                                                        <div className="widget__message-verified-container">
                                                            <div >
                                                                <VerifiedOrderList orderList={m.data} clickable={true} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            ))}
                                            {loader ? <Loader /> : null}
                                            <div ref={messagesEndRef} className="messages-end" />
                                    </div>
                        }
                        <div className={autoEndSessionScreen || showManualEndSessionScreen || showverigyAge ? "overlay-slideup" : ''}></div>
                    </div>
                }

                {// 'isQRPayloadLoaded(messages)' is used to conditionally render the bot input textbox for bot and 
                    //  'qRPayloadLoaded' is used to conditionally render the bot input textbox during livechat
                    (!showFAW && !showArticle && !showTicketForm && !showThankYou && !showOrderTrackingDetail && !showNomineeChangeScreen && (isQRPayloadLoaded(messages) || qRPayloadLoaded)) && (
                        <div id="divFooterContainer" className={"widget__footer " + (!isHostDevice && "widget__footer--website")} ref={el => divFooterContainer = el} >
                            <form className="widget__footer-form" >
                                <div id="divInputBorder" className="inputBorder">
                                    <div
                                        id="divTextBox"
                                        contentEditable={true}
                                        className="divTextBox"
                                        onKeyDown={event => onEnterPress(event)}
                                        spellCheck="false"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        aria-autocomplete='none'
                                    >
                                    </div>
                                </div>
                                <button type="submit"
                                    id="widget-footer-button"
                                    disabled={!input}
                                    onClick={sendMessage}
                                    className={`widget__footer-button ${!showInputTextBox ? 'hide' : ''} ${input ? 'active' : ''}`}>
                                </button>
                            </form>
                        </div>
                    )
                }

                {showArticle && isArticleFound && articleData != null && <Vote sessionId={sessionId.current} userId={chatbotUserId.current} data={articleData} backToWidget={backToWidget} quickReplyHandler={quickReplyHandler} />}
            </div>

            {!showWidget && isHostDevice !== null &&
                <button id="murphy-bot-widget" onClick={() => toggleWidget()} className={"widget__button " + (!showWidget && "widget__button--show ") + (isHostDevice ? "widget__button--hostdevice" : "")}>
                    {unreadMessageCount > 0 && <span className="widget__unread">{unreadMessageCount}</span>}
                    <span className={"widget__button-icon " + (!isHostDevice && "widget__button-icon--website ") + (isHostDevice === true ? "widget__button-icon--hostdevice" : "")}>
                        <img src={`${cdnImgUrl}/chatbot-murphy-no-border?&fmt=png-alpha`} alt="Murphy" className={"widget__button-icon-logo" + (isHostDevice === true ? "--hostdevice" : "")} />
                    </span>
                </button>
            }

        </div>
    )
}

export default Widget

