import React, { useState } from 'react';
import Arrow from './Arrow';
import { ProductNearbyStoreListDetail } from '../product-nearby-store-list-detail/ProductNearbyStoreListDetail';
import './product-nearby-store-list.scss';
import { getUniqueId } from '../../widget/util/commonUtil';

type nearbyStoreProps = {
    nearbyStores: any,
    isHostDevice:any,
    toggleWidget: any
};

const ProductNearbyStoreList = ({nearbyStores, isHostDevice, toggleWidget} : nearbyStoreProps) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [itemLength] = useState<number>(nearbyStores.length);

    const previous = () => {
        if(activeIndex!=0){
        let index = activeIndex;
        let length = itemLength;

        if (index < 1) {
            index = length - 1;
        } else {
            index--;
        }

        setActiveIndex(index);
    }
  }

    const next = () => {
        if(activeIndex!=4){ 
        let index = activeIndex;
        let length = itemLength;

        if (index === length - 1) {
            index = 0;
        } else {
            index++;
        }

        setActiveIndex(index);
    }
  }
    
    return (
        <div className='carousel__container'>
            <div>
                {
                nearbyStores.map((s: any, index: number) => (
                    <div className={index === activeIndex ? 'carousel__active' : 'carousel__inactive'} key={index} id={getUniqueId('product-nearby', (index+1).toString())}>
                        <ProductNearbyStoreListDetail nearbyStore={s.data} isHostDevice = {isHostDevice} toggleWidget={toggleWidget} />
                    </div>
                    ))
               }
            </div>
            <div className='carousel__arrows'>
                <Arrow direction='left' clickHandler={previous} activeIndex={activeIndex}/>
                <div className="carousel__paging">{activeIndex+1} of {nearbyStores.length} stores</div>            
                <Arrow direction='right' clickHandler={next} activeIndex={activeIndex}/>
            </div>
        </div>
    );
}

export {
    ProductNearbyStoreList
};