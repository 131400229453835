import React from 'react';
import '../findAWine.scss';
import './varietal.scss';
import { getUniqueId } from '../../widget/util/commonUtil';

type VarietalProps = {
    updateVarietal: (varietal: string) => void;
    varietals: string[];
    selectedVarietal: string;
}

const Varietal = ({ updateVarietal, varietals, selectedVarietal }: VarietalProps) => {
    return (
            <div className="faw-type-container">
                <p className="sub-header"><b>I’m interested in...</b></p>
                <div className="flex-container">
                    {varietals.map((v: any, index: number) => 
                    <div className="flex-item" key={index}>
                        <input type="button" value={v}  id={getUniqueId('varietal', v)} className={`form-button varietal-button ${selectedVarietal === v ? 'primary' : 'secondary'}`} onClick={ () => {updateVarietal(v)}}/>
                    </div>)}
                </div>
            </div>
      )
}
  
export default Varietal