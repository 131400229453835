import { config } from '../../config/config';
//import io from 'socket.io-client';
import zChat from '../../zendesk/web-sdk';
import { MessageModel } from '../models/messageModel';
import { MessageTypes, Speaker } from '../models/enum';
import axios from 'axios/index';


const initLiveChat = function() {
    // create socket
    // let socket = io(`${config.CHATSERVER_URL}`);

    // socket.on('messageListener', function(msg:any) {
    //     setMessages((messages: any) => [...messages, ...[msg]]);
    //     setLoader(false);         
    // });     
}

const sendLiveChatMessage = (message: string)=> {
    zChat.sendChatMsg(message, function(err: any) {
        if (err) 
            console.log(`err : ${err}`);
    });
}

const isUnderOperatingHours = () => {
    let underOperatingHours: boolean = false;

    try {
        const currentDate = new Date();
        const currentMinutes = (currentDate.getHours() * 60) + currentDate.getMinutes();
        const departmentId = parseInt(config.ZENDESK_LIVECHAT_GROUP_ID);
        const operatingHoursObj = zChat.getOperatingHours();
        let operatingHoursToday = (operatingHoursObj) ? operatingHoursObj.department_schedule[departmentId] : null;
        let departmentStatuses = zChat.getAllDepartments();
        let liveChatDepartment = departmentStatuses.find((a: { id: number; name: string; status: string}) => a.id === departmentId);

        if(operatingHoursToday !== undefined && operatingHoursToday !== null && liveChatDepartment.status && liveChatDepartment.status.toLowerCase() === 'online') {
            //// online hours are present
            underOperatingHours = (operatingHoursToday[currentDate.getDay()][0].start <= currentMinutes && currentMinutes <= operatingHoursToday[currentDate.getDay()][0].end);
        }
    }
    catch {
        underOperatingHours = false;
    }

    return underOperatingHours;
}

const isLiveChatAvailable = () => {
    let isAvailable: boolean = false;

    try {
        const connectionStatus = zChat.getConnectionStatus();
        const underOperatingHours = isUnderOperatingHours();
        if (connectionStatus === 'connected' && underOperatingHours) {
            isAvailable = true;
        }  else if(connectionStatus === 'closed'){
                //if connection status is closed than reconnecting it, if it's under operating hours.
                if(underOperatingHours){
                if(zChat.reconnect()){
                    isAvailable = true;
                }else{
                    isAvailable = false;
                }
                }
        }
        else {
            isAvailable = false;
        }
    }
    catch {
        isAvailable = false;
    }
    
    return isAvailable;
}

// this function will remove the top messages of the message history array
// removeCount = number of messages to be removed
const removeTopBotMessage = (messages: MessageModel[], removeCount: number) => {
    for (let i = 0; i < removeCount; i++) {
        let index = messages.findIndex(a => a.from !== Speaker.User && a.type === MessageTypes.Message);
        messages.splice(index, 1);
    }

    return messages;
}

const selectUserMessages = (messages: MessageModel[], data: any = null) => {
    let conversationHistory = messages.filter(
        a => a.from === Speaker.User || a.type === MessageTypes.Message);
    conversationHistory = removeTopBotMessage(conversationHistory, 2);

    return `==========================================\n` + ((data?.orderNumber) ? `Order Number : ${ data?.orderNumber }\n==========================================\n` : '')
        + ((data?.isLoggedIn) ? `The user is logged in : ${ data?.shopperId } \n==========================================\nLC Context\n` : '') + 

        conversationHistory.map(a => (a.from === Speaker.User) ? `User:  ${ a.text }` : `Murphy: ${ a.text }`)
        .join(`\n`);
} 

const selectLiveChatMessages = (messages: MessageModel[]) => {
    let conversationHistory = messages && messages.filter(a => a.type === MessageTypes.LiveChatMessage);

    conversationHistory && conversationHistory.splice(0, 2);

    return conversationHistory && conversationHistory
        .map(a => (a.from === Speaker.User) ? `User:  ${ a.text }` : `Agent: ${ a.text }`)
        .join(`\n`);
} 

const logEndChatReason = (chatbotUserId: any, sessionId: any, endLiveChatReason = "") => {
    const requestConfig = {
        headers: { 
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': config.APIMGT_KEY
        },
    }

    const body = { 
        msg: `LIVE CHAT END: user: ${ chatbotUserId } : session : ${sessionId} : ${endLiveChatReason}`
    };

    axios.post(`${config.CHATSERVER_URL}/api/log/trace`, body, requestConfig);
}

export {
    initLiveChat,
    sendLiveChatMessage,
    isLiveChatAvailable,
    removeTopBotMessage,
    selectUserMessages,
    selectLiveChatMessages,
    logEndChatReason
}