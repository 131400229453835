import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import './NomineeChangeScreen.scss';
import { getNomineeDetails, setNomineeDetails } from '../chatservice/chatserverApis';
import { DialogflowQueryType, MessageTypes, Speaker } from '../models/enum';
import { dialogflowEvent } from '../../config/config';
import { addAnalyticsEvent } from '../chatservice/dialogflowQuery';
import { v4 } from 'uuid';
import { setOrderForTile, setShowNomineeChangeFailedScreen, setShowNomineeChangeScreen } from '../../actions';
import SkeletonLoaderNomineeChange from '../skeleton-loader/SkeletonLoaderNomineeChange';
import VerifiedOrderList from '../message/verifiedOrderTracking/VerifiedOrderList';
import CollectThirdPartyInfo from './CollectThirdPartyInfo';
import AcceptableIds from './AcceptableIds';
import NomineeChangeFailedScreen from './NomineeChangeFailedScreen';

type NomineeChangeScreenProps = {
    order: any,
    quickReplyHandler: any,
    backToWidget: any,
    sessionId: any,
    userId: any,
    setMessages: any,
    msgs: any,
    setliveChatTransfer: (type: boolean) => void,
    showNomineeChangeFailedScreen: boolean,
    dialogflowEventQueryAsync: (text: string, params?: any, tempWidgetSessionId?: any) => Promise<void>,
    updateNomineeState: (isUpdated: boolean) => void
}

const NomineeChangeScreen = ({ order, quickReplyHandler, backToWidget, sessionId, userId, setMessages, showNomineeChangeFailedScreen, setliveChatTransfer, msgs, dialogflowEventQueryAsync, updateNomineeState }: NomineeChangeScreenProps) => {
    const shopperId = useSelector((state: RootState) => state.botData.shopperId);
    const widgetSessionId = useSelector((state: RootState) => state.botData.widgetSessionId);

    const tileStartRef: any = useRef(null);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isNomineeUpdated, setIsNomineeUpdated] = useState(false);
    const [showThirdPartyInfo, setShowThirdPartyInfo] = React.useState(false);
    const [showAcceptableIds, setShowAcceptableIds] = React.useState(false);
    const dispatch = useDispatch();
    
    const nomineeUpdatedEndRef: any = useRef(null);

    const { register, handleSubmit, formState, getValues, trigger } = useForm();
    const { errors } = formState;
    const errorMessage = "Sorry I’m unable to continue with this request as your order is still being processed. Please try again later.";
    const [focusedField, setFocusedField] = useState<string | null>(null);

    const scrollToStart = () => {
        tileStartRef?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const showThirdPartyInfoScreen = () => {
        setShowThirdPartyInfo(true);
    }

    const showAcceptableIdsScreen = () => {
        setShowAcceptableIds(true);
    }

    const cancelNomineePickupChange = (e: any) => {
        dispatch(setShowNomineeChangeScreen(false));
        dispatch(setOrderForTile(null));
        triggerAnalyticsEvent("CANCEL_PICKUP_NOMINEE");
        dialogflowEventQueryAsync(dialogflowEvent.ORDER_TRACKING_MY_ORDERS_PICKUP_CHANGE_NOMINEE_CANCEL);
        backToWidget(true, 'cancelNomineePickupChange');
    }

    const updateAgainClick = () => {
        setIsNomineeUpdated(false);
        triggerAnalyticsEvent("UPDATE_AGAIN_CLICKED");
    }
    const closeScreen = (e: any) => {
        dispatch(setShowNomineeChangeScreen(false));
        dispatch(setOrderForTile(null));

        if (isNomineeUpdated === true) {
            let eventName = dialogflowEvent.ORDER_TRACKING_PICK_UP_NOMINEE_UPDATED;
            let disPlayClickAsText = false;
            let renewIntentGroup = false;

            quickReplyHandler(e, DialogflowQueryType.Event, eventName, disPlayClickAsText, null, renewIntentGroup, null);
        }
        backToWidget(true, 'closeScreen');
    }

    useEffect(() => {
        updateNomineeState(false);
    },[])
    
    const scrollToBottom = () => {
        nomineeUpdatedEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const updateNomineeDetails = (data: any) => {
        setIsDataLoaded(false);
        const { nomineeFullName, nomineePhoneNumber } = data;
        if (nomineeFullName && nomineePhoneNumber) {
            setNomineeDetails(shopperId, widgetSessionId, order.orderId, sessionId, userId, nomineeFullName, nomineePhoneNumber)
                .then((result: any) => {
                    if (result.statusCode && result.statusCode !== 200) {
                        setIsDataLoaded(true);
                        dispatch(setShowNomineeChangeFailedScreen(true));

                        dispatch(setOrderForTile(null));

                        if (shopperId === 0) {
                            setMessages((messages: any) => [...messages, ...[{
                                text: null, from: Speaker.Murphy, type: MessageTypes.QuickReplyPayload, data: [{
                                    intentGroupId: order.intentGroupId,
                                    intentGroupName: order.intentGroupName,
                                    target: "route",
                                    text: "Login",
                                    type: "url",
                                    uri: "https://www.danmurphys.com.au/dm/mydans/login"
                                }]
                            }]]);
                        }
                    } else {
                        order.nomineeDetails = nomineeFullName;
                        setIsDataLoaded(true);
                        setIsNomineeUpdated(true);
                        updateNomineeState(true);

                    }
                });
        }


        triggerAnalyticsEvent("UPDATE_PICKUP_NOMINEE");

    }

    const triggerAnalyticsEvent = (eventName: any) => {

        const analyticsData = {
            queryId: v4(),
            isSuccessful: null,
            eventName: eventName,
            intentGroupId: order.intentGroupId,
            intentGroupName: order.intentGroupName,
            intentGroupTypeId: 5,
            source: "murphy"
        };

        addAnalyticsEvent(sessionId, userId, analyticsData);
    }

    useEffect(() => {
        scrollToStart();
        // fetch article data
        if (isDataLoaded === false) {
            getNomineeDetails(shopperId, widgetSessionId, order.orderId, sessionId, userId)
                .then((result: any) => {
                    if (result.statusCode && result.statusCode !== 200) {
                        dispatch(setShowNomineeChangeScreen(false));
                        dispatch(setOrderForTile(null));
                        setMessages((messages: any) => [...messages, ...[{ text: errorMessage, from: Speaker.Murphy, type: MessageTypes.Message }]]);

                        if (shopperId === 0) {
                            setMessages((messages: any) => [...messages, ...[{
                                text: null, from: Speaker.Murphy, type: MessageTypes.QuickReplyPayload, data: [{
                                    intentGroupId: order.intentGroupId,
                                    intentGroupName: order.intentGroupName,
                                    target: "route",
                                    text: "Login",
                                    type: "url",
                                    uri: "https://www.danmurphys.com.au/dm/mydans/login"
                                }]
                            }]]);
                        }

                        backToWidget(true, 'getNomineeDetails');
                    } else {
                        order.nomineeDetails = result.data.nomineeName;
                        setIsDataLoaded(true);
                    }
                });
        }
    }, []);
    useEffect(scrollToBottom);
    const getInputClassLabel = (errors: any, currentField: string) => {
        const isError = currentField === "nomineeFullName" ? errors.nomineeFullName : errors.nomineePhoneNumber;
        let nomineeNameClassLabel = "";
        if (isError) {
            nomineeNameClassLabel = 'show-error-highlight';
        } else if (!isError && (focusedField === currentField)) {
            nomineeNameClassLabel = 'show-valid-highlight';
        }
        return nomineeNameClassLabel;
    }

    return (
        <div className='scroller'>
            {
                isDataLoaded === true && isNomineeUpdated === false && !showNomineeChangeFailedScreen &&
                <form onSubmit={handleSubmit(updateNomineeDetails)}>
                    <div className="updateNomineeDiv">
                        <div className="updateNomineeDiv__orderDetails">You’re updating the nominee for the following order:
                            <VerifiedOrderList orderList={[order]} clickable={false} />
                        </div>
                        <div className="updateNomineeDiv__nomineeDetails">
                            <div className="updateNomineeDiv__nomineeDetails__header">Enter nominee’s details</div>
                            <div className="updateNomineeDiv__nomineeDetails__inputDiv">
                                <label className={`updateNomineeDiv__nomineeDetails__inputDiv__header  ${errors.nomineeFullName ? 'show-error' : ''}`}>Nominee’s full name</label>
                                <label className='updateNomineeDiv__nomineeDetails__inputDiv__inputLabel textLabel'>The name must match what’s on the ID</label>
                                <input className={`updateNomineeDiv__nomineeDetails__inputDiv__inputText ${getInputClassLabel(errors, "nomineeFullName")}`}
                                    placeholder="Enter your full name"
                                    {...register('nomineeFullName', {
                                        required: 'Enter nominee’s full name',
                                        minLength: { value: 4, message: 'The full name must be between 4-40 characters with a space between the first & last name.' },
                                        maxLength: { value: 40, message: 'The full name must be between 4-40 characters with a space between the first & last name.' },
                                        pattern: { value: /^[A-Za-z\s]+$/, message: 'Looks like you’ve used special characters. Please remove them & try again' },
                                        onBlur: () => { setFocusedField(''); trigger("nomineeFullName"); }
                                    })}
                                    onFocus={() => setFocusedField('nomineeFullName')} />
                                {errors.nomineeFullName &&
                                    <div className="updateNomineeDiv__nomineeDetails__inputDiv-error">
                                        <img src="/images/error-icon.svg" alt="Error Icon" />
                                        <span>{errors.nomineeFullName.message}</span>
                                    </div>
                                }
                            </div>
                            <div className="updateNomineeDiv__nomineeDetails__inputDiv">
                                <label className={`updateNomineeDiv__nomineeDetails__inputDiv__header ${errors.nomineePhoneNumber ? 'show-error' : ''}`}>Mobile number</label>
                                <label className='updateNomineeDiv__nomineeDetails__inputDiv__inputLabel textLabel'>In case the store needs to contact your nominee</label>
                                <div className='updateNomineeDiv__nomineeDetails__inputDiv__mobileNumber'>
                                    <div className={`updateNomineeDiv__nomineeDetails__inputDiv__mobileNumber__flag  ${getInputClassLabel(errors, "nomineePhoneNumber")}`}>
                                        <img src="/images/Australia.svg" id="australia-icon" alt="Australia flag" className='updateNomineeDiv__nomineeDetails__inputDiv_mobileNumber__flag--australia' />
                                        <label className='updateNomineeDiv__nomineeDetails__inputDiv__inputLabel textLabel'>+61</label>
                                    </div>
                                    <input className={`updateNomineeDiv__nomineeDetails__inputDiv__inputText mobileNumber ${getInputClassLabel(errors, "nomineePhoneNumber")}`}
                                        {...register("nomineePhoneNumber", {
                                            required: 'Enter nominee’s mobile number',
                                            pattern: { value: /^(4\d{8}|04\d{8})$/, message: 'This doesn’t seem to be an Australian mobile number. Please check it & try again.' },
                                            onBlur: () => { setFocusedField(''); trigger("nomineePhoneNumber"); },
                                        })}
                                        onFocus={() => setFocusedField('nomineePhoneNumber')} />
                                </div>
                                {errors.nomineePhoneNumber &&
                                    <div className="updateNomineeDiv__nomineeDetails__inputDiv-error">
                                        <img src="/images/error-icon.svg" alt="Error Icon" />
                                        <span>{errors.nomineePhoneNumber.message}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="updateNomineeDiv__alert">
                            <img src="/images/Information.svg" className="updateNomineeDiv__alert__icon" id="alert-information-icon" alt="information" />
                            <span className="updateNomineeDiv__alert__infoLabel textLabel">Please remind your nominee to <label className="updateNomineeDiv__impInfo__acceptableIds-label bold" onClick={showAcceptableIdsScreen}>bring an acceptable ID</label> when they go in store to collect the order.</span>
                        </div>
                        <div className="updateNomineeDiv__info textLabel">
                            We are collecting the personal information of the pickup nominee so that we can update your order. <span className='updateNomineeDiv__info textLabel-underline green bold' onClick={showThirdPartyInfoScreen}>Read what this means.</span>
                        </div>
                        <div className="updateNomineeDiv__buttons">
                            <input className='updateNomineeDiv__buttons__update' type="submit" value="Update Pick up nominee" />
                            <button className='updateNomineeDiv__buttons__cancel' onClick={cancelNomineePickupChange}>Cancel</button>
                        </div>
                    </div>
                </form>
            }

            {
                isDataLoaded === true && isNomineeUpdated === true && !showNomineeChangeFailedScreen &&
                <div className="updateNomineeDiv">
                    <div ref={nomineeUpdatedEndRef} className="messages-end" />
                    <div className="updateNomineeDiv__nomineeUpdated">
                        <img src="/images/confirmation-icon.svg" id="nominee-confirmation-icon" alt="Nominee Change Confirmed" className='updateNomineeDiv__nomineeUpdated__confirmIcon' />
                        <label className='updateNomineeDiv__nomineeUpdated__label'>Pick up nominee updated!</label>
                    </div>
                    <div className="updateNomineeDiv__updatedNomineeDetails">
                        <label className="updateNomineeDiv__updatedNomineeDetails__header">Your order can be picked up by:</label>
                        <div className="updateNomineeDiv__updatedNomineeDetails__namePhone">
                            <div className="updateNomineeDiv__updatedNomineeDetails__namePhone__name align-flex-start">
                                <label className="updateNomineeDiv__updatedNomineeDetails__namePhone__name__label">Name:</label>
                                <label className="updateNomineeDiv__updatedNomineeDetails__namePhone__name__labelValue">{getValues("nomineeFullName")}</label>
                            </div>
                            <div className="updateNomineeDiv__updatedNomineeDetails__namePhone__phone align-centre">
                                <label className="updateNomineeDiv__updatedNomineeDetails__namePhone__phone__label mobileNumberWidth">Mobile Number:</label>
                                <label className="updateNomineeDiv__updatedNomineeDetails__namePhone__phone__labelValue">{getValues("nomineePhoneNumber")}</label>
                            </div>
                        </div>
                        <label className="updateNomineeDiv__updatedNomineeDetails__updateAgain" onClick={updateAgainClick}>Update again</label>
                    </div>
                    <div className="updateNomineeDiv__impInfo">
                        <label className="updateNomineeDiv__impInfo__header">Important info:</label>
                        <div className="updateNomineeDiv__impInfo__bulletPoint">
                            <img src='/images/ellipse.svg' className="updateNomineeDiv__impInfo__bulletPoint__ellipse" alt="Bullet Point" />
                            <label className="updateNomineeDiv__impInfo__bulletPoint__label"><label className="updateNomineeDiv__impInfo__bulletPoint__label-bold">Bring an acceptable ID:</label> store will refuse Pick up if nominee’s ID is not accepted.</label>
                        </div>
                        <div className="updateNomineeDiv__impInfo__acceptableIds">
                            <img src="/images/Information.svg" className="updateNomineeDiv__alert__icon" id="alert-information-icon" alt="information" />
                            <label className="updateNomineeDiv__impInfo__acceptableIds-label bold" onClick={showAcceptableIdsScreen}>See what IDs are accepted</label>
                        </div>
                        <div className="updateNomineeDiv__impInfo__bulletPoint">
                            <img src='/images/ellipse.svg' className="updateNomineeDiv__impInfo__bulletPoint__ellipse" alt="Bullet Point" />
                            <label className="updateNomineeDiv__impInfo__bulletPoint__label"><label className="updateNomineeDiv__impInfo__bulletPoint__label-bold">Store will see the update soon:</label> This change won’t reflect on your online account. However, it may take 5 mins before the store can see it on their end.</label>
                        </div>
                        <div className="updateNomineeDiv__impInfo__bulletPoint">
                            <img src='/images/ellipse.svg' className="updateNomineeDiv__impInfo__bulletPoint__ellipse" alt="Bullet Point" />
                            <label className="updateNomineeDiv__impInfo__bulletPoint__label"><label className="updateNomineeDiv__impInfo__bulletPoint__label-bold">When in store:</label> Quote the order number or your full name when your nominee goes in store to collect the order.</label>
                        </div>
                    </div>
                    <div className="updateNomineeDiv__buttons">
                        <div className='updateNomineeDiv__buttons__update' onClick={closeScreen}>Back to chat</div>
                    </div>
                </div>
            }

            {isDataLoaded === false && <SkeletonLoaderNomineeChange />}

            {showThirdPartyInfo && <CollectThirdPartyInfo setShowThirdPartyInfo={setShowThirdPartyInfo} />}

            {showAcceptableIds && <AcceptableIds setShowAcceptableIds={setShowAcceptableIds} />}
            <div className={showThirdPartyInfo || showAcceptableIds ? "overlay-slideup" : ''}></div>
            {showNomineeChangeFailedScreen && <NomineeChangeFailedScreen setliveChatTransfer={setliveChatTransfer} setIsDataLoaded={setIsDataLoaded} />}
        </div>
    )
}

export default NomineeChangeScreen;