import { Button, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import './CollectThirdPartyInfo.scss'


type CollectThirdPartyInfoProps = {
    setShowThirdPartyInfo: (type: boolean) => void
}

const useStyles = makeStyles({
    button: {
        backgroundColor: '#4C8251',
        color: '#fff',
        textTransform: 'none',
        fontStyle: 'normal',
        fontSize: '16px',
        padding: '12px 24px',
        transition: 'all .2s ease',
        width: '100%',
        height: '42px',
        fontWeight: 600,

        '&:hover': {
            backgroundColor: '#305233',
            color: '#fff',
        },
        '&:focus': {
            backgroundColor: '#305233',
            color: '#fff',
            border: '2px solid #5795DB'
        },
        '&:active': {
            backgroundColor: '#143017',
            color: '#fff',
        },
        '&:disabled': {
            backgroundColor: '#D1D2D1',
            color: '#fff',
        },
    }
})

const CollectThirdPartyInfo = ({ setShowThirdPartyInfo }: CollectThirdPartyInfoProps) => {
    const [mountingStyle, setmountingStyle] = useState<string>(' mounting');

    const classes = useStyles();

    const closePopup = () => {
        setmountingStyle(' unmounting');

        let timeoutId: ReturnType<typeof setTimeout> | null = null;

        timeoutId = setTimeout(() => {
            setShowThirdPartyInfo(false);
        }, 400);

        return () => {
            if (timeoutId) { clearTimeout(timeoutId); }
        }
    }

    return (
        <div className={'widget__overlay--datacollection noPaddingTop' + mountingStyle} onBlur={() => closePopup()}>
            <div className="collectionDiv">
                <div className="collectionDiv__header">
                    <img src='/images/close-black.svg' className='collectionDiv__header__img' onClick={() => closePopup()} />
                </div>
                <div className="collectionDiv__details">
                    <div className="collectionDiv__details__header">Collection of third party personal information</div>
                    <div className="collectionDiv__details__info">
                        <div>We are collecting the personal information of the pickup nominee so that we can update your order. Before continuing you should have:  </div>
                        <div className="collectionDiv__details__info__bulletPoint">
                            <img src='/images/ellipse.svg' className="collectionDiv__details__info__bulletPoint__ellipse"></img>
                            <label className="collectionDiv__details__info__bulletPoint__label">obtained consent from nominees for their information collection; </label>
                        </div>
                        <div className="collectionDiv__details__info__bulletPoint">
                            <img src='/images/ellipse.svg' className="collectionDiv__detail__infos__bulletPoint__ellipse"></img>
                            <label className="collectionDiv__details__info__bulletPoint__label">informed nominees that you are providing their information to Dan Murphy’s and why; and</label>
                        </div>
                        <div className="collectionDiv__details__info__bulletPoint">
                            <img src='/images/ellipse.svg' className="collectionDiv__details__info__bulletPoint__ellipse"></img>
                            <label className="collectionDiv__details__info__bulletPoint__label">let them know of the <a className="outbound-link" href="https://www.danmurphys.com.au/help/help-centre/articles/360000505355-Dan-Murphy-s-Collection-Statement" target="_blank"><span className="outbound-link__title">Dan Murphy’s Collection Statement</span><img src="/images/external-link-chip.svg" alt="External Link" className="outbound-link__icon" /></a> and <a className="outbound-link" href="https://www.danmurphys.com.au/help/help-centre/articles/360000043536-Privacy-Policy" target="_blank"><span className="outbound-link__title">Dan Murphy’s Privacy Policy.</span><img src="/images/external-link-chip.svg" alt="External Link" className="outbound-link__icon" /></a></label>
                        </div>
                        <div className="collectionDiv__details__info__buttons">
                            <div className='collectionDiv__details__info__buttons__update' onClick={() => closePopup()}>Close</div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default CollectThirdPartyInfo