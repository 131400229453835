import { createSelector } from 'reselect';
import { recommendedWine } from '../find-a-wine/models/fawTypes';
 
const getProductArray = (state: any) => state.productData.productArray

//To get the searched products list
export const getRecommendedWines = createSelector(
  [ getProductArray ],
  (productArray) => {

      const recommendedWines: recommendedWine[] = productArray.map((product: any) => {
          return {
              brandName: product.brand,
              wineName: product.productName,
              price: product.singlePrice,
              rating: product.averageOverallRating,
              review: product.totalReviewCount,
              imageURL: `https://media.danmurphys.com.au/dmo/product/${product.sku}-1.png?impolicy=Prod_MD`,
              salesRank: product.salesRank,
              newAndNoteworthyRank: product.newAndNoteworthyRank,
              wineLink: product.link
            }
      })

      const bestSellers : recommendedWine[] = recommendedWines.filter(item => item.salesRank !== null).sort((a,b) => {return (b.salesRank - a.salesRank)});
      const topRatedSellers : recommendedWine[] = recommendedWines.filter(item => item.rating !== null).sort((a,b) => { return ((b.rating - a.rating) || (b.review - a.review)) });
      const newAndNoteworthySellers : recommendedWine[] = recommendedWines.filter(item => item.newAndNoteworthyRank !== null).sort((a,b) => {return (b.newAndNoteworthyRank - a.newAndNoteworthyRank)});
      
      return {
        recommendedWines: [...recommendedWines], 
        bestSellers: bestSellers.length > 20 ? [...bestSellers.splice(0, 20)] : [...bestSellers],  
        topRatedSellers: topRatedSellers.length > 20 ? [...topRatedSellers.splice(0, 20)] : [...topRatedSellers],
        newAndNoteworthySellers: newAndNoteworthySellers.length > 20 ? [...newAndNoteworthySellers.splice(0, 20)] : [...newAndNoteworthySellers],
        intentData : {}
      }
    }
)