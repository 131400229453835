import React, { useState } from 'react';
import { isValidEmail } from '../../util/validators';
import Checkbox from '../../checkbox/checkbox';

type LiveChatUserFormProps = {
    setName: React.Dispatch<React.SetStateAction<string>>;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    email: string;
    name: string;
    backToWidget: (voted: boolean) => void;
    closeUserForm: () => void;
    isHostDevice: boolean;
}

const LiveChatUserForm = ({ setName, setEmail, email, name, backToWidget, closeUserForm, isHostDevice }: LiveChatUserFormProps) => {

    const [consent, setConsent] = useState<boolean>(false);

    const cancel = (e: any) => {
        backToWidget(true);
    }

    const privacyTextLabel = `I agree that Dan Murphy's will retain a copy of the chat history and will collect and use any personal information provided by me in accordance with the&nbsp;
                              <a href="https://www.danmurphys.com.au/help/help-centre/articles/360000505355-Dan-Murphy-s-Collection-Statement" target="_blank" rel="noopener noreferrer">
                              Dan Murphy's Collection Statement.</a>`;

    return (
        <div className={"widget__overlay--livechat" + (isHostDevice ? " device" : "")}>
            <p className="widget__overlay--livechat-heading">Let me find someone to chat with you. I just need to grab a few details first. Please tell me your name and email address below.</p>
            <form className="widget__overlay--livechat-form">
                <div className="widget__overlay--livechat-form-field">
                    <label>Name</label>
                    <input placeholder="Enter your first name" type="text" className="form-input" autoFocus value={name} onChange={(e => setName(e.target.value))} required />
                </div>
                <div className={email && !isValidEmail(email) ? 'widget__overlay--livechat-form-field has-error': 'widget__overlay--livechat-form-field'}>
                    <label>Email address</label>
                    <input placeholder="Enter your email address" className="form-input" type="text" value={email} onChange={(e => setEmail(e.target.value))} required />
                    {email && !isValidEmail(email) && 
                    <div className="validation-error">
                        <img className="validation-error-img" src="/images/error-icon.svg"/>
                        <span className="validation-error-msg">Please enter a valid email address</span>
                    </div>
                    }
                </div>
                <div className="widget__overlay--livechat-form-privacy-consent">
                    <Checkbox label={privacyTextLabel} setCheckboxValue={ setConsent }></Checkbox>
                </div>
                <div className="widget__overlay--livechat-form-button-container">   
                    <input type="button" value="Cancel" className="form-button secondary" onClick={cancel}></input>
                    <input type="button" value="Continue" className="form-button primary" disabled={name === '' || email === '' || !isValidEmail(email) || !consent} onClick={closeUserForm} tabIndex={0}></input>
                </div>
            </form>
        </div>
    )
}

export default LiveChatUserForm