/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import './message.scss';
import { getUniqueId } from '../../widget/util/commonUtil';

type messageLinkProps = {
    text: string,
    link: string,
    callBackFn: () => void
}

const MessageLiveChatQR = ({text, link, callBackFn}: messageLinkProps) => {
    return (
        <div className="message__text--quick-reply">           
            <a href={link} onClick={callBackFn} className="message__link" id={getUniqueId('message-link', text)} target="_blank" rel="noreferrer">{text}</a>           
        </div>
    )
}

export default MessageLiveChatQR
