import moment, { Moment } from "moment-business-days";
import React from "react";

const getShippingType = (shippingType: string, orderDate: Moment, deliveryDate: Moment) => {
    let deliveryType = '';
    let calculatedDeliveryDate: Moment;
    let shippingIconUrl: string = '';

    if (shippingType === 'Pick A Day') {
        deliveryType = 'Just Say When';
        shippingIconUrl = '/images/icon-track-order-jsw.svg';
        calculatedDeliveryDate = moment(deliveryDate);
    } else if (shippingType === 'Same Day') {
        // within 2 hours should always be same day delivery
        deliveryType = 'Same Day';
        calculatedDeliveryDate = orderDate;
        shippingIconUrl = '/images/icon-track-order-jsw.svg';
    } else if (shippingType === 'Supplier') {
        deliveryType = 'Supplier';
        // add 10 days to this type of delivery
        calculatedDeliveryDate = orderDate.businessAdd(10, 'days');
        shippingIconUrl = '/images/icon-track-order-truck.svg';
    } else if (shippingType === 'Standard') {
        // add 10 days to this type of delivery
        deliveryType = 'Standard';
        calculatedDeliveryDate = orderDate.businessAdd(4, 'days');
        
        shippingIconUrl = '/images/icon-track-order-truck.svg';
    } else {
        deliveryType = shippingType;
        calculatedDeliveryDate = orderDate;
        shippingIconUrl = '/images/icon-track-order-truck.svg';
    }

    return {
        deliveryType,
        calculatedDeliveryDate,
        shippingIconUrl
    }
}

const getOrderStatus = (pickuporderStatus:any) => {
    let pickupIcon: string = '' ;
    let pickupStatus: string = '';
    let statusBackground: string = '';
    switch(pickuporderStatus) {
        case 'Awaiting':
            pickupIcon = '/images/Awaiting.svg';
            pickupStatus = 'Awaiting Picking';
            break;
        case 'Packed':
            pickupIcon = '/images/ready-to-collect.svg';
            pickupStatus = 'Ready to collect';
            break;
        case 'Completed':
            pickupIcon = '/images/collected.svg';
            pickupStatus = 'Collected';
            statusBackground = 'Collected';
            break;
        case 'Placed':
            pickupIcon = '/images/Confirmed.svg';
            pickupStatus = 'Confirmed';
            break;
        case 'Cancelled':
            pickupIcon = '/images/cancelled.svg';
            pickupStatus = 'Cancelled';
            statusBackground = 'Cancelled';
            break;
        default:
    }

    return {
        pickupIcon,
        pickupStatus,
        statusBackground,
    };
}

export {
    getShippingType,
    getOrderStatus
}