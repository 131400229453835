import React from 'react'
import './header.scss';
import Brand from '../brand/Brand';
import { config, livechat } from '../../config/config';

export type HeaderProps = {
    name: string;    
    toggleWidget: () => void;
    closeButton: () => void;
    backToWidget: () => void;
    isAgentName: boolean;
}

const Header = ({name, toggleWidget, closeButton, backToWidget, isAgentName}: HeaderProps) => {
    return (
        <div className="header">

            { name === config.HEADER_TEXT || name === livechat.HEADER_TEXT || isAgentName ?              
                <div className="header__logo">
                    <Brand isLiveChat={ name === livechat.HEADER_TEXT || isAgentName } />
                </div>  :
                <div className="header__back">
                    <img src="/images/back-arrow.svg" 
                        className="header__back-icon"
                        onClick={backToWidget}
                        alt="back" />
                </div>        
            }

            <div className="header__name">
                {name}
            </div>
            <div className="header__buttons">
                <img src="/images/down-arrow.svg" 
                    className="header__min-icon"
                    id="header-minimize-icon"
                    onClick={toggleWidget}
                    alt="minimize" />

                <img src="/images/close.svg" 
                    className="header__close-icon"
                    id="header-close-icon"
                    onClick={closeButton}
                    alt="close" />
            </div>
        </div>
    )
}

export default Header
 
