import React from 'react';
import './arrow.scss';

type arrowProps = {
  direction: string,
  clickHandler: any,
  activeIndex: number
}

const Arrow = ({ direction, clickHandler, activeIndex }: arrowProps) => {
  const getArrowClassName = () => {
    if (direction === "left") {
      return activeIndex === 0 ? "arrow__disabled" : "arrow";
    } else {
      return activeIndex === 4 ? "arrow__disabled" : "arrow";
    }
  };

  const getArrowIconClassName = () => {
    if (direction === "left") {
      return activeIndex === 0 ? "arrow__disabled-left" : "arrow__left";
    } else {
      return activeIndex === 4 ? "arrow__disabled-right" : "arrow__right";
    }
  };

  return (
    <div
      className={getArrowClassName()}
      onClick={clickHandler}
      id={direction === "left" ? "left-arrow-button" : "right-arrow-button"}
    >
      <div className={getArrowIconClassName()}></div>
    </div>
  );
}

export default Arrow;
