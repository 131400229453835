import { Button, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import './AcceptableIds.scss'


type AcceptableIdsProps = {
    setShowAcceptableIds: (type: boolean) => void
}

const useStyles = makeStyles({
    button: {
        backgroundColor: '#4C8251',
        color: '#fff',
        textTransform: 'none',
        fontStyle: 'normal',
        fontSize: '16px',
        padding: '12px 24px',
        transition: 'all .2s ease',
        width: '100%',
        height: '42px',
        fontWeight: 600,

        '&:hover': {
            backgroundColor: '#305233',
            color: '#fff',
        },
        '&:focus': {
            backgroundColor: '#305233',
            color: '#fff',
            border: '2px solid #5795DB'
        },
        '&:active': {
            backgroundColor: '#143017',
            color: '#fff',
        },
        '&:disabled': {
            backgroundColor: '#D1D2D1',
            color: '#fff',
        },
    }
})

const AcceptableIds = ({ setShowAcceptableIds }: AcceptableIdsProps) => {
    const [mountingStyle, setmountingStyle] = useState<string>(' mounting');

    const classes = useStyles();

    const closePopup = () => {
        setmountingStyle(' unmounting');

        let timeoutId: ReturnType<typeof setTimeout> | null = null;

        timeoutId = setTimeout(() => {
            setShowAcceptableIds(false);
        }, 400);

        return () => {
            if (timeoutId) { clearTimeout(timeoutId); }
        }
    }

    return (

        <div className={'widget__overlay--datacollection noPadding' + mountingStyle} onBlur={() => closePopup()}>
            <div className="acceptableIdsDiv">
                <div className="acceptableIdsDiv__details">
                    <div className="acceptableIdsDiv__details__header">
                        <img src='/images/close-black.svg' className='acceptableIdsDiv__details__header__img' onClick={() => closePopup()} />
                    </div>
                    <div className="acceptableIdsDiv__details__info scroller">
                        <div className="acceptableIdsDiv__details__info__header">
                            <img className="acceptableIdsDiv__details__info__header-image" src='/images/membership.svg' />
                            <label className="acceptableIdsDiv__details__info__header-text" >Acceptable IDs</label>
                        </div>
                        <div className="acceptableIdsDiv__details__info__text">
                            <div className="acceptableIdsDiv__details__info__text-header">IDs accepted in store can include:  </div>
                            <div className="acceptableIdsDiv__details__info__text__bulletPoint">
                                <img src='/images/check.svg' className="acceptableIdsDiv__details__info__bulletPoint-check"></img>
                                <label className="acceptableIdsDiv__detail__infos__bulletPoint__label">Passport</label>
                            </div>
                            <div className="acceptableIdsDiv__details__info__text__bulletPoint">
                                <img src='/images/check.svg' className="acceptableIdsDiv__detail__infos__bulletPoint-check"></img>
                                <label className="acceptableIdsDiv__detail__infos__bulletPoint__label">Drivers Licence</label>
                            </div>
                            <div className="acceptableIdsDiv__details__info__text__bulletPoint">
                                <img src='/images/check.svg' className="acceptableIdsDiv__details__info__bulletPoint-check"></img>
                                <label className="acceptableIdsDiv__detail__infos__bulletPointt__label">Proof of age card</label>
                            </div>

                        </div>
                        <div className="acceptableIdsDiv__details__info__text">
                            <label className="acceptableIdsDiv__details__info__text-header">Don’t have any of the IDs above?</label>
                            <label className="acceptableIdsDiv__details__info__text-details">Acceptable ID varies in each state and territory in Australia. Please check your latest state government advice before collecting alcohol.  </label>
                        </div>
                        <div className="acceptableIdsDiv__details__info__warning">
                            <img className="acceptableIdsDiv__details__info__warning-image" src='/images/alert-icon.svg' />
                            <label className="acceptableIdsDiv__details__info__warning-text" >Photos of ID or copies of ID are <span className="acceptableIdsDiv__details__info__warning-text--bold">not accepted</span> as valid forms of ID in store</label>
                        </div>
                        <div className="acceptableIdsDiv__details__info__buttons">
                            <div className='acceptableIdsDiv__details__info__buttons__update' onClick={() => closePopup()}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AcceptableIds