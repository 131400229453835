import { combineReducers } from 'redux'
import botData from './botData'
import fawData from './fawData'
import productData from './productData'

export const rootReducer = combineReducers({
    botData: botData,
    fawData: fawData,
    productData: productData,
  });
  
export type RootState = ReturnType<typeof rootReducer>