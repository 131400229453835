import React, { useEffect, useState } from 'react';

declare global {
    interface Window { KAMPYLE_ONSITE_SDK: any; sf_widget: any; }
}

   type SurveyScreen = {
    setSurveyScreen:(type: boolean) => void,
    setShowWidget:(type: boolean) => void
}

const SurveyScreen = ({setSurveyScreen, setShowWidget}:SurveyScreen) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(()=>{
        const handleOnlineStatus = () => {
            setIsOnline(window.navigator.onLine);
          };
      
          // Add event listeners when the component mounts
          window.addEventListener('online', handleOnlineStatus);
          window.addEventListener('offline', handleOnlineStatus);
      
          // Remove event listeners when the component unmounts
          return () => {
            window.removeEventListener('online', handleOnlineStatus);
            window.removeEventListener('offline', handleOnlineStatus);
          };
    },[]);

    useEffect(() => {
        window?.KAMPYLE_ONSITE_SDK?.updatePageView();
           const containerDiv = document.getElementById('medallia-murphybot-page-survey');
           if (containerDiv) {
             const iframesInContainer = containerDiv.querySelectorAll('iframe');
             if (!iframesInContainer?.length || !isOnline) {
              setShowWidget(false);
              setSurveyScreen(false);
            }
          }
    }, []);

    return (
        <div className="widget__overlay--survey">
            <div id="medallia-murphybot-page-survey">
            </div>
        </div>
    )
}

export default SurveyScreen