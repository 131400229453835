import React from 'react'
import './WineMerchantRedirect.scss' 
import { useDispatch, useSelector  } from 'react-redux';
import { updateCurrentView, showFawPage, resetSelection } from '../../actions/index';
import { DialogflowQueryType } from '../../widget/models/enum';
import { dialogflowEvent } from '../../config/config';
import { postMessageToClient } from '../../widget/chatservice/communicationService';

import { Button, makeStyles } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LaunchIcon from '@material-ui/icons/Launch';

const theme = createTheme({
  typography: {
   "fontFamily": `"DIN 2014","Segoe UI",'Helvetica','Helvetica','sans-serif','Arial'`,
   "fontSize": 14,
  }});

const useStyles = makeStyles({
    primary: {
        backgroundColor: '#4C8251',
        color: '#fff',
        textTransform: 'none',
        fontStyle: 'normal',
        fontSize: '14px',
        height:'36px',
        lineHeight:'21px',
        width:'100%',
        transition: 'all .2s ease',
        '&:hover': {
            backgroundColor: '#305233',
            color: '#fff',
        },
        '&:focus': {
            backgroundColor: '#305233',
            color: '#fff',
            border:'2px solid #5795DB'
        },
        '&:active': {
            backgroundColor: '#143017',
            color: '#fff',
        },
        '&:disabled': {
          backgroundColor: '#D1D2D1',
          color: '#fff',
      },
    },
    secondary: {
      backgroundColor: '#FFFFFF',
      color: '#305233',
      textTransform: 'none',
      fontStyle: 'normal',
      border:'1px solid #305233',
      fontSize: '14px',
      height:'36px',
      lineHeight:'21px',
      width:'100%',
      transition: 'all .2s ease',
      '&:hover': {
          backgroundColor: '#4C8251',
          color: '#FFFFFF',
          border:'1px solid #4C8251'
      },
      '&:focus': {
          backgroundColor: '#4C8251',
          color: '#FFFFFF',
          border:'2px solid #5795DB'
      },
      '&:active': {
          backgroundColor: '#305233',
          color: '#FFFFFF',
          border:'1px solid #305233'
      },
      '&:disabled': {
          backgroundColor: '#D1D2D1',
          color: '#fff',
      },
  }
  })

type WineMerchantRedirectPros = {
    updateWineExpert: (wineExpert: string) => void;
    selectWineExpert : string,
    quickReplyHandler: any
}

 const WineMerchantRedirect = ({updateWineExpert,selectWineExpert,quickReplyHandler}: WineMerchantRedirectPros ) => {
  const dispatch = useDispatch();
  const openTab= (e: any)=>{
    sendAnalyticsTag('web:chatbot:find a wine:find product expert:yes');
    handleDone(e);

    const objCall = {
    data : {},
      methodHandler : "callSalesFloorWidget",
      contextName : "landingPage"
    };

    postMessageToClient(objCall);
  }
  
  const cancelTab = (e: any)=>{
    sendAnalyticsTag('web:chatbot:find a wine:find product expert:no');
    dispatch(updateCurrentView('results'));
  }

  const sendAnalyticsTag = (linkName: string) => {
    const objCall = {
        data : {
            linkName : linkName.toLowerCase(),
            linkType: 'chatbot find a wine',
        },
        methodHandler : "chatbotTriggerAdobeAnalytics"
    }; 
    console.log(linkName);
    postMessageToClient(objCall);
}

  const handleDone = (e: any) => {
    dispatch(showFawPage(false));
    dispatch(resetSelection());
    quickReplyHandler(e, DialogflowQueryType.Event, dialogflowEvent.Chatbot_FAW_Followup_Questions, false);
}

const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
    <div className="mainDiv">
        <div className="expert-wine">
            <img src="./images/benmoroney-expert.png" id="imgBen"  alt=""  />
            <img src="./images/malisacaroselli-expert.png" id="imgMalisa" alt=""/>
            <img src="./images/brucegordon-expert.png" id="imgBruc" alt="" />
        </div>
        <div className="heading">
            <h2>Want expert advice?</h2>
            <p>Connect with our Wine Merchants for personalised recommendations through live chat* or a 1-to-1 appointment.</p>
        </div>
        <div className="heading">
            <small>*Wine Merchants may be offline during busy periods in-store.<br/>
            <strong>Live chat hours:</strong> 10:00am to 4:00pm, Tues to Sat</small>
        </div>
        <div className="divButton">
      <Button className={classes.primary} disableRipple={true} style={{ fontWeight: 600 }} onClick={(event) => openTab(event)} endIcon={<LaunchIcon fontSize="inherit"/>}>Yes, connect with a product expert</Button>
    
      <Button className={classes.secondary} disableRipple={true} style={{ fontWeight: 600 }} onClick={(event) => cancelTab(event)}>No, get wine recommendation</Button>
    
      </div>
    </div>
    </ThemeProvider>
  )
}


export default WineMerchantRedirect
