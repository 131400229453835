/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './messageQrArticle.scss';

type MessageQrArticleProps = {
    message: any,
    clickHandler: any
}

const MessageQrArticle = ({message, clickHandler}: MessageQrArticleProps) => {
    return (
        <div className="messageQrArticle">
            <div className="messageQrArticle__linktitle" onClick={() => clickHandler(message)} tabIndex={0}>
                <span className="messageQrArticle__linktitle-text">{message.text}</span>
            </div>
            <div className="messageQrArticle__linkdesc">
                <span className="messageQrArticle__linkdesc-summary">{message.summary}</span>
                <img src="/images/go-arrow.svg" alt=""></img>
            </div>
        </div>
    )
}

export default MessageQrArticle
