import moment, { Moment } from 'moment';
import React from 'react';
import { RootState } from '../../../reducers/index';
import { useDispatch, useSelector } from 'react-redux';
import { setClickedPickupOrder, setOrderForTile, setShowNomineeChangeScreen, setShowOrderTrackingDetail } from '../../../actions';
import './VerifiedOrderList.scss';
import { getOrderStatus, getShippingType } from './VerifiedOrderUtils';

type VerifiedOrderListProps = {
    orderList: any,
    clickable: boolean
}

const VerifiedOrderList = ({ orderList, clickable }: VerifiedOrderListProps) => {
    const dispatch = useDispatch();
    const userFirstName: string = useSelector((state: RootState) => { return state.botData.userFirstName });

    const changeDetailFlag = (order: any) => {
        if (clickable) {
            if (order.orderType === 'Pickup') {
                var orderStatus = getOrderStatus(order.status)?.pickupStatus?.toLowerCase();
                if (orderStatus && (orderStatus === 'confirmed' || orderStatus === 'awaiting picking' || orderStatus === 'ready to collect' || orderStatus === 'collected' || orderStatus === 'cancelled')) {
                    dispatch(setClickedPickupOrder(order));
                    dispatch(setShowNomineeChangeScreen(false));
                }
            } else if (!order.isSubscription) {
                dispatch(setShowOrderTrackingDetail(true));
                dispatch(setOrderForTile(order));
            }
        }
    }

    return (
        <div className="verifiedOrder__parent">
            {
                orderList.map((data: any, index: number) => {
                    let deliveryDate: Moment;
                    let deliveryTypeString;
                    let estimatedDeliveryDateText = '';
                    let orderDate: Moment;
                    let orderId = data.orderId;
                    let products;
                    let isSubscription = data.isSubscription ?? false;
                    let extraDeliveryInfo = '';
                    let iconUrl = '';
                    let deliveryCount = 0;
                    let orderType = data.orderType;
                    let orderStatus = '';
                    let storeAddress = data.pickUpStoreName + ' store';
                    let pickupIcon: any;
                    let pickupOrderPlacedDate = '';
                    let orderStatusBackground = '';
                    let nominee = data.nomineeDetails;



                    if (isSubscription) {
                        // this is subscription data
                        estimatedDeliveryDateText = moment(data.deliveryDate).format('DD MMM');

                        deliveryTypeString = 'Subscription';
                        orderId = data.orderId;
                        products = data.productImages;
                        extraDeliveryInfo = `${data.quantity} x ${data.schemeName}`;
                        iconUrl = '/images/icon-track-order-subscription.svg';
                    }
                    else if (orderType === 'Pickup') {
                        let { pickupIcon, pickupStatus, statusBackground } = getOrderStatus(data.status);
                        iconUrl = pickupIcon;
                        orderStatus = pickupStatus;
                        orderStatusBackground = statusBackground;
                        orderDate = moment(data.orderDate)
                        pickupOrderPlacedDate = moment(orderDate).format('DD MMM, YYYY');
                        products = data.productImages;
                    }
                    else {
                        deliveryCount = data.deliveryCount;
                        deliveryTypeString = '';
                        orderId = data.orderId;

                        let deliveryDatesAllConsignments = data.consignmentShippingTypes.map((shippingType: string) => {
                            orderDate = moment(data.orderDate);
                            deliveryDate = moment(data.deliveryDate);

                            let { deliveryType, calculatedDeliveryDate, shippingIconUrl } = getShippingType(shippingType, orderDate, deliveryDate);

                            // if the delivery type is Supplier, show a standard delivery
                            deliveryTypeString = (deliveryType === 'Supplier') ? 'Standard' : deliveryType;

                            iconUrl = shippingIconUrl;

                            return calculatedDeliveryDate;
                        });

                        let sortedDates = deliveryDatesAllConsignments.sort(
                            (a: Date, b: Date) => new Date(b).getTime() - new Date(a).getTime()
                        );

                        // get the latest date
                        estimatedDeliveryDateText = moment(sortedDates[0]).format('DD MMM');

                        products = data.productImages;
                    }

                    return (<div className="verifiedOrder__container-pickup" key={"order" + index} onClick={() => changeDetailFlag(data)} >
                        {orderType === 'Pickup' ?
                            <div className={clickable ? 'verifiedOrder__pickup ' : 'disabled'} key={"order" + index} onClick={() => changeDetailFlag(data)}>
                                <div className="verifiedOrder__pickup-orderInfo">
                                    <div className="verifiedOrder__pickup-status">
                                        <div className={"verifiedOrder__pickup-status-label " + orderStatusBackground}>
                                            <img src={iconUrl} alt="" />
                                            <span >{orderStatus}</span>
                                        </div>
                                        <span className="verifiedOrder__pickup-status-orderId">Order #{orderId}</span>
                                    </div>

                                    <div className="verifiedOrder__pickup-storeAddress">
                                        <span>Pick up: {storeAddress}</span> |
                                        <span> Placed at  {pickupOrderPlacedDate}</span>
                                    </div>

                                    {!clickable && (nominee || userFirstName) &&
                                        <div className="verifiedOrder__pickup--nominee-details">
                                            <img src="/images/nominee-icon.svg" alt="" />
                                            <span>{nominee ? nominee : userFirstName} to collect order</span>
                                        </div>
                                    }

                                    <div className='verifiedOrder__container-products'>
                                        {
                                            products?.map((productImage: any, indexinner: number) => {
                                                return (
                                                    indexinner < 4 &&
                                                    <div className="verifiedOrder__container-products--icon" key={"icon" + indexinner}>
                                                        {
                                                            indexinner === 3 && <span>+{products.length - 3} </span>
                                                        }

                                                        {indexinner === 3 &&
                                                            <div className="verifiedOrder__container-products--icon---cover"></div>
                                                        }

                                                        {
                                                            <div className="verifiedOrder__container-products--icon---image" style={{
                                                                backgroundImage: "url(" + productImage + ")",
                                                                backgroundColor: (indexinner === 3) ? '#474947' : 'transparent'
                                                            }}>

                                                            </div>
                                                        }
                                                    </div>
                                                )

                                            })
                                        }
                                    </div>
                                </div>
                            </div> :
                            <div className='verifiedOrder__container'>
                                <div className='verifiedOrder__container-orderinfo'>
                                    <div className='verifiedOrder__container-orderinfo--orderlabel'>
                                        <img src={iconUrl} width="20px" height="20px" alt="icon" />
                                        <span className='verifiedOrder__container-orderinfo--ordertype'>{deliveryTypeString}</span>
                                    </div>
                                    <span className='verifiedOrder__container-orderinfo--orderdate'>By {estimatedDeliveryDateText}</span>
                                </div>
                                {
                                    !isSubscription && <div className='verifiedOrder__container-ordernumber'>
                                        #{orderId} {deliveryCount > 1 && <span> • Total of <strong>{deliveryCount}</strong> deliveries</span>}
                                    </div>
                                }

                                {
                                    isSubscription && <div className='verifiedOrder__container-ordernumber'>
                                        {extraDeliveryInfo}
                                    </div>
                                }

                                <div className='verifiedOrder__container-products'>
                                    {
                                        products?.map((productImage: any, indexinner: number) => {
                                            return (
                                                indexinner < 4 &&
                                                <div className="verifiedOrder__container-products--icon" key={"icon" + indexinner}>
                                                    {
                                                        indexinner === 3 && <span>+{products.length - 3} </span>
                                                    }

                                                    {indexinner === 3 &&
                                                        <div className="verifiedOrder__container-products--icon---cover"></div>
                                                    }

                                                    {
                                                        <div className="verifiedOrder__container-products--icon---image" style={{
                                                            backgroundImage: "url(" + productImage + ")",
                                                            backgroundColor: (indexinner === 3) ? '#474947' : 'transparent'
                                                        }}>

                                                        </div>
                                                    }
                                                </div>)

                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    )
                })

            }
        </div>
    )
}

export default VerifiedOrderList