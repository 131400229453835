import React, { useState } from "react";
import "./LiveChatTransfer.scss";
import { isValidEmail } from "../../util/validators";
import Checkbox from "../../checkbox/checkbox";
import { setShowNomineeChangeFailedScreen, setShowNomineeChangeScreen } from "../../../actions";
import { useDispatch } from "react-redux";

type LiveChatTransferProps = {
  setName: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  name: string;
  backToWidget: (voted: boolean) => void;
  closeUserForm: () => void;
  isHostDevice: boolean;
  showNomineeChangeFailedScreen:boolean;
  setliveChatTransfer:(type: boolean) => void,
};

const LiveChatTransfer = ({
  setName,
  setEmail,
  email,
  name,
  backToWidget,
  closeUserForm,
  isHostDevice,
  showNomineeChangeFailedScreen,
  setliveChatTransfer
}: LiveChatTransferProps) => {
  const [consent, setConsent] = useState<boolean>(false);
  const [displayImg, setDisplayImg] = useState<boolean>(true);
  const dispatch = useDispatch();

  const cancel = () => {
    if (showNomineeChangeFailedScreen) {
      setliveChatTransfer(false);
    } else {
      backToWidget(true);
    }
  };

  const replaceImage =(error :any)=>{

    setDisplayImg(false);
  }

  const connectToLiveChat = ()=> {
    closeUserForm();
    dispatch(setShowNomineeChangeFailedScreen(false));
    dispatch(setShowNomineeChangeScreen(false));
  }



  const privacyTextLabel = `I agree that Dan Murphy's will retain a copy of the chat history and will collect and use any personal information provided by me in accordance with the&nbsp;
                              <a href="https://www.danmurphys.com.au/help/help-centre/articles/360000505355-Dan-Murphy-s-Collection-Statement" target="_blank" rel="noopener noreferrer">
                              Dan Murphy's Collection Statement.</a>`;

  return (
    <div
      className={"widget__overlay--livechat" + (isHostDevice ? " device" : "")}
    >
      <div className="widget_to_liveChat">
        {displayImg? <img
          src="/images/MurphyBot-To-Live_Chat.png"
          onError={replaceImage}
        /> : <div className="noImg">
          
          <span><b> Alt text:</b><br/>Transferring MurphyBot to our live chat agent</span>
          </div>}
        
      </div>
      <div className="textMsg">
        <p>Won't be long {name.split(" ")[0]}, I'm finding someone to chat with you.</p>
      </div>
     
      <div className="widget__overlay--livechat-form-privacy-consent checkBox">
        <Checkbox 
          label={privacyTextLabel}
          setCheckboxValue={setConsent}
        ></Checkbox>
        
      </div>
      <div className="widget__overlay--livechat-form-button-container buttons">
        <input
          id="cancel"
          type="button"
          value="Cancel"
          className="form-button secondary"
          onClick={cancel}
        ></input>
        <input
          id="continue"
          type="button"
          value="Continue"
          className="form-button primary"
          disabled={
            name === "" || email === "" || !isValidEmail(email) || !consent
          }
          onClick={connectToLiveChat}
          tabIndex={0}
        ></input>
      </div>
    </div>
  );
};

export default LiveChatTransfer;
