const config = {
    CHATSERVER_URL: 'https://api.edg.com.au/murphybot/chatserver',
    HEADER_TEXT: "Murphy",
    ARTICLE_TEXT: "Help",
    TICKET_FORM_HEADER_TEXT: "Contact Us",
    MESSAGE_SENT: "Message Sent",
    ZENDESK_FORM_ID: "",
    DAN_HELP_CENTRE_URL: "https://www.danmurphys.com.au/help/help-centre",
    DAN_CONTACT_US_FORM_URL: "https://www.danmurphys.com.au/help/help-centre/suam?source=bot",
    DAN_MY_ACCOUNT_URL: "https://www.danmurphys.com.au/dm/mydans/my_account",
    ENDEAVOUR_DELIVERY_URL: "https://endeavourdelivery.com.au/track?search=",
    AUSPOST_URL: "https://auspost.com.au/mypost/track/#/details/",
    FRIENDLY_ERROR_MESSAGE: "Sorry but I’m having some technical issues. I’ll be back shortly",
    IDLE_MESSAGE: "Looks like you don't need any more help! I'm going to leave you alone now. I'm still here if you need me. You can close the chat window to end our chat or ask me another question.",
    IDLE_MESSAGE_FOLLOW: "Ask another question",
    IDLE_TIMEOUT: 1000 * 60 * 10,
    SESSION_ALIVE_PING: 1000 * 60 * 8,
    AUTOEND_TIMEOUT: 1000 * 60 * 5,
    COOKIES_CHATBOT_USER_ID_TEXT: "chatbotUserId",
    COOKIES_ARTICLE_TOKEN_TEXT: "chatbotLCArticleToken",
    COOKIES_LIVECHAT_SESSION: "chatbotDMSession",
    TRACK_ORDER_EMPTY_STATUS: "Please click the link below to view more details",
    ZENDESK_LIVECHAT_DEPARTMENT_ID : '2148005161',
    ZENDESK_LIVECHAT_GROUP_ID : '360004244916',
    ZENDESK_CHAT_CONNECTION : '87EvB4k0kjj7gEY7DKldGnhhYlmA23wB',
    COOKIE_DOMAIN : 'prdedgccslivraue.z8.web.core.windows.net',
    APIMGT_KEY: '7eb95db69b8a4a3a85ca9eb9a52e43b8',
    FACEBOOK_CLOSE_WINDOW_URL: "https://www.messenger.com/closeWindow/?image_url=''&display_text=Closing%20window",
    ALLOWED_CHARACTERS: /^[a-zA-Z0-9?*!:.,$%#_\\-\\~\\ô\\&\\+\\ö\\é\\è\\(\\)\\/\\'\\%\\ñ\\:\\ô\\"\\@\\â\\ë\\á\\Ä\\°\\â\\«\\»\\ö\\ò\\É\\É\\’\s`]+$/,
    ISSTAGING: false,
    SOURCE: null
}

export {
    config
}