import React, { useEffect } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import './cancelFaw.scss';
import { updateCurrentView, showFawPage, resetSelection } from '../../actions/index';
import { RootState } from '../../reducers/index';

import { dialogflowEvent } from '../../config/config';
import { DialogflowQueryType } from '../../widget/models/enum';
import { postMessageToClient } from '../../widget/chatservice/communicationService';

type CancelProps = {
  quickReplyHandler: (event:any, queryType : DialogflowQueryType, eventText: string, showText: boolean) => void,
  endScreenMountingStyle:string,
  setisOverlayScreenMounted:React.Dispatch<React.SetStateAction<boolean>>
}

const CancelFaw = ({quickReplyHandler, endScreenMountingStyle, setisOverlayScreenMounted}: CancelProps) => {

  const dispatch = useDispatch();
  const lastSelectedScreen = useSelector((state: RootState) => { return state.fawData.lastSelectedScreen});
  
  const getStepNumber = () => {
    switch(lastSelectedScreen) {
        case 'type':
            return 1;
        case 'price':
            return 2;
        case 'varietal':
            return 3;
        default:
    }
}

  const onCancel = (e: any) => {
    dispatch(showFawPage(false));
    dispatch(resetSelection());
    const linkName = `web:chatbot:find a wine:preference:step${getStepNumber()}:cancel`;
    postMessageToClient({
      data : {
          linkName : linkName.toLowerCase(),
          linkType: 'chatbot find a wine',
      },
      methodHandler : "chatbotTriggerAdobeAnalytics"
    })
    
    quickReplyHandler(e, DialogflowQueryType.Event, dialogflowEvent.Chatbot_Additional_Questions, false);
  }

  const onTakeMeBack = () => {
    setisOverlayScreenMounted(false);
    dispatch(updateCurrentView(lastSelectedScreen));
  }
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;
    
    window.onclick = (event:Event) => {
        const { className } = event.target as HTMLElement;
        if(className == "overlay-slideup"){
          onTakeMeBack();
        }
    }
    
    return () => {
        if(timeoutId) { clearTimeout(timeoutId);}
    }
}, [])
  return (
    <div className={"cancel-faw" + endScreenMountingStyle}>  
     
      <div className="warning-text">
        <div>No longer need a wine recommendation?</div>
        <div className="cancel-text">If you leave now, you'll lose your current selection & I can't show you Dan's super wines.</div>
      </div>

      <div className="action-buttons">
          <input type="button" value="No, go back" id="faw-back" className='form-button secondary' onClick={ () => onTakeMeBack() }/>
          <input type="button" value="Leave" id="faw-cancel" className='form-button primary' onClick={ (event) => onCancel(event) }/>
      </div>

    </div>
  )
}
  
export default CancelFaw