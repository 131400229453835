/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './message.scss';
import MessageLink from './MessageLink';
import MessageQuickEvent from './MessageQuickEvent';

type messageQrDynamicProp = {
    message: any,
    quickReplyHandler: any,
    sessionId?: any,
    userId?: any,
    indexParent: any,
    msgs: any
}

const MessageQrDynamic = ({message, quickReplyHandler, sessionId, userId, indexParent, msgs}: messageQrDynamicProp) => {
    let callbackType = "";
    const callback = message.data.callback;

    if (callback !== undefined && callback !== null) {
        if (callback.hasOwnProperty('url')) {
            callbackType = "link";
        } else if (callback.hasOwnProperty('event')) {
            callbackType = "event";
        }
    }

    return (
        <div>
            {callbackType === 'link' && <MessageLink text={message.text} link={callback.url} sessionId={sessionId} userId={userId} intentGroupId={ message.intentGroupId } type={ message.type } indexParent={indexParent} msgs={msgs}/>}
            {callbackType === 'event' && <MessageQuickEvent text={message.text} quickReplyHandler={quickReplyHandler} eventName={callback.event} showTextResponse={false} indexParent={indexParent} msgs={msgs} />}
        </div>       
    )
}

export default MessageQrDynamic



