import { config, storeConfig } from '../../config/config';

const handleMessage = (message: any) => {
    if (message?.length > 1)
    {
        message[0].hasQr = true;

        if (message[1].type === "interaction_access_token") {
            for (let i = 2; i < message.length; i++) {
                message[i].data.token = message[1].text;
            }
        } else if (message[1].type === "store" || message[1].type === "store-select") {
            const first = {text: message[0].text, type: "message", hasQr: true};
            let stores = message.slice(1);
            stores = stores.slice(0, storeConfig.STORE_NUMBER_IN_CAROUSEL)
            const result = [first, {text: "", type: "store", data: stores}];
            return result;
        } 
    } else if (message === null || message === undefined || 
        message[0] === null || message[0] === undefined ||
        message[0].text === null || message[0].text === undefined ||
        message[0].text.trim() === "") { 
        message = [{text: config.FRIENDLY_ERROR_MESSAGE, type: "message"}];
    }
    
    return message;
}

const handleMessageNewRoutine = (message: any) => {

    const messageArray:any = [];
    let interaction_access_token = "";
    if (message?.length > 1)
    {
        let storePayload: any = [];
        let paStoreSelectPayload: any = [];
        let productListPayload: any = [];
        let productNearbyStoresPayload = [];
        let selectStorePayload: any = [];
        
        for (let i = 0; i < message.length; i++) {
            if (i + 1 < message.length && message[i + 1].type !== 'message' && message[i + 1].type !== 'session_id') {
                message[i].hasQr = true;
            }

            if (message[i].type === 'interaction_access_token') {
                interaction_access_token = message[i].text;
            }

            if (message[i].type === "store") {
                if (storePayload.length < storeConfig.STORE_NUMBER_IN_CAROUSEL)
                storePayload.push(message[i]);
            } else if (message[i].type === "pa-store-select") {
                message[i].isSelected = false;
                if (paStoreSelectPayload.length < storeConfig.STORE_NUMBER_IN_CAROUSEL)
                    paStoreSelectPayload.push(message[i]);
            } else if (message[i].type === "product-list") {
                if (productListPayload.length < storeConfig.STORE_NUMBER_IN_CAROUSEL)
                    productListPayload.push(message[i]);
            }  
            else if (message[i].type === "product-nearby-store-list") {
                if (productNearbyStoresPayload.length < storeConfig.STORE_NUMBER_IN_CAROUSEL)
                productNearbyStoresPayload.push(message[i]);
            } else if (message[i].type === "store-select") {
                if (selectStorePayload.length < storeConfig.STORE_NUMBER_IN_CAROUSEL)
                selectStorePayload.push(message[i]); 
            } else if (message[i].type === "article") {
                message[i].data.token = interaction_access_token;
                messageArray.push(message[i]);
            } else {
                messageArray.push(message[i]);
            }
        }

        if (storePayload.length > 0) {
            messageArray.push({
                text: "",
                type: "store", 
                data: storePayload
            })
        }

        if (paStoreSelectPayload.length > 0) {
            messageArray.push({
                text: "",
                type: "pa-store-select", 
                data: paStoreSelectPayload
            })
        }

        if (productListPayload.length > 0) {
            messageArray.push({
                text: "",
                type: "product-list", 
                products: productListPayload
            })
        }

        if(productNearbyStoresPayload.length > 0){
            messageArray.push({
                text: "",
                type: "product-nearby-store-list", 
                stores: productNearbyStoresPayload
            })
        }

        if (selectStorePayload.length > 0) {
            messageArray.push({
                text: "",
                type: "store-select", 
                data: selectStorePayload
            })
        }

        return messageArray;
    } else if ((message === null || message === undefined || 
        message[0] === null || message[0] === undefined ||
        message[0].text === null || message[0].text === undefined ||
        (!(message[0].text instanceof Array) && message[0].text.trim() === "")) && (message[0].data == null)) {
        message = [{text: config.FRIENDLY_ERROR_MESSAGE, type: "message"}];
    }
    return message;
}

export {
    handleMessage,
    handleMessageNewRoutine
}