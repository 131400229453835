import React, { useEffect, useRef } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

type ManualEndSessionProps = {
    endBotSession: (value: boolean) => void;
    timeoutType: number;
    endScreenMountingStyle: string;
}

const theme = createTheme({
    typography: {
        "fontFamily": `"din-2014","Segoe UI",'Helvetica','Helvetica','sans-serif','Arial'`,
        "fontSize": 14
    }
});

const useStyles = makeStyles({
    button: {
        backgroundColor: '#FFFFFF',
        color: '#305233',
        textTransform: 'none',
        fontStyle: 'normal',
        border: '1px solid #305233',
        transition: 'all .2s ease',
        padding: '12px 24px 12px 24px',
        height: '42px',
        fontFamily: 'din-2014',
        fontWeight: 600,
        fontSize: '16px',

        '&:hover': {
            backgroundColor: '#4C8251',
            color: '#FFFFFF',
            border: '1px solid #4C8251'
        },
        '&:focus': {
            backgroundColor: '#4C8251',
            color: '#FFFFFF',
            border: '2px solid #5795DB'
        },
        '&:active': {
            backgroundColor: '#305233',
            color: '#FFFFFF',
            border: '1px solid #305233'
        },
    },
    primary: {
    backgroundColor: '#4C8251',
    color: '#fff',
    border: 'none',
    outline: 'none',
    cursor: 'pointer'

    }
})

const ManualEndSession = ({ endBotSession, timeoutType, endScreenMountingStyle }: ManualEndSessionProps) => {

    const classes = useStyles();
    // 1 user exits from the cross
    // 2 timeout text after 10 mins
    // 3 user ends after query
    // 4 close after live chat

    const endSession = useRef<HTMLDivElement>(null);
    let screenText;

    switch (timeoutType) {
        case 1: screenText = `<div>Have you finished your chat?</div>`;
            break;
        case 2:
            screenText = `<div>Looks like you don't need any more help.</div>
                          <div class="cancel-text">Have you finished your chat?</div>`;
            break;
        case 3:
            screenText = `<div>No problem, I enjoyed our chat, stop by
                           again sometime.</div>
                          <div class="cancel-text">Have you finished your chat?</div>`;
            break;
        case 4:
            screenText = `<div>Thank you for your feedback.</div>
                          <div class="cancel-text">Have you finished your chat?</div>`;
            break;
        default:
            screenText = `<div>No problem, I enjoyed our chat, stop by
                          again sometime.</div>
                          <div class="cancel-text">Have you finished your chat?</div>`;
            break;
    }

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout> | null = null;
        
        window.onclick = (event:Event) => {
            const { className } = event.target as HTMLElement;
            if ( endSession.current && !endSession.current.contains(event.target as HTMLElement) && className && className.indexOf('header') && className.indexOf('brand') ) {
                timeoutId =  setTimeout(() => {
                    endBotSession(false)
                }, 450);
            }
        }
        
        return () => {
            if(timeoutId) { clearTimeout(timeoutId);}
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <div className={"widget__overlay--endsession" + endScreenMountingStyle} ref={endSession}  id="endSession">  
                {/* {timeoutType === 1 ? <div className="warning-icon">
                    <img className="star" src="/images/alert-icon.png" />
                </div>: null} */}

                <div className="warning-text" dangerouslySetInnerHTML={{ __html: screenText }}></div>

                <div className="action-buttons">
                    <Button id="no-button" disableRipple={true} className={classes.button} style={{ width:  '170px', letterSpacing: 'initial' }} onClick={() => endBotSession(false)}>Return to chat</Button>
                    <Button id="yes-button" disableRipple={true} className={classes.button + ' ' +classes.primary} style={{ width: '139px', letterSpacing: 'initial' }} onClick={() => endBotSession(true)}>Finished</Button>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default ManualEndSession