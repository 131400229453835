import React, { useState, useEffect } from 'react';
import './ticketForm.scss';
import { getFormDetails, createTicket } from '../chatservice/zendeskApis';

type TicketFormProps = {
    showThankYou: () => void
}

const TicketForm = ({showThankYou}: TicketFormProps) => {
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [tellMeMore, setTellMeMore] = useState<string>("");
    const [subjects, setSubjects] = useState<Array<string>>([]);
    const [selectedSubject, setSelectedSubject] = useState<string>("");

    const handleSubmit = (e: any) => {
        e.preventDefault();
        showThankYou();
        //alert('name-' + name + ' email-' + email + ' tell me more-' + tellMeMore + ' subject-' + selectedSubject );

        //todo: confirm each field
        createTicket({
            "ticket": {
              "subject":   selectedSubject,
              "comment":   { 
                  "body": tellMeMore 
              },
              "requester": { "locale_id": 1, "name": name, "email": email },
              "custom_fields": [
                  {
                      "id": 81551748, 
                      "value": "delivery__product_related__missing"
                  }
              ]
            }
          });
    }

    const handleChange = (e: any) => {
        setSelectedSubject(e.target.value);
    }

    useEffect(() => {
        // todo
        //getFormDetails().then(result => { setSubjects(result); });      
        
        // todo: mock
        setSubjects(["Payment", "Delivery", "Store Location", "Others"]);
    }, [])

    return (        
        <form className="ticket__form" onSubmit={handleSubmit}>
            <div className="ticket__container">
                <input className="ticket__input ticket__input--small" placeholder="Name" type="text" value={name} onChange={(e => setName(e.target.value))} required/>
                <input className="ticket__input ticket__input--small" placeholder="Email address" type="text" value={email} onChange={(e => setEmail(e.target.value))} required/>
                <select className="ticket__input ticket__input--small ticket__input--dropdown" name="Subject" id="subject" onChange={handleChange} required>
                    <option value="" disabled selected hidden>Subject</option>
                    {subjects.map((s: string) => (
                        <option value={s}>{s}</option>
                    ))}                              
                </select>
                <textarea className="ticket__input ticket__input--large" id="tell-us-more" name="tell-us-more" placeholder="Tell us more" value={tellMeMore} onChange={(e => setTellMeMore(e.target.value))} required/>                
            </div>
            <input className="ticket__submit" type="submit" value="Submit"></input>                
        </form>    
    )
}

export default TicketForm
