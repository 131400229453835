import React from 'react'
import './message.scss';
import { DialogflowQueryType } from '../models/enum';
import { getUniqueId, removeIntents } from '../../widget/util/commonUtil';

type messageQuickEventProps = {
    text: string,   
    quickReplyHandler: any, 
    eventName: string,
    showTextResponse: boolean,
    indexParent: number,
    msgs: any
}

const MessageQuickEvent = ({text, quickReplyHandler, eventName, showTextResponse, indexParent, msgs}: messageQuickEventProps) => {

    const onClickHandler = (e: any) => {
        quickReplyHandler(e, DialogflowQueryType.Event, eventName, showTextResponse);
        removeIntents(msgs, indexParent)
    }

    return (
        <div className="message__text--quick-reply">       
            <span onClick={onClickHandler} className="message__text--click" id={getUniqueId('message', text)}>{text}</span>       
        </div>
    )
}

export default MessageQuickEvent
