import React, { useRef, useState } from 'react';
import './product-list-detail.scss';
import '../message/message.scss';
import { postMessageToClient } from '../chatservice/communicationService';
import { DialogflowQueryType } from '../models/enum';
import { dialogflowEvent } from '../../config/config';
import { updateAnalyticsStatus } from '../chatservice/dialogflowQuery';

import { Button, makeStyles } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

type ProductListDetailProps = {
    sessionId: any,
    userId: any,
    product: any,
    selectedStore: any,
    quickReplyHandler: any,
    toggleWidget: any
}

const theme = createTheme({
    typography: {
     "fontFamily": `"DIN 2014","Segoe UI",'Helvetica','Helvetica','sans-serif','Arial'`,
     "fontSize": 14
    }});

const useStyles = makeStyles({
    button: {
        backgroundColor: '#FFFFFF',
        color: '#305233',
        textTransform: 'none',
        fontStyle: 'normal',
        padding: '7.5px 24px 7.5px 24px',
        borderLeft:'1px solid #eee',
        borderRight:'1px solid #eee',
        borderBottom:'1px solid #eee',
        borderRadius:'0px',
        fontSize:'small',
        transition: 'all .2s ease',
        width:'100%',
        '&:hover': {
            backgroundColor: '#F8F8F8',
            color: '#305233',
        },
        '&:focus': {
            backgroundColor: '#F8F8F8',
            color: '#305233',
            border:'2px solid #5795DB'
        },
        '&:active': {
            backgroundColor: '#F3F3F3',
            color: '#474947',
            border:'none'
        }
    }
    })     


const formImageUrl = (url: string) => {
    return url.substring(url.lastIndexOf('/') + 1);
}

const getPackType = (product: any) => {
    return product.availablePackTypes.sort((a: any, b: any) => a.UnitQty > b.UnitQty ? 1 : -1)[0];
}

const getInventoryStatus = (product: any) => {
    const packTypeToShow = getPackType(product);
    if (packTypeToShow.Key === 'Bottle') {
        return `${product?.availableInventoryQty} ${packTypeToShow.DisplayName} at ${formatStoreName(product.storeName)}`;
    }
    return `${Math.ceil(product?.availableInventoryQty / packTypeToShow.UnitQty)} ${packTypeToShow.DisplayName} of ${packTypeToShow.UnitQty} at ${formatStoreName(product.storeName)}`;
}

const formatStoreName = (storeName: any) => {
    const store = storeName.replaceAll("Dan Murphy's ", "");
    return store.charAt(0).toUpperCase() + store.slice(1).toLowerCase();
}

const isMarketPlaceProduct = (product: any) => {
    return product?.webdsvflag && product?.stockCode?.startsWith("ER_");
}

const ProductListDetail = ({sessionId, userId, product, selectedStore, quickReplyHandler, toggleWidget }: ProductListDetailProps) => {
    const fileName = formImageUrl(product.images.small);
    let stockText = "";
    
    if (isMarketPlaceProduct(product)) {
        //Market place products
        stockText = "Delivery only";
    } else if (product.isDeliveryOnly || (parseInt(product?.availableInventoryQty ?? 0) <= 0)) {
        stockText = `Out of stock at ${formatStoreName(product.storeName)}`;
    } else {
        stockText = getInventoryStatus(product);
    }

    let displayPrice = 0;
    let displayMessage = '';

    if (Object.keys(product.priceSequence).length > 0) {
        if (product.priceSequence.optionalPrice && product.priceSequence.optionalPrice.Value !== 0) {
            displayPrice = product.priceSequence.optionalPrice.Value;
            displayMessage = product.priceSequence.optionalPrice.Message;
        } else {
            displayPrice = product.priceSequence.primaryPrice ? product.priceSequence.primaryPrice.Value : 0;
            displayMessage = product.priceSequence.primaryPrice ? product.priceSequence.primaryPrice.Message : ''
        }
    }

    const prodDetailsUrl = `/product/DM_${product.stockCode}/${product.urlFriendlyName}`;
    const onMoreDetailsClickHandler = (e: any) => {
        const analyticsCall = {
            data: {
                linkName: `web:chatbot:product availability:more details:${product.productName}`,
                linkType: 'chatbot product availability',
            },
            methodHandler: "chatbotTriggerAdobeAnalytics"
        };
        postMessageToClient(analyticsCall);

        // init change url
        const objCall = {
            data: {
                prodDetailsUrl: prodDetailsUrl,
                storeId: product.storeId,
                storeName: formatStoreName(product.storeName),
                queryString: { i_cid: 'cbprodavail' }
            },
            methodHandler: "chatbotInitChangeUrl"
        };
        // minimize widget
        toggleWidget(false);
        postMessageToClient(objCall);

        // post message to chatserver, change analytics status to success
        updateAnalyticsStatus(sessionId, userId, product.intentGroupId, true);
    }

    const onNearbyStoreClickHandler = (e: any) => {
        const params: any = {
            suburb: selectedStore.suburb,
            postcode: selectedStore.postCode,
            productData: { productName: product.productName, stockCode: product.stockCode, productDetailsUrl: prodDetailsUrl, availablePackTypes: product.availablePackTypes },
            state: selectedStore.state,
            selectedStoreId: selectedStore.storeId
        }
        quickReplyHandler(e, DialogflowQueryType.Event, dialogflowEvent.PRODUCT_AVAIL_FIND_NEARBY_STORES, false, params);
    }
    const classes = useStyles();
    return (
        <div className='store'>
            <div className='product__details'>
                <div className='product__image'><img src={`https://media.danmurphys.com.au/dmo/product/${fileName}?impolicy=PROD_SM`} alt="product" /></div>
                <div className='product__brand'>{product.productBrand} </div>
                <div className='product__title'>{product.productName} </div>
                <p className='product__stock'>{stockText}</p>
                {product.isDeliveryOnly && !isMarketPlaceProduct(product) && <p className='product__deliveryText'>Available for delivery</p>}
                {displayPrice > 0 && <div className='product__pretext'>
                    <span className={(product.isMemberOffer) ? 'product__pretext--memberoffer' : 'product__pretext--nonmemberoffer'}>{(product.isMemberOffer || product.isOnlineOffer) && product.priceSequence.optionalPrice.PreText}</span>
                    {product.isClearance && <span className='product__pretext--nonmemberoffer'>CLEARANCE</span>}
                </div>}
                {displayPrice > 0 && <div className='product__price'><span className='product__price--value'>${displayPrice.toFixed(2)}</span> <span className='product__price--message'>{displayMessage}</span></div>}
                {(product.isOnlineOffer || product.isMemberOffer) && product.nonMemberPrice > 0 && <div className='product__secondaryprice'>Non-Member ${product.nonMemberPrice.toFixed(2)} </div>}
            </div>

            <div>
                <Button className={classes.button} disableRipple={true} id="more-details-link" onClick={onMoreDetailsClickHandler}>More details</Button>
               
            </div>
                {!isMarketPlaceProduct(product) && <div>
                    <Button className={classes.button} disableRipple={true} onClick={onNearbyStoreClickHandler} id="find-nearby-link">Find nearby</Button>
                    
                </div>}
        </div>
    );
}

export {
    ProductListDetail
}