import React from 'react';
import '../../message.scss';
import '../messageOrderTracking.scss';
import { config, orderTrackingStatusConfig, orderTrackingConfig } from '../../../../config/config';
import { displayCourierNameText } from '../orderTrackingService';
import MessageLink from '../../MessageLink';
import MessageFailed from './MessageFailed';
import MessageDelayed from './MessageDelayed';
import MessageInProgress from './MessageInProgress';
import MessageDelivered from './MessageDelivered';
import MessageAusPost from './MessageAusPost';

type messageOrderTrackingSingleProps = {
    message: any,
    toDate: any,
    mapStatus: any,
    indexParent: number,
    msgs: any
};

const MessageOrderTrackingSingle = ({message, toDate, mapStatus, indexParent, msgs}: messageOrderTrackingSingleProps) => {
    const consignment = message.data.trackingInfo[0].consignmentNotes[0];
    const orderNumber = message.data.searchTermOrderNumber;    
    const mappedStatus = mapStatus(consignment.status, consignment.expectedDeliveryDate);
    const isDeliveryFailed = mappedStatus === orderTrackingStatusConfig.DELIVERY_FAILED;
    const isDelivered = mappedStatus === orderTrackingStatusConfig.DELIVERED;
    const isDelayed = mappedStatus === orderTrackingStatusConfig.DELAYED;
    const label = mappedStatus === orderTrackingStatusConfig.DELIVERED ? "delivered" : "due";
    const dateDisplayText = toDate(isDelivered ? consignment.statusDate : consignment.expectedDeliveryDate);
    const isAusPost = consignment.courierName === "AusPost";
    const courierName = displayCourierNameText(consignment);

    if(isAusPost) {
        return (
            <MessageAusPost orderNumber={orderNumber} orderTrackingUrl={consignment.trackingURL} indexParent={indexParent} msgs={msgs}/>          
        );
    }
    else {        
        return (
            <div>
                <div className="message__text">
                    {isDeliveryFailed && <MessageFailed orderNumber={orderNumber} dateDisplayText={dateDisplayText} />}
                    {mappedStatus === 'N/A' && <div className="orderTrackingMessage__delivery">{config.TRACK_ORDER_EMPTY_STATUS}</div> } 
                    {mappedStatus !== 'N/A' && 
                        (isDelivered ? <MessageDelivered deliveredObj={{orderNumber: orderNumber, dateDisplayText : dateDisplayText, courierName: courierName, barCodeNumber: consignment.barCodeNumber}} /> 
                            : isDelayed ? <MessageDelayed orderNumber={orderNumber}/>
                                : <MessageInProgress courierName={courierName} status={mappedStatus.toLowerCase()} label={label} orderNumber={orderNumber} dateDisplayText={dateDisplayText} barCodeNumber={consignment.barCodeNumber} />
                        )
                    }
                </div>
                <MessageLink text={isDeliveryFailed ? orderTrackingConfig.REBOOK_REPLY_TEXT : orderTrackingConfig.QUICK_REPLY_TEXT} link={consignment.trackingURL} indexParent={indexParent} msgs={msgs} />
            </div>
        );
    }
}

export default MessageOrderTrackingSingle;
