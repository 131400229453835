import React from 'react'
import { isValidEmail } from '../../util/validators';

type LiveChatUserFormWebProps = {
    setName: React.Dispatch<React.SetStateAction<string>>;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    setPrivacyConsent: React.Dispatch<React.SetStateAction<boolean>>;
    email: string;
    name: string;
    privacyConsent: boolean;
    backToWidget: (voted: boolean) => void;
    closeUserForm: () => void;
}

const LiveChatUserFormWeb = ({setName, setEmail, setPrivacyConsent, email, name, privacyConsent, backToWidget, closeUserForm}: LiveChatUserFormWebProps) => {
    const cancel = (e: any) => { 
        backToWidget(true);
    }

    return (
        <div className="widget_livechat">
            <div className="toolbar-livechat">
                <img src={"/images/chatbot-murphy.png"} alt="Murphy" className="livechat_logo" />
                <span className="livechat_title">Live Chat</span>
            </div>
       
        <div className="widget_livechat_web">
        <span className="form-title">Please tell me your name and email address below.</span>
        <form className="widget_livechat_web_form">
            {/* user name */}
            <div className="form-input">
                <label htmlFor="userName">Name</label>
                <input type="text" name="userName" autoFocus value={name} onChange={(e => setName(e.target.value))} required/>
            </div>
           
           
            {/* email address */}
            <div className="form-input">
                <label>Email address</label>
                <input type="text" value={email} onChange={(e => setEmail(e.target.value))} required/>
            </div>
            
            <div className="privacy-consent">
                    <input type="checkbox"  name="privacyConsent" defaultChecked={privacyConsent} onChange={() => setPrivacyConsent(!privacyConsent)}/>
                    
                    <label>I agree that Dan Murphy's will retain a copy of the chat history and will collect and use any personal information provided by me in accordance with the&nbsp;
                        <a href="https://www.danmurphys.com.au/help/help-centre/articles/360000505355-My-Dan-s-Collection-Statement" target="_blank" rel="noopener noreferrer">Dan Murphy’s Collection Statement.</a>
                    </label>
            </div>    
            
            <input type="button" value="Cancel" className="form-button secondary" onClick={ cancel }></input>
            <input type="button" value="Continue" className="form-button primary" disabled={name === '' || email === ''  || !isValidEmail(email) || !privacyConsent} onClick={ closeUserForm }></input>
        </form>
        </div>
    </div>
    )
}

export default LiveChatUserFormWeb