import React, { useState } from 'react';
import { config } from '../config/config';
import LiveChatUserForm from '../widget/message/livechat/LiveChatUserForm';
import LiveChatUserFormWeb from '../widget/message/livechat/LiveChatUserFormWeb';
import './privacyForm.scss';
import axios from 'axios/index';

declare global {
    interface Window { 
        WebviewSdk: any,
        MessengerExtensions: any
    }
}

const PrivacyForm = (props: any) => {
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [privacyConsent, setPrivacyConsent] = useState<boolean>(false);

    const closeForm = () => {
        if (window.WebviewSdk.hasFeature('close') || window.WebviewSdk.features.includes("close")) {
            window.WebviewSdk.close();
        } else {
            window.MessengerExtensions.requestCloseBrowser();
        }
    }

    const submit = async () => {
        const requestConfig = {
            headers: { 
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': config.APIMGT_KEY
            },
        }

        const body = { 
            userId: props.userId,
            conversationId: props.conversationId,
            appId: props.appId,
            projectId: props.projectId,
            userName: name,
            userEmail: email
        };

        axios.post(`${config.CHATSERVER_URL}/api/livechat/form/submit`, body, requestConfig).then(_ => {
            closeForm();
        });
    }
    
    return (
        <div>
            <LiveChatUserFormWeb 
                        setName={ setName } 
                        setEmail = { setEmail } 
                        setPrivacyConsent = { setPrivacyConsent }
                        email = { email } 
                        name = { name } 
                        privacyConsent = { privacyConsent }
                        backToWidget = { closeForm } 
                        closeUserForm = { submit }/>
        </div>
      )
}
  
export default PrivacyForm




