import * as ACTIONS from '../actions/actionTypes';
const defaultState = {
  isFetching: false,
  productArray: [],
  errorMsg:'',
}
const productData = (state = defaultState, action: any) => {
  switch (action.type) {
    case ACTIONS.REQUEST_PRODUCTS:
      return {
        ...state,
          isFetching: true,
          productArray: [],
          errorMsg:''
      }
    case ACTIONS.RECEIVED_PRODUCTS:
      return {
          ...state,
          isFetching: false,
          productArray: [...action.productsData],
          errorMsg:''
        }
    case ACTIONS.ERROR_RECEIVED:
    return {
        ...state,
        isFetching: false,
        productArray: [],
        errorMsg: action.errorMessage
      }
    default:
      return state
  }
}

export default productData