enum MessageTypes {
    Message = "message",
    Qr = "qr",
    Article = "article",
    Help = "help",
    Form = "form",
    End = "end",
    Stores = "store",
    Direction = "direction",
    QrEvent = 'qe',
    OrderTracking = 'ordertracking',
    DynamicQr = 'dqr',
    Command = 'command',
    LiveChatMessage = 'livechatmessage',
    ConditionalQr = "conditionalqr",
    LiveChatQr = "livechatqr",
    SessionId = "session_id",
    PAStoreSelector = "pa-store-select",
    Product = "product-list",
    ProductNearbyStore="product-nearby-store-list",
    StoreSelector = "store-select",
    Url = "url",
    ArticleList = "article-list",
    QuickReply = "quickReply",
    IntentGroupId = "intent_group_id",
    IntentGroupName = "intent_group_name",
    IntentName = "intent_name",
    QueryId = "query_id",
    QuickReplyPayload = "qrPayload",
    ArticlePayload = "articlePayload",
    VerifiedOrders = "verifiedOrders",
    LastOrderChecked = "last_order_checked",
    FollowUpEventData = "followup_event_data",
    ResendTaxInvoice = "resend-tax-invoice",
    PickupOrderDetail = "pickup-order-detail"
}

enum DialogflowQueryType {
    Text = "text",
    Event = "event"
}

enum Speaker {
    User = "user",
    Agent = "agent",
    System = "system",
    Murphy = "murphy"
}

enum FollowUpEventTypes {
    OrderTrackingAcceptance = "OrderTrackingAcceptance",
    SDDFAQAcceptance = "SDDFAQAcceptance"
}

export {
    MessageTypes,
    DialogflowQueryType,
    Speaker,
    FollowUpEventTypes
}