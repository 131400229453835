import { request } from 'http';
import { config } from '../../config/config';

const URL_ARTICLE_FETCH: string = `${config.CHATSERVER_URL}/api/Zendesk/Article`;
const URL_ARTICLE_STATUS: string = `${config.CHATSERVER_URL}/api/Analytics/RecordArticleStatus`;
const URL_LIVECHAT_STATUS: string = `${config.CHATSERVER_URL}/api/Analytics/UpdateLiveChatStatus`;
const URL_LIVECHAT_HOURS: string = `${config.CHATSERVER_URL}/api/Zendesk/GetLiveChatHours`;
const URL_TICKET_CREATE: string = `${config.CHATSERVER_URL}/api/Zendesk/PostCreateTicket`;
const URL_FORM_DETAILS_GET: string = `${config.CHATSERVER_URL}/api/Zendesk/Form/GetDetails`;

const requestPost = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': config.APIMGT_KEY
    },
    body: JSON.stringify({})
};

const requestGet = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json','Ocp-Apim-Subscription-Key': config.APIMGT_KEY }
};

const fetchArticle = (articleId: string) => {
    requestPost.body = JSON.stringify({ articleId: articleId });

    return fetch(URL_ARTICLE_FETCH, requestPost)
        .then(response => response.json())
        .then((result) => {
            return result;
        }, (error) => { console.log(error) });
}

const fetchLiveChatHours = () => {
    return fetch(URL_LIVECHAT_HOURS, requestGet)
        .then((result) => {
            return result.json();
        }, (error) => { console.log(error) });
}

const updateLiveChatStatus = (sessionId: string, userId: string, intentGroupId: string, liveChatStatus: boolean, intentGroupName: string) => {

    requestPost.body = JSON.stringify(
        {
            sessionId: sessionId,
            userId: userId,
            data: {
                intentGroupId: intentGroupId,
                liveChatStatus: liveChatStatus,
                intentGroupName: intentGroupName
            }
        });

    return fetch(URL_LIVECHAT_STATUS, requestPost)
        .then(response => response.json())
        .then((result) => {
            return result;
        }, (error) => { console.log(error) });
}

const viewArticle = (sessionId: string, userId: string, intentGroupId: string, queryId: string, articleId: string, viewStatus: boolean) => {
    requestPost.body = JSON.stringify(
        {
            sessionId: sessionId,
            userId: userId,
            data: {
                intentGroupId: intentGroupId,
                queryId: queryId,
                articleId: articleId,
                isOpened: viewStatus,
                isAccepted: null,
                isRejected: null
            }
        });

    return fetch(URL_ARTICLE_STATUS, requestPost)
        .then(response => response.json())
        .then((result) => {
            return result;
        }, (error) => { console.log(error) });
}

const resolveArticle = (sessionId: string, userId: string, intentGroupId: string, queryId: string, articleId: string, resolveStatus: boolean): any => {
    requestPost.body = JSON.stringify(
        {
            sessionId: sessionId,
            userId: userId,
            data: {
                intentGroupId: intentGroupId,
                queryId: queryId,
                articleId: articleId,
                isOpened: null,
                isAccepted: resolveStatus,
                isRejected: null
            }
        });
    return fetch(URL_ARTICLE_STATUS, requestPost)
        .then(response => response.json())
        .then((result) => {
            return result;
        }, (error) => { console.log(error) });
}

const rejectArticle = (sessionId: string, userId: string, intentGroupId: string, queryId: string, articleId: string, rejectStatus: boolean): any => {
    requestPost.body = JSON.stringify(
        {
            sessionId: sessionId,
            userId: userId,
            data: {
                intentGroupId: intentGroupId,
                queryId: queryId,
                articleId: articleId,
                isOpened: null,
                isAccepted: null,
                isRejected: rejectStatus
            }
        });

    return fetch(URL_ARTICLE_STATUS, requestPost)
        .then(response => response.json())
        .then((result) => {
            return result;
        }, (error) => { console.log(error) });
}

const createTicket = (ticketObj: any): any => {
    requestPost.body = JSON.stringify(ticketObj);
    return fetch(URL_TICKET_CREATE, requestPost)
        .then(response => response.json())
        .then((result) => {
            return result;
        }, (error) => { console.log(error) });
}


const getFormDetails = async () => {
    try {
       // requestGet.body = JSON.stringify({ ticketformid: config.ZENDESK_FORM_ID });
        const response = await fetch(URL_FORM_DETAILS_GET, requestGet);
        const result = await response.json();
        return result;
    } catch (error) {
        console.log(error);
        return [""];
    }
}

export {
    viewArticle,
    resolveArticle,
    rejectArticle,
    createTicket,
    getFormDetails,
    fetchArticle,
    updateLiveChatStatus,
    fetchLiveChatHours
}