//FAW Data
export const UPDATE_WINE_TYPE = 'UPDATE_WINE_TYPE'
export const UPDATE_PRICE_RANGE = 'UPDATE_PRICE_RANGE'
export const UPDATE_VARIETAL = 'UPDATE_VARIETAL'
export const UPDATE_CURRENT_VIEW = 'UPDATE_CURRENT_VIEW'
export const UPDATE_SELECTED_TYPE = 'UPDATE_SELECTED_TYPE'
export const RESET_SELECTION = 'RESET_SELECTION'
export const UPDATE_STORE_DETAILS = 'UPDATE_STORE_DETAILS'
export const PDP_OPENED = 'PDP_OPENED'
export const SHOW_FAW = 'SHOW_FAW'
export const SHOW_WINE_EXPERT = 'SHOW_WINE_EXPERT'

//Product Search
export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS'
export const RECEIVED_PRODUCTS = 'RECEIVED_PRODUCTS'
export const ERROR_RECEIVED = 'ERROR_RECEIVED'

//Bot Data
export const IS_WIDGET_OPEN_FIRST_TIME = 'IS_WIDGET_OPEN_FIRST_TIME'
export const LAST_FOLLOWUP_EVENT_NAME = 'LAST_FOLLOWUP_EVENT_NAME'
export const UPDATE_USER_FIRST_NAME = 'UPDATE_USER_FIRST_NAME'
export const UPDATE_BANNER_INFO = 'UPDATE_BANNER_INFO'

//Live Chat Data
export const UPDATE_LC_USER_INITIAL_POSITION = 'UPDATE_LC_USER_INITIAL_POSITION'
export const UPDATE_LC_USER_POSITION = 'UPDATE_LC_USER_POSITION'
export const IS_LC_MESSAGE_TRIGGERED = 'IS_LC_MESSAGE_TRIGGERED'
export const FEEDBACK_CHIP_SELECTION = 'FEEDBACK_CHIP_SELECTION'
export const FEEDBACK_ADDITIONAL_COMMENTS = 'FEEDBACK_ADDITIONAL_COMMENTS'

//Order Tracking Data
export const SHOW_ORDERTRACKING_DETAIL = 'SHOW_ORDERTRACKING_DETAIL'
export const CLICKED_PICKUP_ORDER = 'CLICKED_PICKUP_ORDER'
export const SHOW_NOMINEE_CHANGE_SCREEN = 'SHOW_NOMINEE_CHANGE_SCREEN'
export const SHOPPER_ID = 'SHOPPER_ID'
export const WIDGET_SESSION_ID = 'WIDGET_SESSION_ID'
export const ORDERDATA_EXPANDED_TILE = 'ORDERDATA_EXPANDED_TILE'
export const SHOW_NOMINEE_CHANGE_Failed_SCREEN = 'SHOW_NOMINEE_CHANGE_Failed_SCREEN'