import { config } from '../../config/config';
import axios from 'axios/index';

const urlTextQuery: string = `${config.CHATSERVER_URL}/api/TextQuery`;
const urlEventQuery: string = `${config.CHATSERVER_URL}/api/EventQuery`;

const requestPost: any = {
    method: 'POST',
    headers: { 
        'Content-Type' : 'application/json',
        'Ocp-Apim-Subscription-Key': config.APIMGT_KEY
    },
    body: JSON.stringify({})
}

const eventQueryAsync = async (text: string, chatbotUserId: string, sessionId: string = "", isLCAvailable?: boolean, additionalParams:any = null) : Promise<any> => {
    try {        
        const params:any = {
            sessionId: sessionId,
            isLCAvailable: isLCAvailable
        }

        // add the source if the 
        if (config.ISSTAGING) {
            params['source'] = 'staging';
        } else if (config.SOURCE !== null) {
            params['source'] = config.SOURCE;
        }

        // add additional params passed into the event
        if (additionalParams) {
            Object.keys(additionalParams).forEach(key => {
                params[key] = additionalParams[key];
            });
        }
        
        requestPost.body = JSON.stringify({event: text, chatbotUserId: chatbotUserId, parameters: params});
        const response = await fetch(urlEventQuery, requestPost);
        const result = await response.json();
        return result;
    } catch(error) {
        console.log(error);
        return [{
            text: config.FRIENDLY_ERROR_MESSAGE, 
            type: "message" 
        }];
    }
}

const textQueryAsync = async (text: string, chatbotUserId: string, sessionId: string = "", isLCAvailable: boolean, additionalParams:any = null): Promise<any> => {  
    try {       
        let params: any = {
            sessionId: sessionId,
            isLCAvailable: isLCAvailable
        }

        // add the source if the 
        if (config.ISSTAGING) {
            params['source'] = 'staging';
        } else if (config.SOURCE !== null) {
            params['source'] = config.SOURCE;
        }

        // add additional params passed into the event
        if (additionalParams) {
            Object.keys(additionalParams).forEach(key => {
                params[key] = additionalParams[key];
            });
        }

        requestPost.body = JSON.stringify({text: text, chatbotUserId: chatbotUserId, parameters: params });   
        const response = await fetch(urlTextQuery, requestPost);
        const result = await response.json();
        return result;
    } catch(error) {
        console.log(error);
        return [{
            text: config.FRIENDLY_ERROR_MESSAGE, 
            type: "message" 
        }];
    }
}


// todo: example of using axios
const textQueryAxios = async (text: string, chatbotUserId: string) => {      
    axios.interceptors.request.use(config => {
        // log a message before any HTTP request is sent
        console.log(`text: ${text}, chatbotUserId: ${chatbotUserId}`);     
        return config;
    });
    
    const res = await axios.post('/api/df_text_query', {
        text: text,
        chatbotUserId: chatbotUserId
    }).catch ((error) => {
        if (error.response) {
            // responded with status code out of xxx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {            
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
    });

    return res;
}

const updateAnalyticsStatus = (sessionId: string, userId:string, intentGroupId: string, isSuccessful: boolean): any => {
    requestPost.body = JSON.stringify(
        {
            sessionId: sessionId, 
            userId: userId,
            data: {
                updateType: 'acceptance',
                intentGroupId : intentGroupId,
                isSuccessful : isSuccessful,
            }
        });
    return fetch(`${config.CHATSERVER_URL}/api/Analytics/UpdateAnalyticsStatus`, requestPost)
        .then(response => response.json())
        .then((result) => {            
            return result;
        }, (error) => { console.log(error) });
}

const addAnalyticsEvent = (sessionId: string, userId:string, data: any): any => {
    requestPost.body = JSON.stringify(
        {
            sessionId: sessionId, 
            userId: userId,
            data: {
                updateType: 'addEvent',
                eventName: data.eventName,
                queryId : data.queryId,
                isSuccessful : data.isSuccessful,
                intentGroupId : data.intentGroupId,
                intentGroupName : data.intentGroupName,
                intentGroupTypeId : data.intentGroupTypeId,
                source : data.source
            }
        });
    return fetch(`${config.CHATSERVER_URL}/api/Analytics/UpdateAnalyticsStatus`, requestPost)
        .then(response => response.json())
        .then((result) => {            
            return result;
        }, (error) => { console.log(error) });
}

export {
    eventQueryAsync,
    textQueryAsync,
    updateAnalyticsStatus,
    addAnalyticsEvent
}