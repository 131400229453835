import React from 'react';
import '../messageOrderTracking.scss';
import MessageUnit from '../MessageUnit';
import MessageLink from '../../MessageLink';
import { orderTrackingConfig } from '../../../../config/config';

type messageAusPostProps = {
    orderNumber: string,
    orderTrackingUrl: string,
    indexParent: number,
    msgs: any
};

const MessageAusPost = ({orderNumber, orderTrackingUrl, indexParent, msgs}: messageAusPostProps) => {
    return (
        <div>
            <div className="message__text">
                <div className="orderTrackingMessage__delivery">
                    <div>Your <MessageUnit info={orderNumber}/> will be delivered by <MessageUnit info={"Australia Post"}/>.</div>        
                </div>   
            </div>
            <MessageLink text={orderTrackingConfig.QUICK_REPLY_TEXT} link={orderTrackingUrl}  indexParent={indexParent}  msgs={msgs}/>
        </div>                 
    );
};

export default MessageAusPost;
