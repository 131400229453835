import React from 'react';
import '../message.scss';
import './messageOrderTracking.scss';
import MessageUnit from './MessageUnit';

type messageSummaryProps = {
    text: string,
    isMultiple: boolean,
    orderNumber: string,
    numberOfConsignments: number
};

const MessageSummary = ({text, isMultiple, orderNumber, numberOfConsignments}: messageSummaryProps) => {
    return (                            
        <div>
            <div>{text}</div>
            {isMultiple && 
                <div className="orderTrackingMessage__delivery">
                    Your order <MessageUnit info={orderNumber}/> is coming in <MessageUnit info={numberOfConsignments}/> deliveries.
                </div>
            }
        </div>
    );
};

export default MessageSummary;
