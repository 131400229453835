import React, { useState } from 'react';
import Arrow from './Arrow';
import { StoreDetails } from '../store/StoreDetails';
import { StoreSelect } from '../store-select/StoreSelect';
import './carousel.scss';
import { getUniqueId } from '../../widget/util/commonUtil';

type carouselProps = {
    stores: any,
    isHostDevice: boolean,
    chooseWinePreference?: any,
    sessionId?: any,
    userId?: any
};

const Carousel = ({stores, isHostDevice, chooseWinePreference, sessionId, userId} : carouselProps) => {

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [itemLength] = useState<number>(stores.length);

    const previous = () => {
        if(activeIndex!=0){
        let index = activeIndex;
        let length = itemLength;

        if (index < 1) {
            index = length - 1;
        } else {
            index--;
        }

        setActiveIndex(index);
    }
    }

    const next = () => {
        if(activeIndex!=4){ 
        let index = activeIndex;
        let length = itemLength;

        if (index === length - 1) {
            index = 0;
        } else {
            index++;
        }

        setActiveIndex(index);
    }
    }

    return (
        <div className='carousel__container'>
            <div>
                {
                stores.map((s: any, index: number) => (
                //Find a Wine 
                (s.type === 'store-select' ?
                (<div className={index === activeIndex ? 'carousel__active' : 'carousel__inactive'} key={index} id={getUniqueId('store-select', (index+1).toString())}>
                    <StoreSelect store={s} isHostDevice={isHostDevice} chooseWinePreference={chooseWinePreference} sessionId={sessionId} userId={ userId } />
                </div>):
                //Store Details
                (<div className={index === activeIndex ? 'carousel__active' : 'carousel__inactive'} key={index} id={getUniqueId('store-details', (index+1).toString())}>
                    <StoreDetails store={s} isHostDevice={isHostDevice} />
                </div>))
               ))
               }
            </div>
            <div className='carousel__arrows'>
                <Arrow direction='left' clickHandler={previous} activeIndex={activeIndex}/>
                <div className="carousel__paging">{activeIndex+1} of {stores.length} stores</div>            
                <Arrow direction='right' clickHandler={next} activeIndex={activeIndex}/>
            </div>
        </div>
    );
}

export {
    Carousel
};