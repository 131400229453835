import { VarietalType } from '../models/fawTypes';

enum Varietals {
    NotSure = "I'm not sure"
}

const RedVarietals: string[] = [
    "Cab sav & blends",
    "Pinot noir",
    "Grenache & blends",
    "I'm not sure",
    "Shiraz & blends",
    "Merlot & blends",
    "Other varietals"
];

const WhiteVarietals: string[] = [
    "Sauvignon blanc",
    "Chardonnay",
    "Rosé",
    "I'm not sure",
    "Pinot gris & grigio",
    "Riesling",
    "Other varietals"
];

const SparklingVarietals: string[] = [
    "Champagne",
    "Sparkling white",
    "Sparkling red",
    "I'm not sure",
    "Prosecco & cava",
    "Sparkling rosé",
    "Other varietals"
];

const VarietalTypeMapping: VarietalType  = {
    "Shiraz & blends": "Shiraz / Shiraz Blends",
    "Cab sav & blends": "Cab / Cab blends",
    "Pinot noir": "Pinot Noir",
    "Merlot & blends": "Merlot", //check for blends
    "Grenache & blends": "Grenache & GSM", //check
    "Other varietals": "Other",
    "Sauvignon blanc": "Sauvignon Blanc",
    "Pinot gris & grigio": "Pinot Grigio", //check
    "Chardonnay": "Chardonnay",
    "Riesling": "Riesling",
    "Rosé": "Rosé",
    "Champagne": "Champagne",
    "Sparkling white": "Sparkling White",
    "Sparkling red": "Sparkling Red",
    "Prosecco & cava": "Prosecco & Cava",
    "Sparkling rosé": "Sparkling Rosé"
}

export { RedVarietals, WhiteVarietals, SparklingVarietals, VarietalTypeMapping, Varietals }