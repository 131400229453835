import React from 'react';
import '../findAWine.scss';
import  { Types }  from './wineTypeModel';

export type WineTypeProps = {
    updateType: (type: string) => void;
    type: string;
}

const WineType = ({updateType, type}: WineTypeProps) => {
    return (
        <div className="faw-type-container">
            <p className="sub-header"><b>I’m curious about...</b></p>
            <input type="button" id="wine-type-red" value={Types.Red} className={`form-button type-button ${type === Types.Red ? 'primary' : 'secondary'}`} onClick={ () => {updateType(Types.Red)}}/>
            <input type="button" id="wine-type-white-rose" value={Types.WhiteRose} className={`form-button type-button ${type === Types.WhiteRose ? 'primary' : 'secondary'}`} onClick={ () => {updateType(Types.WhiteRose)}}/>
            <input type="button" id="wine-type-champagne-sparkling" value={Types.ChampagneSparkling} className={`form-button type-button ${type === Types.ChampagneSparkling ? 'primary' : 'secondary'}`} onClick={ () => {updateType(Types.ChampagneSparkling)}}/>
            <input type="button" id="wine-type-not-sure" value={Types.NotSure} className={`form-button type-button ${type === Types.NotSure ? 'primary' : 'secondary'}`} onClick={ () => {updateType(Types.NotSure)}}/>   
        </div>
      )
}
  
export default WineType




