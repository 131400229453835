import React, { useState } from 'react';
import Arrow from './Arrow';
import { ProductListDetail } from '../product-list-detail/ProductListDetail';
import './product-list.scss';
import { getUniqueId } from '../../widget/util/commonUtil';

type productListProps = {
    sessionId: any,
    userId: any,
    products: any,
    selectedStore: any,
    quickReplyHandler: any,
    toggleWidget: any,
};

const ProductList = ({ sessionId, userId, products, selectedStore, quickReplyHandler, toggleWidget }: productListProps) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [itemLength] = useState<number>(products.length);

    const previous = () => {
        if (activeIndex != 0) {
            let index = activeIndex;
            let length = itemLength;

            if (index < 1) {
                index = length - 1;
            } else {
                index--;
            }

            setActiveIndex(index);
        }
    }

    const next = () => {
        if (activeIndex != 4) {
            let index = activeIndex;
            let length = itemLength;

            if (index === length - 1) {
                index = 0;
            } else {
                index++;
            }

            setActiveIndex(index);
        }
    }

    return (
        <div className='carousel__container'>
            <div>
                {
                    products.map((s: any, index: number) => (
                        <div className={index === activeIndex ? 'carousel__active' : 'carousel__inactive'} key={index} id={getUniqueId('product-list', (index + 1).toString())}>
                            <ProductListDetail sessionId={sessionId} userId={userId} product={s.data} selectedStore={selectedStore} quickReplyHandler={quickReplyHandler} toggleWidget={toggleWidget} />
                        </div>
                    ))
                }
            </div>
            <div className='carousel__arrows'>
                <Arrow direction='left' clickHandler={previous} activeIndex={activeIndex} />
                <div className="carousel__paging">{activeIndex + 1} of {products.length} stores</div>
                <Arrow direction='right' clickHandler={next} activeIndex={activeIndex} />
            </div>
        </div>
    );
}

export {
    ProductList
};