/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './messageQrArticle.scss';
import { getUniqueId } from '../../widget/util/commonUtil';

type ArticleListProps = {
    message: any,
    clickHandler: any
}

const ArticleListProps = ({message, clickHandler}: ArticleListProps) => {
    return (
        <div className="messageQrArticle" tabIndex={0}>
            <div className="messageQrArticle__linktitle" id={getUniqueId('message-qr-article', message.text)} onClick={() => clickHandler(message)} >
                <span className="messageQrArticle__linktitle-text">{message.text}</span>
            </div>
            <div className="messageQrArticle__linkdesc">
                <span className="messageQrArticle__linkdesc-summary">{message.summary}</span>
                <img src="/images/go-arrow.svg" alt=""></img>
            </div>
        </div>
    )
}

export default ArticleListProps
