import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { setShowNomineeChangeFailedScreen, setShowNomineeChangeScreen } from "../../actions";
import { useDispatch } from "react-redux";
import { fetchLiveChatHours } from "../chatservice/zendeskApis";

type NomineeChangeFailedScreenProps = {
    setliveChatTransfer:(type: boolean) => void,
    setIsDataLoaded:(type: boolean) => void,
}
const NomineeChangeFailedScreen = ({setliveChatTransfer,setIsDataLoaded}:NomineeChangeFailedScreenProps) => {
    const dispatch = useDispatch();
    const [isLCOpenHours, setIsLCOpenHours] = useState<boolean>(false);
    const [liveChatOpenHour, setLiveChatOpenHour] = useState<any>();

    const connetToLC = () => {
        setliveChatTransfer(true);
    }
    const backToPickupNominee = () => {
        setIsDataLoaded(true);
        dispatch(setShowNomineeChangeFailedScreen(false));
        dispatch(setShowNomineeChangeScreen(true));
    }
    useEffect(() => {
        fetchLiveChatHours().then((result : any )=> {
            if(result && result.statusCode === 200){
                const LCcurrentStatus = result?.data[0];
                const LCopeningHours = `${LCcurrentStatus} - ${result?.data[1]}`;
                setLiveChatOpenHour(LCopeningHours);
                if (LCcurrentStatus === 'Open') {
                    setIsLCOpenHours(true);
                }
            } else {
                setIsLCOpenHours(false);
            }
        });
    }, [])

  return (
    <div className="NomineeChangeFailed">
        <div className="NomineeChangeFailed-icon">
            <img src="./images/failed-graphic-icon.svg" alt="" />
        </div>
        <div className="NomineeChangeFailed-text">
            <span className="NomineeChangeFailed-text__title">Sorry, we've got a little mix-up on our hands</span>
            <span className="NomineeChangeFailed-text__msg">
                We are unable to update your nominee and may be experiencing a
                connection/ server issue. Please try again later. If that doesn’t work,
                our Live chat Team is here to help.
            </span>
            {!isLCOpenHours ?
                <div className="NomineeChangeFailed-LC">
                <span>Live chat available (AEST)</span>
                <div className="NomineeChangeFailed-LC__operatingHours">
                  <span>{liveChatOpenHour}</span>
                </div>
            </div>:''
            }
        </div>
        <div className="NomineeChangeFailed-buttons">
            {
                isLCOpenHours ? 
                <button className="NomineeChangeFailed-buttons__secondary" onClick={connetToLC}>Live chat</button>
                :''
            }
            <button className="NomineeChangeFailed-buttons__primary" onClick={backToPickupNominee}>Try again</button>
        </div>
    </div>
  );
};

export default NomineeChangeFailedScreen;

