/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './vote.scss';
import { vote, dialogflowEvent } from '../../config/config';
import { DialogflowQueryType } from '../models/enum';
import { resolveArticle, rejectArticle } from '../chatservice/zendeskApis';
import { postMessageToClient } from '../chatservice/communicationService';

type VoteProps = {
    sessionId: any,
    userId: any,
    data: any,
    backToWidget: (voted: boolean) => void,
    quickReplyHandler: any
}

const Vote = ({sessionId, userId, data, backToWidget, quickReplyHandler }: VoteProps) => {    
    const resolveClickHandler = (e: any) => {
        postMessageToClient({name: "chatbotArticleVote", text: 'yes'});
        resolveArticle(sessionId, userId, data.intentGroupId, data.queryId, data.articleId, true);

        quickReplyHandler(e, DialogflowQueryType.Event, dialogflowEvent.Chatbot_Additional_Questions, true, null, true, null);

        backToWidget(true);
    }

    const rejectClickHandler = (e: any) => {
        postMessageToClient({name: "chatbotArticleVote", text: 'no, i need help'});
        rejectArticle(sessionId, userId, data.intentGroupId, data.queryId, data.articleId, true);
        const intentGroupInfo = {
            intentGroupId: data.intentGroupId,
            intentGroupName: data.intentGroupName
        }

        if (data?.followUpEventName !== null && data?.followUpEventName !== '') {
            quickReplyHandler(e, DialogflowQueryType.Event, data?.followUpEventName + '_Event_No', true, null, false, intentGroupInfo);
        } else {
            quickReplyHandler(e, DialogflowQueryType.Event, dialogflowEvent.Chatbot_Contact_Options, true, null, false, intentGroupInfo);
        }

        

        backToWidget(true);
    }

    return (
        <div className="vote">
            <div className="vote__question">{vote.QUESTION_TEXT}</div>
            <div className="vote__button-container">
                <div className="vote__button" id="yes-vote-button" aria-label='smile' onClick={resolveClickHandler}>{vote.YES_TEXT}</div>
                <div className="vote__button" id="no-vote-button" aria-label='frown' onClick={rejectClickHandler}>{vote.NO_TEXT}</div>
            </div>
        </div>
    )
}

export default Vote
